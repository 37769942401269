/* eslint-disable no-useless-escape */
import React from "react"
import * as d3 from "d3"
import * as moment from "moment"
import * as uuid from "uuid"

import {
  BEHAVIOR_COLORS,
  SCORE_SORT,
  barColor,
  setLineMargin,
  showHorizontalLines,
  yAxisLabel,
} from "./constants"
import { dashToTitleCase } from "@src/utils/string"
import { LegendLabel, LegendBullet, LegendIcon } from "./styles"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import { formatLocaleDate } from "@src/utils/locale/formatLocaleDate"

const compareBehaviors = (firstBehavior, secondBehavior, sortType = "") => {
  const [firstBehaviorName, firstBehaviorScore] = firstBehavior
  const [secondBehaviorName, secondBehaviorScore] = secondBehavior
  // 1. overall should be the first
  if (
    firstBehaviorName === "overall" ||
    firstBehaviorName === "human_risk_score"
  ) {
    return -1
  }
  // 2. sorting by score
  if (secondBehaviorScore !== firstBehaviorScore && sortType === SCORE_SORT) {
    return secondBehaviorScore - firstBehaviorScore
  }
  // 3. sorting alphabetically
  const firstName =
    firstBehaviorName === "phishing" ? "simulated-phishing" : firstBehaviorName
  const secondName =
    secondBehaviorName === "phishing"
      ? "simulated-phishing"
      : secondBehaviorName
  return firstName.localeCompare(secondName)
}

const reduceToMostRecentBehaviorScore = (acc, [key, scores]) => {
  const [mostRecentScore] = [...scores].reverse()
  return [...acc, [key, mostRecentScore?.score || 0]]
}

const sortBehaviors = (behaviorScores, sortType = "") =>
  Object.entries(behaviorScores || {})
    .reduce(reduceToMostRecentBehaviorScore, [])
    .sort((first, second) => compareBehaviors(first, second, sortType))
    .map(([behaviorName]) => behaviorName)

const getBehaviorLabel = (key, isInsight = false) => {
  const titledCase = dashToTitleCase(key)
  if (!isInsight && titledCase === "Phishing") return "Simulated Phishing"
  return titledCase
}

export const getLinesInfoToDrawChart = (
  data,
  keyToBeDashed = "overall",
  sortType = "",
  isInsight = false,
) => {
  const tempData = isInsight ? Object.keys(data) : sortBehaviors(data, sortType)

  return tempData.reduce(
    (info, key, idx) => ({
      ...info,
      [key]: {
        color: BEHAVIOR_COLORS[idx],
        opacity: key === keyToBeDashed ? 1 : 0.3,
        isDashed: key === keyToBeDashed,
        label: getBehaviorLabel(key, isInsight),
      },
    }),
    0,
  )
}

function convertYearToQuarters(data) {
  if (!data || !Object.keys(data).length) return []

  const firstBehavior = data[Object.keys(data)[0]]
  const datesWithQuarters = firstBehavior
    .map(({ date }) => ({
      quarter: moment(date, "YYYY-MM-DD").format("[Q]Q Y"),
      date,
    }))
    .reverse()

  // keep only latest month for each quarter
  const quarters = datesWithQuarters
    .filter(
      (obj, index) =>
        datesWithQuarters.findIndex(
          ({ quarter }) => quarter === obj.quarter,
        ) === index,
    )
    .reverse()

  // prettier-ignore
  const filterByQuarter = (behaviourKey) => {
    if (!behaviourKey || !quarters || !Array.isArray(quarters)) return []
    return quarters.map(({ date, quarter }, index) => {
      return {
        ...(data[behaviourKey].find((item) => item.date === date) || {
          score: null,
          date,
        }),
        quarter,
        ...(index > 0
          ? {
            score_last_quarter:
              data[behaviourKey].find(
                (item) => item.date === quarters[index - 1].date,
              )?.score || null,
          }
          : {
            score_last_quarter: null,
          }),
      }
    })
  }

  const dataByQuarter = Object.keys(data).reduce((value, key) => {
    return { ...value, [key]: filterByQuarter(key) }
  }, {})

  return dataByQuarter
}

function getXAxisData(lineData, barData, typeGraph) {
  return lineData?.length
    ? lineData?.map((item) => parseXAxis(item, typeGraph))
    : barData?.map((item) => parseXAxis(item, typeGraph))
}

function parseXAxis({ date, quarter }, typeGraph) {
  if (typeGraph === "monthly") return date
  return quarter
}

export function createChartBar({
  element,
  behaviorScores,
  barData,
  chartReady,
  disabledLines,
  typeGraph = "monthly",
  onMouseEnter,
  printing,
  keyToBeDashed,
  minMaxValue,
  sortType = "",
  isInsight,
  yAxisDecimal = false,
  changeLineFocus,
  performanceFlag,
  showGradeLabel = false,
  locale = "en-US",
}) {
  const chartId = uuid.v4()
  const parentNode = element.parentElement
  const getSanitizedId = (key, date, score) =>
    `${key}-${date}-${score}${printing ? "-printable" : ""}-${chartId}`.replace(
      /[.]/g,
      "-",
    )

  // get parent div sizes
  const styles = window.getComputedStyle(parentNode)
  var padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)

  const parentWidth = parentNode.clientWidth - padding || 500
  const parentHeight = 310

  const paddingLeft = 57
  const paddingTop = 20

  const svgWidth = parentWidth - paddingLeft * 2 - (showGradeLabel ? 50 : 0)
  const svgHeight = parentHeight - paddingTop

  // create container
  const svgContainer = d3
    .select(element)
    .attr("width", "100%")
    .attr("height", parentHeight)

  // create the graph area
  const graphArea = svgContainer
    .append("g")
    .attr("width", "100%")
    .attr("height", "100%")

  const { min, max } = minMaxValue

  const renderYAxis = (yAxis, transformX, transformY) => {
    const yAxisArea = graphArea
      .append("g")
      .style("font-size", "14px")
      .style("line-height", "24px")
      .attr("transform", `translate(${transformX},${transformY})`)
      .style("cursor", "pointer")
      .call(yAxis)

    yAxisArea
      .selectAll("path")
      .attr("class", "y-axis-line")
      .attr("fill", "none")
      .attr("stroke", "none")
  }

  const yScale = d3
    .scaleLinear()
    .domain([min, max])
    .rangeRound([svgHeight - paddingTop, 0])

  if (behaviorScores?.historical_risks?.length) {
    if (barData?.length) {
      // rendering yAxis for Human Risk Score on right
      const yAxis = yAxisLabel({
        yScale,
        showGradeLabel,
        yAxisDecimal,
        max,
        isRightAxis: true,
      })

      const transformX = svgWidth + paddingLeft
      const transformY = paddingTop * 0.5
      renderYAxis(yAxis, transformX, transformY)
    } else {
      // rendering yAxis for Human Risk Score on left
      const yAxis = yAxisLabel({ yScale, showGradeLabel, yAxisDecimal, max })
      renderYAxis(yAxis, paddingLeft, paddingTop * 0.5)
    }
  }

  // create xAxis
  const dataGraph =
    typeGraph === "monthly"
      ? behaviorScores
      : convertYearToQuarters(behaviorScores)
  const firstItemOfObjects = dataGraph[Object.keys(dataGraph)[0]]

  const xScale = d3
    .scaleBand()
    .domain(getXAxisData(firstItemOfObjects, barData, typeGraph))
    .rangeRound([0, svgWidth])

  const intervention_Scale =
    behaviorScores?.historical_data
      ?.map((historicalData) => historicalData.date)
      ?.sort((a, b) => (a > b ? 1 : -1)) || []

  // Exclusive scale for intervention lines
  const xScale2 = d3
    .scaleTime()
    .domain([
      new Date(intervention_Scale[0]),
      new Date(intervention_Scale[intervention_Scale.length - 1]),
    ])
    .range([0, ((svgWidth - 6) * 11) / 12])

  const xAxis = d3
    .axisBottom(xScale)
    .tickSize(0)
    .tickPadding(15)
    .tickSizeOuter(0)
    .tickFormat((v) =>
      typeGraph === "monthly"
        ? formatLocaleDate(v, { locale, format: "MMM" })
        : v,
    )
    .scale(xScale)

  // rendering xAxis
  const xAxisRender = graphArea
    .append("g")
    .style("font-size", "14px")
    .style("line-height", "24px")
    .style("cursor", "pointer")
    .attr(
      "transform",
      `translate(${paddingLeft},${svgHeight - paddingTop / 2})`,
    )

  xAxisRender
    .call(xAxis)
    .selectAll("path")
    .attr("fill", "none")
    .attr("stroke", "none")

  // rendering horizontal grid on xAxis
  const grid = graphArea
    .append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${paddingLeft},${paddingTop / 2})`)
    .call(showHorizontalLines({ yScale, svgWidth }))

  grid.selectAll("path").attr("fill", "none").attr("stroke", "none")

  grid
    .selectAll("line")
    .attr("class", "horizontal-lines")
    .attr("stroke-width", "1")
    .attr("stroke", "#C9CFD6")
    .attr("fill", "#C9CFD6")

  // CREATE LINES
  const lineDrawInfo = getLinesInfoToDrawChart(
    behaviorScores,
    keyToBeDashed,
    sortType,
    isInsight,
  )

  const drawBarsOnLineChart = () => {
    const xScaleBars = d3
      .scaleBand()
      .domain(barData.map((d) => d.date))
      .range([0, svgWidth])
      .paddingInner(0.5)
      .paddingOuter(0.3)

    // If all values are zero, make sure we still show all the ticks
    const DEFAULT_BAR_MAX = 1_000

    const TICKS = 6

    const max = Math.max(
      Math.max(...barData.map((d) => d.value)) || DEFAULT_BAR_MAX,
      TICKS - 1, // Always show at least TICKS number of ticks, regardless of range
    )

    const yScaleBars = d3
      .scaleLinear()
      .nice()
      .domain([0, max])
      .rangeRound([svgHeight - paddingTop, 0])

    const tickValues = []
    const segment = max / (TICKS - 1)
    for (let i = 0; i < TICKS; i++) {
      tickValues.push(segment * i)
    }

    const yScale = yScaleBars
    const yAxisBars = yAxisLabel({
      yScale,
      showGradeLabel: false, // barData isn't a score, but a count
      yAxisDecimal,
      max,
      ticks: TICKS,
      tickValues,
    })

    // Render yAxis for bars on left side
    renderYAxis(yAxisBars, paddingLeft + 5, paddingTop * 0.5)

    const barRadius = 4

    // Render Bars
    graphArea
      .append("g")
      .selectAll(".bar")
      .data(barData)
      .enter()
      .append("path")
      .style("fill", barColor)
      .attr("d", (d) => {
        if (yScaleBars(d.value) < svgHeight - paddingTop) {
          return `
        M${xScaleBars(d.date) + paddingLeft},${yScaleBars(d.value) + paddingTop / 2 + barRadius}
        a${barRadius},${barRadius} 0 0 1 ${barRadius},${-barRadius}
        h${xScaleBars.bandwidth() - 2 * barRadius}
        a${barRadius},${barRadius} 0 0 1 ${barRadius},${barRadius}
        v${yScaleBars(0) - yScaleBars(d.value) - barRadius}
        h${-xScaleBars.bandwidth()}Z
      `
        }
      })
  }

  const createLines = (key) => {
    if (disabledLines.includes(key) || !lineDrawInfo[key]) return

    const lineData = dataGraph[key]
    const { color, isDashed, opacity } = lineDrawInfo[key]

    const valueLine = d3
      .line()
      .x((item) => {
        return xScale(parseXAxis(item, typeGraph))
      })
      .y((item) => {
        const v = isInsight ? item.individuals : item.score
        if (v !== null) return yScale(v)
      })
      .defined((item) => {
        const v = isInsight ? item.individuals : item.score
        return v !== null
      })

    // Add the value line paths.
    const line = graphArea.append("path")
    const translateX =
      parseFloat(
        xAxisRender
          ?.selectAll(".tick")
          ?.nodes()?.[0]
          ?.getAttribute("transform")
          ?.match(/translate\(([0-9\.]*)/)?.[1],
      ) + 35

    line
      .data([lineData])
      .attr("class", "line")
      .style("opacity", opacity)
      .style("cursor", "pointer")
      .attr("fill", "none")
      .attr("stroke", color)
      .attr("stroke-width", "2")
      .attr(
        "transform",
        `translate(${translateX + paddingTop},${paddingTop / 2 - 1} )`,
      )
      .on("mouseenter", function () {
        d3.select(this).style("opacity", 1)
      })
      .on("mouseleave", function () {
        d3.select(this).style("opacity", opacity)
      })
      .on("click", function () {
        if (performanceFlag) {
          changeLineFocus(key)
        }
      })
      .attr("d", valueLine)

    if (!chartReady) {
      const pathLength =
        line.node().getTotalLength && line.node().getTotalLength()
      line
        .attr("stroke-dasharray", pathLength + " " + pathLength)
        // Set the intial starting position so that only the gap is shown by offesetting by the total length of the line
        .attr("stroke-dashoffset", pathLength)
        // Then the following lines transition the line so that the gap is hidden...
        .transition()
        .duration(1800)
        .ease(d3.easeSinIn)
        .attr("stroke-dashoffset", 0)
    }

    // insights: create horizontal bar
    if (isInsight) {
      const barWidth = xScale.domain().length * xScale.step()
      graphArea
        .append("rect")
        .attr("class", "bar")
        .attr("fill", "#B8E5E3")
        .style("opacity", "0.3")
        .style("cursor", "pointer")
        .attr("y", 50)
        .attr("width", barWidth)
        .attr("height", 54 * 1.5)
        .attr("transform", `translate(${paddingLeft}, ${151})`)
    }

    if (isDashed) {
      const dotsLength =
        line.node().getTotalLength && line.node().getTotalLength()

      // removes null points as we do not want to put dot there
      const filteredLineData = lineData.reduce((acc, cur) => {
        const v = isInsight ? cur.individuals : cur.score
        if (v !== null) acc.push(cur)
        return acc
      }, [])

      const dots = graphArea
        .selectAll("dot")
        .data(filteredLineData)
        .enter()
        .append("circle")
        .attr("class", "hollow-point")
        .attr("fill", "white")
        .attr("stroke", color)
        .attr("stroke-width", "2")
        .style("cursor", "pointer")
        .style("outline", "none")
        .attr("id", ({ date, score }) => getSanitizedId(key, date, score))
        .on("mouseenter", ({ date, score }) => {
          const fullInfo = filteredLineData.find((l) => l.date === date)
          onMouseEnter({
            behaviourName: key,
            elementId: getSanitizedId(key, date, score),
            ...fullInfo,
            color,
            typeGraph,
          })
        })
        .on("mouseleave", function () {
          d3.select(this).attr("fill", "white")
        })
        .attr(
          "transform",
          `translate(${translateX + paddingTop},${paddingTop / 2 - 1})`,
        )
        .attr("r", 6)
        .attr("cx", function (item) {
          return xScale(parseXAxis(item, typeGraph)) - 1
        })
        .attr("cy", function (item) {
          return yScale(isInsight ? item.individuals : item.score)
        })

      if (!chartReady) {
        dots
          .attr("stroke-dasharray", dotsLength + " " + dotsLength)
          // Set the intial starting position so that only the gap is shown by offesetting by the total length of the line
          .attr("stroke-dashoffset", dotsLength)
          // Then the following lines transition the line so that the gap is hidden...
          .transition()
          .duration(4500)
          .ease(d3.easeSinIn)
          .attr("stroke-dashoffset", 0)
      }
    }

    if (behaviorScores?.intervention_data?.length > 0) {
      graphArea
        .selectAll("intervention-lines")
        .data(behaviorScores.intervention_data)
        .enter()
        .append("line")
        .attr("stroke", "#1CB696")
        .attr("stroke-width", "2")
        .attr("class", "intervention-lines")
        .attr("transform", `translate(${translateX},${paddingTop / 2 - 1} )`)
        .attr(
          "x1",
          (d) => xScale2(new Date(d.date)) + setLineMargin(new Date(d.date)),
        )
        .attr(
          "x2",
          (d) => xScale2(new Date(d.date)) + setLineMargin(new Date(d.date)),
        )
        .attr("y1", yScale.range()[0])
        .attr("y2", yScale.range()[1])
        .attr("id", ({ date, rdr_name }) =>
          getSanitizedId(key, date, rdr_name.replaceAll(" ", "_")),
        )
        .style("stroke-dasharray", "5")
        .style("outline", "none")
        .style("cursor", "pointer")
        .on("mouseenter", ({ date, rdr_name }) => {
          const fullInfo = lineData.find((l) => l.date === date)
          onMouseEnter({
            behaviourName: key,
            elementId: getSanitizedId(key, date, rdr_name.replaceAll(" ", "_")),
            ...fullInfo,
            typeGraph,
            intervention: true,
            rdr_name: rdr_name,
            date: date,
          })
        })
        .on("mouseleave", function () {
          d3.select(this).attr("fill", "white")
        })
    }
  }

  if (barData?.length) {
    // do this before rendering the lines so lines are on top of the bars
    drawBarsOnLineChart()
  }

  // create lines and dots
  Object.keys(dataGraph)
    ?.filter((item) => item !== "intervention_data")
    ?.forEach(createLines)
}

export const handleOnToggleLine =
  (disabledLines, setDisabledLines) => (key) => {
    if (disabledLines.includes(key)) {
      setDisabledLines([...disabledLines.filter((e) => e !== key)])
      return
    }
    setDisabledLines([...disabledLines, key])
  }

// prettier-ignore
const renderLegendSupport =
  (printing = false) =>
    (
      behaviorsGraphInfo,
      disabledLines,
      setDisabledLines,
      hideActionsTaken,
      setHideActionsTaken,
      changeLineFocus,
      keyToBeDashed,
    ) => {
      const keys = Object.keys(behaviorsGraphInfo)
      return (
        <React.Fragment>
          {keys.map((key) => {
            const { label, color } = behaviorsGraphInfo[key]
            const toggleVisibilityTooltipBody = disabledLines.includes(key)
              ? "Show trend line"
              : "Hide trend line"
            const changeFocusTooltipBody = "Focus trend line"
            const icon = disabledLines.includes(key) ? "EyeClose" : "Eye"
            return (
              <LegendLabel key={key}>
                {!printing && (
                  <>
                    <Tooltip
                      body={toggleVisibilityTooltipBody}
                      placement="top"
                      readOnly
                      size="xsm"
                    >
                      <LegendIcon
                        icon={icon}
                        onClick={() =>
                          handleOnToggleLine(disabledLines, setDisabledLines)(key)
                        }
                      >
                        <Icon name={icon} fill="#959DA8" />
                      </LegendIcon>
                    </Tooltip>
                    <Tooltip
                      body={changeFocusTooltipBody}
                      placement="top"
                      readOnly
                      size="xsm"
                    >
                      <LegendBullet color={color} />
                      <div
                        onClick={() => {
                          if (!disabledLines.includes(key)) changeLineFocus(key)
                        }}
                      >
                        {keyToBeDashed === key ? <b>{label}</b> : <>{label}</>}
                      </div>
                    </Tooltip>
                  </>
                )}
                {printing && (
                  <>
                    <LegendBullet color={color} />
                    {label}
                  </>
                )}
              </LegendLabel>
            )
          })}

        </React.Fragment>
      )
    }

export const renderLegend = (
  behaviorsGraphInfo,
  disabledLines,
  setDisabledLines,
  hideActionsTaken,
  setHideActionsTaken,
  changeLineFocus,
  keyToBeDashed,
) => {
  const printing = false
  return renderLegendSupport(printing)(
    behaviorsGraphInfo,
    disabledLines,
    setDisabledLines,
    hideActionsTaken,
    setHideActionsTaken,
    changeLineFocus,
    keyToBeDashed,
  )
}

export function renderLegendForPrint(
  behaviorsGraphInfo,
  disabledLines,
  setDisabledLines,
) {
  const printing = true
  return renderLegendSupport(printing)(
    behaviorsGraphInfo,
    disabledLines,
    setDisabledLines,
  )
}
