import React, { useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import tippy from "tippy.js"
import { SkeletonLoader } from "@src/utils/skeleton"
import { SpacingY } from "../SpacingY"
import ScoreTooltip from "@src/scenes/Dashboard/components/ThirdQuestion/components/ScoreTooltip"
import {
  NoDataContainer,
  NoDataTitle,
  TrendingAxesLabelsContainer,
  TrendingHeader,
  TrendingXLabelContainer,
  TrendingYLabelContainer,
} from "./styles"
import HistoricalTrend from "../HistoricalTrend"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"

function TrendingOverTimeGraphCommon({
  lineData,
  barData,
  loader,
  title,
  xAxisLabel,
  yAxisLabel,
  xAxisColor,
  yAxisColor,
  behaviorName,
  showGradeLabel,
  toggleBarsOnLegendClick,
}) {
  const [hideBarData, setHideBarData] = useState(false)

  const handleOnMouseEnter = (info) => {
    const { elementId, score, score_last_month, date } = info
    const sanitizedId = elementId.replace(/[.]/g, "-")
    const tooltipId = `${sanitizedId}-tooltip-content`
    const toolTipContent = document.createElement("div")
    toolTipContent.setAttribute("id", tooltipId)

    ReactDOM.render(
      <ScoreTooltip
        score={roundUserFacingScore(score)}
        month={date}
        trend={
          score_last_month === null
            ? 0
            : formatUserFacingScore(
                roundUserFacingScore(score) -
                  roundUserFacingScore(score_last_month),
              )
        }
        behaviorName={behaviorName}
        maxValue={10}
        useWordLabel={showGradeLabel}
      />,
      toolTipContent,
    )

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      maxWidth: "unset",
      onHide() {
        toolTipContent.remove()
      },
    }

    const element = document.querySelector(`#${sanitizedId}`)
    element.setAttribute("fill", "#0C7D84")
    tippy(element, tippyProps)
    element._tippy.show()
  }

  return (
    <div>
      <SpacingY size="md">
        {title && <TrendingHeader>{title}</TrendingHeader>}
        {loader ? (
          <SkeletonLoader height={350} />
        ) : !barData?.length && !lineData?.historical_risks?.length ? (
          <NoDataContainer style={{ top: 50 }}>
            No Data for
            <NoDataTitle>{title}</NoDataTitle>
            Chart
          </NoDataContainer>
        ) : (
          <HistoricalTrend
            behaviorScores={lineData}
            barData={hideBarData ? [] : barData}
            onMouseEnter={handleOnMouseEnter}
            yAxisLabel=""
            xAxisLabel=""
            keyToBeDashed="historical_risks"
            showHeading={false}
            showTabs={false}
            changeGraphTabsPosition={true}
            minMaxValue={{ min: 0.0, max: 10.0 }}
            yAxisDecimal={false}
            showGradeLabel={showGradeLabel}
          />
        )}
      </SpacingY>
      {!loader && (
        <TrendingAxesLabelsContainer>
          <TrendingXLabelContainer color={xAxisColor}>
            {xAxisLabel}
          </TrendingXLabelContainer>
          <TrendingYLabelContainer
            color={yAxisColor}
            isClickable={toggleBarsOnLegendClick}
            isHidingData={hideBarData}
            onClick={() => {
              if (toggleBarsOnLegendClick) {
                setHideBarData((prev) => !prev)
              }
            }}
          >
            {yAxisLabel}
          </TrendingYLabelContainer>
        </TrendingAxesLabelsContainer>
      )}
    </div>
  )
}

TrendingOverTimeGraphCommon.propTypes = {
  lineData: PropTypes.shape({ historical_risks: [] }).isRequired,
  barData: PropTypes.array.isRequired,
  loader: PropTypes.bool,
  title: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  xAxisColor: PropTypes.string.isRequired,
  yAxisColor: PropTypes.string.isRequired,
  behaviorName: PropTypes.string,
  showGradeLabel: PropTypes.bool,
  toggleBarsOnLegendClick: PropTypes.bool,
}

TrendingOverTimeGraphCommon.defaultProps = {
  loader: false,
  title: "",
  xAxisLabel: "",
  yAxisLabel: "",
  behaviorName: "Human Risk Score",
  showGradeLabel: false,
  toggleBarsOnLegendClick: false,
}

export default TrendingOverTimeGraphCommon
