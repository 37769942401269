import React from "react"
import { ProgressBarWrapper } from "./styles"
import { useRequest } from "ahooks"
import { getIndividualScoresHistoricalActionScores } from "@src/services/apis/reputation"
import { useMonthlyAttackCounts } from "@src/services/apis/reputation/useMonthlyAttackCounts"
import { useParams } from "react-router-dom"
import { SkeletonLoader } from "@src/utils/skeleton"
import { useSelector } from "react-redux"
import { TIME_PERIOD_MONTHS } from "@src/scenes/HumanRiskDashboard/constants"
import moment from "moment/moment"
import { ScoreProgressBar } from "@elevate_security/elevate-component-library"
import { SpacingY } from "@src/components/SpacingY"
import { generateArray } from "../IndividualHumanRiskScoreInfoSection/utils"
import { HumanRiskBehaviorsSection } from "@src/scenes/HumanRiskDashboard/components/HumanRiskBehaviors"
import TrendingOverTimeGraphCommon from "@src/components/TrendingOverTimeGraphCommon"
import { getGlobalConfig } from "@src/globalConfig"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { AttackExplanationCards } from "./AttackExplanationCards"
import { useTranslation } from "react-i18next"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"

function AttackFactorProgressBar({ attackFactorScore }) {
  return (
    <ScoreProgressBar
      score={roundUserFacingScore(attackFactorScore || 0.1)}
      size="medium"
      bodyPadding="30px 12px 10px"
    />
  )
}

const AttackScoreExplanation = ({ onSwitchToEventsTab, attackData }) => {
  const { t } = useTranslation("individualRiskProfile")
  const { profileId } = useParams()

  const timePeriodValue =
    useSelector((state) => state.get("reputationsReducer"))
      ?.time_period_value || ""

  const startDate = moment()
    .subtract(TIME_PERIOD_MONTHS[timePeriodValue], "month")
    .format("YYYY-MM-DD")

  const { data: attackFactorTrendData } = useRequest(
    () =>
      getIndividualScoresHistoricalActionScores({
        profileId,
        start_date: startDate,
      }),
    {
      refreshDeps: [timePeriodValue],
      formatResult: (res) => {
        return res.data?.attack
      },
    },
  )

  const activeActions =
    useSelector(selectActiveActions)?.map((action) =>
      action.replaceAll("-", "_"),
    ) || []

  const {
    attackCounts,
    totalsByBehavior,
    loading: loadingMonthlyAttackCounts,
  } = useMonthlyAttackCounts({
    months: TIME_PERIOD_MONTHS[timePeriodValue] + 1,
    es_person_id: profileId,
    activeActions,
  })

  return (
    <SpacingY size="lg">
      {loadingMonthlyAttackCounts ? (
        <SpacingY size="md">
          <SkeletonLoader height={50} marginTop={20} />
          <SkeletonLoader height={250} />
          <SkeletonLoader height={100} />
        </SpacingY>
      ) : (
        <SpacingY size="md">
          <ProgressBarWrapper>
            <AttackFactorProgressBar
              attackFactorScore={
                attackFactorTrendData &&
                attackFactorTrendData[attackFactorTrendData.length - 1].score
              }
            />
          </ProgressBarWrapper>
          <TrendingOverTimeGraphCommon
            lineData={generateArray(attackFactorTrendData || [])}
            barData={attackCounts}
            yAxisLabel={t("trendingOverTimeGraphCommon.yAxisLabel")}
            xAxisLabel={t("trendingOverTimeGraphCommon.xAxisLabel")}
            xAxisColor="#2064b5"
            yAxisColor="#AEAEAE"
            title={t("trendingOverTimeGraphCommon.title")}
            behaviorName={t("trendingOverTimeGraphCommon.behaviourName")}
            showGradeLabel
          />
          <HumanRiskBehaviorsSection
            actionFactorScoreData={attackData}
            title=""
            scoreType="text"
            isAttackFactor={true}
            onClickOverride={onSwitchToEventsTab}
            person_nid={profileId}
            eventTotalsByBehavior={totalsByBehavior}
          />
        </SpacingY>
      )}
      {getGlobalConfig("ENABLE_IRP_ATTACK_EXPLANATION_CARDS") && (
        <AttackExplanationCards
          profileId={profileId}
          startDate={startDate}
          timePeriodValue={timePeriodValue}
        />
      )}
    </SpacingY>
  )
}

export default AttackScoreExplanation
