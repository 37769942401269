import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Dropdown, Section } from "./styles"
import { useToggle } from "ahooks"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { useSelectedIndividualState } from "../../SelectedIndividualProviders"
import { useDispatch } from "react-redux"
import { showSideDrawer } from "@src/services/redux/analysisIndividuals/actions"
import { Button } from "@src/components/MimecastReskin"
import { SpacingX } from "@src/components/SpacingX"
import { useTranslation } from "react-i18next"

const enrollActions = [
  { label: "Enroll in a Campaign", value: "enroll" },
  { label: "Add to new Manual Group", value: "add_to_new_static_group" },
  {
    label: "Add to existing Manual Group",
    value: "add_to_existing_static_group",
  },
]

export default function PerformBulkActions({
  onCreateStaticGroup,
  onAddToExistingStaticGroup,
}) {
  const { t } = useTranslation("riskAnalysis", {
    keyPrefix: "performBulkAction",
  })
  const [isOpen, { toggle: setIsOpen }] = useToggle()
  const { checked, checkedAll } = useSelectedIndividualState()
  const dispatch = useDispatch()

  const clickHandler = () => isOpen && setIsOpen(false)

  const onChange = ({ value }, event) => {
    event.stopPropagation()
    switch (value) {
      case "enroll":
        dispatch(showSideDrawer())
        break
      case "add_to_new_static_group":
        onCreateStaticGroup()
        break
      case "add_to_existing_static_group":
        onAddToExistingStaticGroup()
        break
      default:
        break
    }
    setIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener("click", clickHandler)
    return () => {
      window.removeEventListener("click", clickHandler)
    }
  })

  return (
    <Container>
      <Button
        onClick={() => (checked.length || checkedAll) && setIsOpen(!isOpen)}
        disabled={!checked.length && !checkedAll}
      >
        <SpacingX size="xs" alignItems="center">
          <span>{t("label")}</span>
          <Icon name="CaretDown" size="xxsm" fill="#fff" />
        </SpacingX>
      </Button>
      {isOpen && (
        <Dropdown>
          {enrollActions.map((action) => (
            <Section
              key={action.label}
              onClick={(event) => onChange(action, event)}
            >
              {t(action.value)}
            </Section>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}

PerformBulkActions.propTypes = {
  onCreateStaticGroup: PropTypes.func.isRequired,
  onAddToExistingStaticGroup: PropTypes.func.isRequired,
}
