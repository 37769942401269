import moment from "moment"
import Timezone from "moment-timezone"
import { TIME_ZONES_INVERTED } from "@elevate_security/elevate-component-library"

export function currentDate({ format = "YYYY-MM-DD" } = {}) {
  return moment().format(format)
}

export function subtractYear({
  size = 1,
  format = "YYYY-MM-DD",
  currentDate = moment().format(format),
} = {}) {
  return moment(currentDate, format).subtract(size, "year").format(format)
}

export function subtractMonth({
  size = 1,
  format = "YYYY-MM-DD",
  currentDate = moment().format(format),
}) {
  return moment(currentDate, format).subtract(size, "month").format(format)
}

export function formatDate({ date, format = "YYYY-MM-DD" }) {
  return moment(date).format(format)
}

export function getDateRangePeriodBefore(
  count,
  period,
  { endDateOffset = 1, dateFormat = "YYYY-MM-DD" } = {},
) {
  return {
    startDate: moment().subtract(count, period).format(dateFormat),
    endDate: moment().add(endDateOffset, "days").format(dateFormat),
  }
}

/**
 * converts the UTC timestamp to a corresponding timezone
 *
 * example params:-
 * date: 2020-11-18T17:00:00
 * timezone: Asia/Karachi
 *
 * example return value:-
 * 2020-11-18T22:00 (as Asia/Karachi is GMT+5)
 */
export const utcAndTzToTime = ({ date, timezone }) =>
  date
    ? timezone
      ? Timezone.tz(moment.utc(date).format(), timezone)
      : Timezone.tz(moment.utc(date).format(), moment.tz.guess())
    : null

export function formatTimezone(timeZoneAbbr, timezone) {
  const letterPattern = /^[a-zA-Z]{3,4}$/

  if (!letterPattern.test(timeZoneAbbr)) {
    return TIME_ZONES_INVERTED[timezone]
  }

  return timeZoneAbbr
}

export const timeWithTimeZone = (date) => {
  const targetTimezone = moment.tz.guess()
  const timeZoneAbbr = moment.tz(moment.tz.guess()).format("z")
  const formattedTime = utcAndTzToTime(date)?.format("MMM D, YYYY h:mm A")
  return `${formattedTime} ${formatTimezone(timeZoneAbbr, targetTimezone)}`
}

export const timeMonthDayYear = (date) => {
  const formattedTime = utcAndTzToTime(date)?.format("MMMM D, YYYY")
  return formattedTime
}
