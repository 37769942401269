import React, { useMemo } from "react"
import { useEventLogs } from "@src/components/EventLogs/useEventLogs"
import { getColumns, getFiltersParam } from "@src/components/EventLogs/utils"
import { ACTION_LOGS, ACTION_LOGS_ALL_POLICIES } from "@src/constants"
import useQuery from "@src/services/hooks/useQuery"
import { getDateRangePeriodBefore } from "@src/utils/dates"
import { ActionLogFilters } from "./ActionLogsFilters"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { getGlobalConfig } from "@src/globalConfig"
import { MimecastActionLogsScreen } from "../MimecastActionLogsScreen/MimecastActionLogsScreen"
import { useTranslation } from "react-i18next"

export const ActionLogsScreen = (props) => {
  const actionLogContext = getGlobalConfig("ACTION_LOG_CONTEXT")
  const searchValue = useQuery().get("search")

  // The differences between Elevate and Mimecast action logs are subtle but
  // many, so it makes sense just to create a new screen instead of having a
  // bunch of conditions splattered throughout a single action logs screen to
  // handle everything.
  if (actionLogContext === "ELEVATE_PLATFORM") {
    return <ActionLogsScreenWithSearch {...props} searchValue={searchValue} />
  } else if (actionLogContext === "MIMECAST") {
    return <MimecastActionLogsScreen {...props} />
  } else {
    throw new Error(`Invalid ACTION_LOG_CONTEXT ${actionLogContext}`)
  }
}

export const ActionLogsScreenWithSearch = ({ searchValue = null }) => {
  const { t } = useTranslation("individualRiskProfile")
  const defaultFilters = useMemo(() => {
    const { startDate, endDate } = getDateRangePeriodBefore(3, "months")
    return [
      {
        type: "date",
        key: "execution_datetime",
        value: [startDate, endDate],
      },
    ]
  }, [])

  const {
    pagination,
    data,
    loading,
    orderBy,
    appliedFilters,
    searchFilter,
    filters,
    handleSearch,
    clearSearch,
    handleSortedChange,
    onFilterSelection,
  } = useEventLogs({
    type: ACTION_LOGS_ALL_POLICIES,
    order_by: "created_datetime desc",
    search: searchValue,
    defaultFilters,
  })
  const formatter = (key, defaultValue) => {
    return t(key, { defaultValue })
  }

  const columns = useMemo(
    () =>
      getColumns(
        orderBy,
        loading,
        false,
        true,
        ACTION_LOGS_ALL_POLICIES,
        formatter,
      ),
    [orderBy, loading],
  )

  return (
    <>
      <MimecastCgBreadcrumbs
        breadcrumbs={["risk_response_engine", "action_logs"]}
      />
      <TableWithFiltersScreen
        title={ACTION_LOGS}
        appliedFiltersCount={appliedFilters.length}
        entries={data?.list}
        totalCount={pagination.total || data?.total}
        columns={columns}
        searchValue={searchFilter || searchValue}
        pagination={pagination}
        loading={!data}
        onSearch={handleSearch}
        clearSearch={clearSearch}
        onSortedChange={handleSortedChange}
        showExportData={getGlobalConfig("ENABLE_ACTION_LOGS_EXPORT")}
        filters={Object.keys(filters).length ? getFiltersParam(filters) : ""}
      >
        <ActionLogFilters
          onChange={onFilterSelection}
          appliedFilters={appliedFilters}
        />
      </TableWithFiltersScreen>
    </>
  )
}
