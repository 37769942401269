import { roundUserFacingScore } from "./roundUserFacingScore"

/*
 * Be careful with .toFixed() here because of rounding behaviour, since it can
 * seem to round up or down unexpectedly based on floating-point precision loss.
 * That's why we round manually first.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#using_tofixed
 *
 * We must return a string here to get a single decimal place for whole number
 * scores, e.g. score of 1 should show up as "1.0", score of 8 as "8.0" etc.
 */
export function formatUserFacingScore(value: number): string {
  // eslint-disable-next-line no-restricted-syntax
  return roundUserFacingScore(value).toFixed(1)
}
