import { AppliedFilters as AppliedFiltersComponenet } from "@elevate_security/elevate-component-library"
import useRiskAnalysisURLParam from "@src/scenes/SecEngIndividualRiskAnalysis/components/useRiskAnalysisURLParam"
import { humanize } from "@src/utils/string"
import React from "react"
import FiltersUtils from "../FiltersToolbar/FiltersUtils"
import {
  actionKeyLabelMap,
  LIST_FILTERS,
  sortNameMap,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { useTranslation } from "react-i18next"

export default function AppliedFilters({ riskPrefix, riskTypeOverride }) {
  const { t } = useTranslation("riskAnalysis")
  const { filters } = useRiskAnalysisURLParam({ riskTypeOverride })
  const {
    AND_CONSTANT,
    IN_CONSTANT,
    IN_SEPARATOR,
    HUMAN_RISK_SCORE,
    HUMAN_RISK_MAP,
    ACTION_FILTERS,
    removeFilter,
  } = FiltersUtils(riskPrefix, { riskTypeOverride })

  const filtersArray = filters?.split(AND_CONSTANT) || []

  const sortFunction = (a, b) => {
    return a - b
  }

  const risksFilters =
    filtersArray.filter((filter) => filter.includes("human_risk_score")) || []
  const rangeFilters = ACTION_FILTERS.filter(
    (actionName) => actionName !== "overall",
  ).flatMap((actionName) => {
    return filtersArray.filter((filter) =>
      filter
        .split(" ")
        .includes(
          HUMAN_RISK_SCORE[actionName] ||
            sortNameMap[actionName] ||
            actionName ||
            [],
        ),
    )
  })
  const inFilters =
    filtersArray.filter((filter) => filter.includes(IN_CONSTANT)) || []
  const searchFilter =
    filtersArray.filter((filter) => filter.includes(" ILIKE ")) || []

  const handleFilterRemoval = (selection) => {
    if (selection.name === HUMAN_RISK_MAP.label) {
      selection.name = HUMAN_RISK_MAP.key
    }
    if (
      selection.name === "access_factor" ||
      selection.name === "attack_factor"
    ) {
      selection.value = selection.value.toLowerCase().replaceAll(" ", "_")
    }
    selection.name =
      actionKeyLabelMap[selection.name.toLowerCase().replaceAll(" ", "_")] ||
      selection.name

    const filter = {
      key: selection.name,
      value: selection.original,
    }

    removeFilter(filter)
  }

  const formatAppliedFilters = () => {
    let filtersArr = []
    if (risksFilters) {
      Object.entries(
        risksFilters
          .map((risk_filter) => risk_filter?.split(" "))
          .reduce((acc, [key, , value]) => {
            acc[key] = acc[key] ? [...acc[key], value] : [value]
            return acc
          }, {}),
      ).forEach(([risk, arr]) => {
        arr.sort(sortFunction)
        filtersArr.push({
          key: risk,
          value: `${arr[0]} - ${arr[1]}`,
          original: `${arr[0]} - ${arr[1]}`,
          name: risk,
          humanizedName: t(`appliedFilters.${risk}`, {
            defaultValue: humanize(risk),
          }),
        })
      })
    }
    Object.entries(
      rangeFilters
        .map((risk_filter) => risk_filter?.split(" "))
        .reduce((acc, [key, , value]) => {
          acc[key] = acc[key] ? [...acc[key], value] : [value]
          return acc
        }, {}),
    ).forEach(([risk, arr]) => {
      arr.sort(sortFunction)
      arr = arr.map((item) => item)
      filtersArr.push({
        key: risk,
        value: `${arr[0]} - ${arr[1]}`,
        original: `${arr[0]} - ${arr[1]}`,
        name: risk,
        humanizedName: t(`appliedFilters.${risk}`, {
          defaultValue: humanize(risk),
        }),
      })
    })
    if (inFilters) {
      const inFiltersArr = inFilters.reduce((acc, cur) => {
        const [property, concat_values] = cur?.split(IN_CONSTANT)
        const isListFilter = LIST_FILTERS.includes(property)
        let valuesArray = concat_values
        if (isListFilter) {
          try {
            valuesArray = JSON.parse(concat_values)
          } catch {
            valuesArray = concat_values?.split(IN_SEPARATOR)
          }
        } else {
          valuesArray = concat_values?.split(IN_SEPARATOR)
        }
        valuesArray?.forEach((value, index) =>
          acc.push({
            key: `in-${property}-${index}`,
            value: t(`appliedFilters.${value}`, {
              defaultValue: humanize(value),
            }),
            original: value,
            name: property,
            humanizedName: t(`appliedFilters.${property}`, {
              defaultValue: humanize(property),
            }),
          }),
        )
        return acc
      }, [])
      filtersArr = filtersArr.concat(inFiltersArr)
    }
    if (searchFilter) {
      const searchFilterArr = searchFilter.reduce((acc, cur) => {
        const [property, concat_values] = cur?.split(" ILIKE ")
        acc.push({
          key: `search-${property}`,
          value: concat_values,
          original: humanize(concat_values),
          name: property,
          humanizedName: humanize(concat_values),
        })
        return acc
      }, [])
      filtersArr = filtersArr.concat(searchFilterArr)
    }
    return filtersArr
  }

  return (
    <AppliedFiltersComponenet
      appliedFilters={formatAppliedFilters()}
      handleRemoveFilter={handleFilterRemoval}
      chipsFormater={{ tenure_duration: (val) => val }}
    />
  )
}
