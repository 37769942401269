import moment from "moment"
import React from "react"
import Picker from "react-datepicker"
import { Container } from "./styles"

const DatePicker = ({ onChange, value, placeholder, ...props }) => {
  return (
    <Container>
      <Picker
        placeholderText={placeholder}
        dateFormat="yyyy-MM-dd"
        selected={value ? moment(value).toDate() : null}
        onChange={onChange}
        {...props}
      />
    </Container>
  )
}

export default DatePicker
