import axios from "axios"
import qs from "qs"

const axios_headers = {}

const impersonate_es_person_id = localStorage.getItem(
  "impersonate_es_person_id",
)
if (impersonate_es_person_id !== null) {
  axios_headers["X-Impersonate-User"] = impersonate_es_person_id
}

const axiosOpts = {
  headers: axios_headers,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params),
}

const axiosInstance = axios.create(axiosOpts)

export function configureAuthInterceptor(interceptor) {
  axiosInstance.interceptors.request.use(interceptor, (error) => {
    return Promise.reject(error)
  })
}

export function configureErrorResponseInterceptor(interceptor) {
  axiosInstance.interceptors.response.use((response) => {
    return response
  }, interceptor)
}

export default axiosInstance
