import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Table,
  CustomSelect,
} from "@elevate_security/elevate-component-library"
import ActivityIndicator from "@src/components/ActivityIndicator"
import {
  TrendSummaryWrapper,
  Title,
  FilterWrapper,
  LabelFilter,
  FilterColumn,
  TableWrapper,
} from "./styles"

import { TREND_SUMMARY_TABLE_COLUMNS, parseResults } from "./utils"

import {
  selectTrendSummaryCampaigns,
  selectTrendSummaryOverallScore,
} from "@src/services/redux/trendSummary/selectors"
import {
  setTrendSummaryCampaigns,
  setTrendSummaryHistoricalScores,
} from "@src/services/redux/trendSummary/actions"
import { subtractMonth, subtractYear, currentDate } from "@src/utils/dates"
import { ActionBoxes } from "../ActionBoxes"

const filter = [
  {
    label: "Last 30 days",
    value: subtractMonth({ size: 1 }),
  },

  {
    label: "Last 60 days",
    value: subtractMonth({ size: 2 }),
  },
  {
    label: "Last 90 days",
    value: subtractMonth({ size: 3 }),
  },
  {
    label: "Last 6 months",
    value: subtractMonth({ size: 6 }),
  },
  {
    label: "Last Year",
    value: subtractYear(),
  },
]
export function TrendSummary() {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useState({
    limit: 10,
    offset: 0,
    start_date: subtractMonth({ size: 1 }),
    end_date: currentDate(),
    ordering: "-end_date",
  })

  const { data, isLoaded } = useSelector(selectTrendSummaryCampaigns)
  const { overallTrend } = useSelector(selectTrendSummaryOverallScore)

  const results = data && data?.results ? parseResults(data.results) : []
  const totalCount = data && data?.count ? data.count : 0

  useEffect(() => {
    dispatch(setTrendSummaryCampaigns({ ...queryParams }))

    // for the score we need to add one month more to calculate the trend
    const startMonth = subtractMonth({
      currentDate: queryParams.start_date,
      size: 1,
    })
    dispatch(
      setTrendSummaryHistoricalScores({
        ...queryParams,
        start_date: startMonth,
      }),
    )
  }, [queryParams])

  const handleOnChangeRange = ({ value }) => {
    setQueryParams({ ...queryParams, offset: 0, start_date: value })
  }

  const handleOnChangeTable = ({ pagination }) => {
    setQueryParams({ ...queryParams, ...pagination })
  }

  return (
    <TrendSummaryWrapper>
      <Title>Trend Summary</Title>
      <FilterWrapper>
        <FilterColumn>
          <LabelFilter>Viewing&nbsp;</LabelFilter>
          <CustomSelect
            options={filter}
            defaultValue={filter[0]}
            onChange={handleOnChangeRange}
          />
        </FilterColumn>
      </FilterWrapper>
      <ActionBoxes totalCount={totalCount} overallTrend={overallTrend} />
      <TableWrapper>
        <Table
          columns={TREND_SUMMARY_TABLE_COLUMNS}
          data={results}
          manual
          totalCount={totalCount}
          defaultPageSize={10}
          pageSizeOptions={[10, 25, 50]}
          onChangeTable={handleOnChangeTable}
        />
        <ActivityIndicator active={!isLoaded} />
      </TableWrapper>
    </TrendSummaryWrapper>
  )
}
