type PaginatedResponse<T> = {
  results: T[]
  total_elements: number
}

export async function fetchAll<T>(
  fetchPage: (pagination: {
    pageSize: number
    pageNumber: number
  }) => Promise<PaginatedResponse<T>>,
  {
    maxFetches = 10,
    pageSize = 50,
    startingPage = 1,
  }: { maxFetches?: number; pageSize?: number; startingPage?: number } = {},
): Promise<PaginatedResponse<T>> {
  let pageNumber = startingPage
  const rules = []

  while (true) {
    const { results, total_elements } = await fetchPage({
      pageSize,
      pageNumber,
    })
    rules.push(...results)

    if (rules.length >= total_elements) {
      break
    }

    pageNumber++

    if (pageNumber > maxFetches) {
      // In case there is a bug on the back-end, break to make sure we don't
      // keep pulling infinitely. 500 items is way more than we are likely
      // to want to pull without manual pagination.
      break
    }
  }

  return { results: rules, total_elements: rules.length }
}
