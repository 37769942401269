import React from "react"
import {
  EmptyScreenContainer,
  CrossContainer,
  TextContainer,
  ParagraphContainer,
} from "./styles"
import { IconAction } from "@elevate_security/elevate-component-library"
import { RISK_RESPONSE_RULES } from "@src/scenes/SecEngIndividualRiskAnalysis/components/RiskDetectionRules/constants"
import { Link } from "react-router-dom"

const EmptyScreen = () => {
  const RULE_TITLE = RISK_RESPONSE_RULES.slice(0, -1).toLowerCase()

  const CREATE_URL =
    "/engagement/vision2/risk-response-engine/risk-response-rules/create-rule"

  return (
    <EmptyScreenContainer>
      <CrossContainer>
        <Link to={CREATE_URL}>
          <IconAction name="Plus" size="xlg" className="plus-icon" />
        </Link>
      </CrossContainer>
      <TextContainer>
        <p>Let&apos;s get the ball rolling...</p>
      </TextContainer>

      <ParagraphContainer>
        <p>
          {" "}
          <Link to={CREATE_URL}>Add a new {RULE_TITLE}</Link> to start
          automating and tailoring responses to different levels of detected
          security risk in your org!
        </p>
      </ParagraphContainer>
    </EmptyScreenContainer>
  )
}

export default EmptyScreen
