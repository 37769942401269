import React from "react"
import PropTypes from "prop-types"
import {
  Gauge,
  Badge,
  Typography,
} from "@elevate_security/elevate-component-library"
import { GaugeFooter, GaugeWrapper } from "./styles"
import { DashboardCard } from "../DashboardCard"
import { HumanRiskDashboardHumanRiskScoreInfoTooltip } from "../DashboardInfoTooltips"
import { useTranslation } from "react-i18next"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"

function HumanRiskScoreGauge({
  score: rawScore,
  footerText,
  isFooterInline,
  containerWidth,
}) {
  const { Text } = Typography
  const { t } = useTranslation("dashboard")

  const score = roundUserFacingScore(rawScore)

  const getScoreLabel = () => {
    let label = ""

    if (score < 2) {
      label = t("humanRiskScoreCard.scoreGauge.veryLow")
    } else if (score < 4) {
      label = t("humanRiskScoreCard.scoreGauge.low")
    } else if (score < 6) {
      label = t("humanRiskScoreCard.scoreGauge.medium")
    } else if (score < 8) {
      label = t("humanRiskScoreCard.scoreGauge.high")
    } else {
      label = t("humanRiskScoreCard.scoreGauge.veryHigh")
    }

    return label
  }

  const getTheme = () => {
    if (score < 2) {
      return "verylow"
    } else if (score < 4) {
      return "low"
    } else if (score < 6) {
      return "medium"
    } else if (score < 8) {
      return "high"
    } else {
      return "veryhigh"
    }
  }

  return (
    <DashboardCard
      title={t("humanRiskScoreCard.title")}
      subtitle={t("humanRiskScoreCard.subtitle")}
      info={<HumanRiskDashboardHumanRiskScoreInfoTooltip />}
      style={{ maxWidth: "450px" }}
      imageExportId="HumanRiskScoreGauge"
    >
      <GaugeWrapper>
        <Gauge
          score={score}
          containerWidth={containerWidth}
          formatter={(val) => {
            // This ensures we show "10" for 10. The endpoint is a special case
            // where we don't want to show decimal places due to weird spacing
            // with the score indicator.
            return val === 10 ? "10" : formatUserFacingScore(val)
          }}
        />
      </GaugeWrapper>
      <GaugeFooter>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 15,
            marginRight: isFooterInline ? 10 : 0,
            display: isFooterInline ? "inline" : "block",
          }}
        >
          {footerText}
        </Text>
        <Badge
          text={getScoreLabel()}
          theme={getTheme()}
          shape="largeboldround"
          fontSize="18px"
        />
      </GaugeFooter>
    </DashboardCard>
  )
}

export default HumanRiskScoreGauge

HumanRiskScoreGauge.propTypes = {
  score: PropTypes.number,
  footerText: PropTypes.string,
  isFooterInline: PropTypes.bool,
  containerWidth: PropTypes.number,
}

HumanRiskScoreGauge.defaultProps = {
  score: 0,
  footerText: "",
  isFooterInline: false,
  containerWidth: 270,
}
