import PropTypes from "prop-types"
import { getFiltersParam } from "@src/components/EventLogs/utils"
import { truncateNumber } from "@src/utils/numbers/utils"
import {
  getEventLogModal,
  getEventsLogFromReputations,
} from "@src/services/apis/reputation"
import { useRequest } from "ahooks"
import React from "react"
import { Badge } from "@elevate_security/elevate-component-library"
import { useTranslation } from "react-i18next"

export const EventBadge = ({
  id,
  person_nid,
  startDate,
  eventCountOverride,
}) => {
  const { t } = useTranslation("dashboard")
  const filteredId =
    id === "attack_rwp" ? "real_world_phishing" : id.replace("attack_", "")

  const actionTypes =
    id === "attack_rwp"
      ? ["real-world-phishing-blocked", "real-world-phishing-delivered"]
      : []

  const { data, loading } = useRequest(
    () => {
      const args = {
        filters: getFiltersParam(
          {
            action: [filteredId],
            date: [startDate],
            action_event: actionTypes,
          },
          "",
        ),
        start_date: startDate,
        order_by: "date",
        page_number: 0,
        page_size: 1,
      }
      if (person_nid) {
        return getEventsLogFromReputations(person_nid, args)
      }
      return getEventLogModal(filteredId, args)
    },
    {
      // If an override is passed in, no need to fetch the count here, so only
      // fetch if the override is not defined
      ready: typeof eventCountOverride === "undefined",
      refreshDeps: [startDate],
      formatResult: (response) => {
        return response?.data
      },
    },
  )

  const eventTotal = eventCountOverride ?? data?.total_elements

  return (
    <Badge
      text={
        loading
          ? t("eventBadge.loading")
          : truncateNumber(eventTotal ?? 0) + " " + t("eventBadge.events")
      }
      theme="eventsgrey"
      shape="smallboldround"
    />
  )
}

EventBadge.propTypes = {
  id: PropTypes.string.isRequired,
  person_nid: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  eventCountOverride: PropTypes.number,
}
