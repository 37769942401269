import React from "react"

import {
  TextOverflow,
  SkeletonLoader,
  Tooltip,
} from "@elevate_security/elevate-component-library"
import { timeWithTimeZone } from "@src/utils/dates"
import { humanize } from "@src/utils/string"
import {
  getEventLogModal,
  getActionableEventLog,
  getEventsLogFromReputations,
} from "@src/services/apis/reputation"
import {
  getAuditLogData,
  getPoliciesActionLogs,
  getPoliciesAuditLogs,
  getPolicyActionLogs,
} from "@src/services/apis/policies"
import { TableCellSpan } from "../TableCellSpan"
import ShowActionText from "@src/scenes/RiskDetectionRulesScreen/Table/ShowActionText"
import { StatusBadge } from "@src/scenes/RiskDetectionRulesScreen/Table/StatusBadge"
import {
  ACTION_LOGS_ALL_POLICIES,
  AUDIT_LOGS_ALL_POLICIES,
} from "@src/constants"
import { RISK_RESPONSE_RULE_PATH } from "@src/scenes/RiskDetectionRulesScreen/constants"
import LinkTo from "../LinkTo"

export const getStringDataRender = (loading, value) => {
  if (loading) {
    return (
      <div style={{ width: "90%" }}>
        <SkeletonLoader />
      </div>
    )
  }
  return (
    <div style={{ paddingLeft: "5px" }}>
      <TextOverflow name={humanize(value || "")} color="#565d66" />
    </div>
  )
}

export const getSortProperty = (sort, key) => {
  if (!sort.includes(key)) return {}

  return {
    asc: !sort.includes("desc"),
  }
}

const getDataSourceEventColumn = (
  sort,
  showDatasourceEventLog,
  loading,
  formatter = (_, val) => val,
) => {
  return showDatasourceEventLog
    ? [
        {
          key: "data_source",
          header: humanize(
            formatter(
              "eventsInfo.columns.dataSourceEventColumn",
              "data_source",
            ),
          ).toLocaleUpperCase(),
          render: (value) => getStringDataRender(loading, value),
          ...getSortProperty(sort, "data_source"),
        },
      ]
    : []
}

const getIndividualColumn = (
  columnName,
  sort,
  showIndividualColumn,
  loading,
) => {
  return showIndividualColumn
    ? [
        {
          key: "full_name",
          header: columnName?.toLocaleUpperCase(),
          render: (value, row) =>
            loading ? (
              <div style={{ width: "90%" }}>
                <SkeletonLoader />
              </div>
            ) : (
              <LinkTo
                value={humanize(value)}
                path={`/engagement/vision2/${row?.es_person_id}/profile`}
              />
            ),
          ...getSortProperty(sort, "full_name"),
        },
      ]
    : []
}

const getIndividualColumnActionLog = (
  columnName,
  sort,
  showIndividualColumn,
  loading,
) => {
  return showIndividualColumn
    ? [
        {
          key: "first_name",
          header: columnName?.toUpperCase(),
          render: (_, row) =>
            loading ? (
              <div style={{ width: "90%" }}>
                <SkeletonLoader />
              </div>
            ) : (
              <LinkTo
                value={humanize(row?.full_name)}
                path={`/engagement/vision2/${row?.es_person_id}/profile`}
              />
            ),
          ...getSortProperty(sort, "first_name"),
        },
      ]
    : []
}

const getAuditLogColumns = (loading, sort, formatter = (_, val) => val) => {
  return [
    {
      key: "event_description",
      header: formatter(
        "eventsInfo.columns.events",
        "event",
      ).toLocaleUpperCase(),
      render: (value, row) => {
        if (loading) {
          return (
            <div style={{ width: "90%" }}>
              <SkeletonLoader />
            </div>
          )
        }
        if (row.event_type === "run" || row.event_type === "evaluated") {
          return (
            <LinkTo
              value={value}
              path={`/engagement/vision2/risk-response-engine/action-logs?search=${row.details.execution_id}`}
            />
          )
        }
        return <TextOverflow name={humanize(value)} color="#565d66" />
      },
      ...getSortProperty(sort, "event_description"),
    },
    {
      key: "created_datetime",
      header: formatter(
        "eventsInfo.columns.policyExecutedWhen",
        "Policy Executed When",
      ).toLocaleUpperCase(),
      render: (value) => {
        return loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TextOverflow name={value} color="#565d66" />
        )
      },
      ...getSortProperty(sort, "created_datetime"),
    },
    {
      key: "event_type",
      header: formatter("eventsInfo.columns.type", "Type").toUpperCase(),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TextOverflow name={humanize(value)} color="#565d66" />
        ),
      ...getSortProperty(sort, "event_type"),
    },
  ]
}

export const getColumns = (
  sort,
  loading,
  showDataSourceCol,
  showIndividualColumn,
  type,
  formatter = (_, val) => val,
) => {
  if (type === "audit_log") return getAuditLogColumns(loading, sort, formatter)
  if (type === "action_log") {
    return getActionLogColumns(loading, sort, showIndividualColumn, formatter)
  }

  if (type === ACTION_LOGS_ALL_POLICIES) {
    return getPoliciesActionLogsColumns(
      loading,
      sort,
      showIndividualColumn,
      formatter,
    )
  }

  if (type === AUDIT_LOGS_ALL_POLICIES) {
    return getPoliciesAuditLogsColumns(loading, sort, formatter)
  }

  return [
    ...getIndividualColumn(
      formatter("eventsInfo.columns.individual", "individual"),
      sort,
      showIndividualColumn,
      loading,
    ),
    {
      key: "event",
      header: formatter(
        "eventsInfo.columns.eventType",
        "EVENT TYPE",
      ).toLocaleUpperCase(),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TextOverflow
            name={humanize(value?.split(" (")[0])}
            color="#565d66"
          />
        ),
      ...getSortProperty(sort, "action"),
    },
    {
      key: "details",
      header: formatter(
        "eventsInfo.columns.eventDetail",
        "EVENT DETAIL",
      ).toLocaleUpperCase(),
      render: (value) => {
        const formattedName = [
          // Training
          value?.course_title
            ? `${formatter("eventsInfo.prependedValues.title")}${value.course_title}`
            : null,
          // Simulated Phishing
          value?.campaign_title
            ? `${formatter("eventsInfo.prependedValues.title")}${value.campaign_title}`
            : null,
          // Actual Phishing
          value?.threat_classification
            ? `${formatter("eventsInfo.prependedValues.threatClassificationTitle")}${value.threat_classification}`
            : null,
          // Malware
          value?.event_title
            ? `${formatter("eventsInfo.prependedValues.title")}${value.event_title}`
            : null,
        ].find(Boolean)

        return loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TextOverflow name={formattedName} color="#565d66" />
        )
      },
    },
    {
      key: "date",
      header: formatter("eventsInfo.columns.date", "DATE").toLocaleUpperCase(),
      render: (date) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan style={{ padding: "0 4px" }} title={date}>
            {timeWithTimeZone({
              date: date,
            })}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "date"),
    },
    ...getDataSourceEventColumn(sort, showDataSourceCol, loading, formatter),
    {
      key: "action",
      header: formatter(
        "eventsInfo.columns.action",
        "action",
      ).toLocaleUpperCase(),
      render: (value) => getStringDataRender(loading, value),
      ...getSortProperty(sort, "action"),
    },
  ]
}

export const eventsLogType = {
  actionEventsLog: "actionEventLogsData",
  insightEventsLog: "InsightData",
  individualEventsLog: "individualsEventLogs",
  audit_log: "audit_log",
  action_log: "action_log",
  [ACTION_LOGS_ALL_POLICIES]: ACTION_LOGS_ALL_POLICIES,
  [AUDIT_LOGS_ALL_POLICIES]: AUDIT_LOGS_ALL_POLICIES,
}

export const getEventsLogAction = (type) => {
  if (type === "actionEventsLog") {
    return getEventLogModal
  }

  if (type === "insightEventsLog") {
    return getActionableEventLog
  }

  if (type === "individualEventsLog") {
    return getEventsLogFromReputations
  }
  if (type === "audit_log") {
    return getAuditLogData
  }

  if (type === "action_log") {
    return getPolicyActionLogs
  }

  if (type === ACTION_LOGS_ALL_POLICIES) {
    return getPoliciesActionLogs
  }

  if (type === AUDIT_LOGS_ALL_POLICIES) {
    return getPoliciesAuditLogs
  }
}
const getLastDate = (key, date) => {
  if (!date?.length) return ""
  return `${key} LE ${date}`
}

const getFirstDate = (key, date) => {
  if (!date?.length) return ""
  return `${key} GE ${date}`
}

const getDateParams = (key, startDate, endDate) => {
  if (startDate && endDate) {
    return `${getFirstDate(key, startDate)} AND ${getLastDate(key, endDate)}`
  }
  if (endDate) {
    return getLastDate(key, endDate)
  }
  return getFirstDate(key, startDate)
}

export const getFiltersParam = (filters, prefix = "") => {
  const fields = [
    {
      key: "filterBy",
      param: `searchbar searches ${
        filters?.filterBy?.length > 0 ? filters?.filterBy[0] : ""
      }`,
      value: filters?.filterBy?.length > 0 ? filters?.filterBy : null,
    },
    {
      key: "actions",
      param: `action IN ${filters?.action?.join(",")}`,
      value: filters?.action?.length > 0 ? filters?.action : null,
    },
    {
      key: "data_source",
      param: `data_source IN ${filters?.data_source?.join(",")}`,
      value: filters?.data_source?.length > 0 ? filters?.data_source : null,
    },
    {
      key: "date",
      param: getDateParams("date", filters?.date?.[0], filters?.date?.[1]),
      value: filters?.date?.filter((d) => d)?.length ? " " : null,
    },
    {
      key: "execution_datetime",
      param: getDateParams(
        "execution_datetime",
        filters?.execution_datetime?.[0],
        filters?.execution_datetime?.[1],
      ),
      value: filters?.execution_datetime?.filter((d) => d)?.length ? " " : null,
    },
    {
      key: "rule_version",
      param: `rule_version IN ${filters?.rule_version?.join(",")}`,
      value: filters?.rule_version?.length > 0 ? filters?.rule_version : null,
    },
    {
      key: "state",
      param: `state IN ${filters?.state?.join(",")}`,
      value: filters?.state?.length > 0 ? filters?.state : null,
    },
    {
      key: "status",
      param: `${prefix}status IN ${filters?.status?.join(",")}`,
      value: filters?.status?.length > 0 ? filters?.status : null,
    },
    {
      key: "action_type",
      param: `${prefix}action_type IN ${filters?.action_type?.join(",")}`,
      value: filters?.action_type?.length > 0 ? filters?.action_type : null,
    },
    {
      key: "action_event",
      param: `${prefix}action_event IN ${filters?.action_event?.join(",")}`,
      value: filters?.action_event?.length > 0 ? filters?.action_event : null,
    },
    {
      key: "action_category",
      param: `action_category IN ${filters?.action_category?.join(",")}`,
      value:
        filters?.action_category?.length > 0 ? filters?.action_category : null,
    },
    {
      key: "category",
      param: `category IN ${filters?.category?.join(",")}`,
      value: filters?.category?.length > 0 ? filters?.category : null,
    },
    {
      key: "user_type",
      param: `extra.user.type IN ${filters?.user_type?.join(",")}`,
      value: filters?.user_type?.length > 0 ? filters?.user_type : null,
    },
  ]

  return fields
    .filter((field) => field.value)
    .map((field) => field.param)
    .join(" AND ")
}

export const setAuditLogFormat = (response) => {
  const desiredOrder = [
    "status",
    "individuals_matched",
    "execution_id",
    "rule_id",
    "rule_version",
    "errors_details",
  ]
  const data = response?.data || {}
  const list =
    data?.results?.map((item) => {
      const date = timeWithTimeZone({ date: item.created_datetime })
      const newItem = {
        ...item,
        created_datetime: date,
        details: item.extra,
      }
      delete newItem.extra
      newItem.details = reorganizeObjectProperties(
        newItem.details,
        desiredOrder,
      )
      return newItem
    }) || []
  return {
    total: data?.total_elements || 0,
    list: list,
    metadata: [],
  }
}

export const setActionLogFormat = (response) => {
  const desiredOrder = [
    "status",
    "individuals_matched",
    "execution_id",
    "rule_id",
    "rule_version",
    "errors_details",
  ]
  const data = response?.data || {}
  const list =
    data?.results?.map((item) => {
      const createdDate = timeWithTimeZone({ date: item.created_datetime })
      const executionDate = timeWithTimeZone({
        date: item?.extra?.execution_datetime,
      })
      const newItem = {
        ...Object.assign({}, item),
        created_datetime: createdDate,
        execution_datetime: item?.extra?.execution_datetime
          ? executionDate
          : "Not Executed Yet",
        details: item.extra,
        action_details: item?.action,
        full_name: `${item?.first_name} ${item?.last_name}`,
      }
      delete newItem.extra
      delete newItem.action
      newItem.details = reorganizeObjectProperties(
        newItem.details,
        desiredOrder,
      )
      return newItem
    }) || []

  return {
    total: data?.total_elements || 0,
    list: list,
    metadata: [],
  }
}

function reorganizeObjectProperties(obj, desiredOrder) {
  const keys = Object.keys(obj)
  const orderedProperties =
    desiredOrder?.filter((key) => keys?.includes(key)) || []
  const remainingProperties = keys?.filter(
    (key) => !orderedProperties.includes(key),
  )

  return {
    ...orderedProperties.reduce((result, key) => {
      result[key] = obj[key]
      return result
    }, {}),
    ...remainingProperties.reduce((result, key) => {
      result[key] = obj[key]
      return result
    }, {}),
  }
}

const getActionLogColumns = (
  loading,
  sort,
  showIndividualColumn,
  formatter = (_, val) => val,
) => {
  return [
    ...getIndividualColumnActionLog(
      formatter("eventsInfo.columns.personName", "person name"),
      sort,
      showIndividualColumn,
      loading,
    ),
    {
      key: "created_datetime",
      header: formatter("eventsInfo.columns.criteriaMetOn", "CRITERIA MET ON"),
      render: (created_datetime) =>
        loading ? (
          <SkeletonLoader />
        ) : (
          <TableCellSpan style={{ padding: "0 2px" }} title={created_datetime}>
            {created_datetime}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "created_datetime"),
    },
    {
      key: "action",
      header: formatter("eventsInfo.columns.ruleResponse", "RULE RESPONSE"),
      isHeadSort: false,
      render: (_, row) => (
        <ShowActionText
          data={{ ...row, action: row?.action_details }}
          loading={loading}
          parseHTML={true}
        />
      ),
    },
    {
      key: "rule_version",
      header: formatter("eventsInfo.columns.ruleVersion", "RULE VERSION"),
      isHeadSort: true,
      render: (rule_version) =>
        loading ? (
          <SkeletonLoader />
        ) : (
          <TableCellSpan style={{ padding: "0 2px" }} title={rule_version}>
            {rule_version}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "rule_version"),
    },
    {
      key: "status",
      header: formatter("eventsInfo.columns.actionStatus", "ACTION STATUS"),
      isHeadSort: true,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        const status = row.details?.status || "unknown"

        const badge = <StatusBadge>{status}</StatusBadge>

        if (status === "failed") {
          return (
            <Tooltip
              body={
                <div
                  style={{
                    display: "inline-block",
                    wordBreak: "break-word",
                  }}
                >
                  {row.details?.message ||
                    formatter(
                      "eventsInfo.fallbackMessages.seekSupportMessage",
                      "Seek support for more details",
                    )}
                </div>
              }
              placement="top"
              readOnly
            >
              {badge}
            </Tooltip>
          )
        }

        return badge
      },
    },
  ]
}

const getPoliciesActionLogsColumns = (
  loading,
  sort,
  showIndividualColumn,
  formatter = (_, val) => val,
) => {
  return [
    {
      key: "name",
      header: formatter("eventsInfo.columns.ruleName", "RULE NAME"),
      isHeadSort: true,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        return (
          <LinkTo
            value={row?.risk_rule_name}
            path={`${RISK_RESPONSE_RULE_PATH}/edit-rule/${row?.policy_id}`}
          />
        )
      },
    },
    {
      key: "state",
      header: formatter("eventsInfo.columns.ruleState", "RULE STATE"),
      isHeadSort: false,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        return humanize(row?.risk_rule_state)
      },
    },
    ...getActionLogColumns(loading, sort, showIndividualColumn, formatter),
    {
      key: "execution_datetime",
      header: formatter("eventsInfo.columns.executedOn", "EXECUTED ON"),
      isHeadSort: true,
      render: (execution_datetime) => {
        if (loading) {
          return <SkeletonLoader />
        }

        return execution_datetime
      },
    },
  ]
}

const getPoliciesAuditLogsColumns = (
  loading,
  sort,
  formatter = (_, val) => val,
) => {
  return [
    {
      key: "name",
      header: formatter("eventsInfo.columns.ruleName", "RULE NAME"),
      isHeadSort: true,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        if (row?.rule_deleted) {
          return (
            row?.risk_rule_name ||
            formatter(
              "eventsInfo.fallbackMessages.ruleWithNoName",
              "A rule with no name",
            )
          )
        }

        return (
          <LinkTo
            value={row?.risk_rule_name}
            path={`${RISK_RESPONSE_RULE_PATH}/edit-rule/${row?.policy_id}`}
          />
        )
      },
    },
    {
      key: "state",
      header: formatter("eventsInfo.columns.ruleState", "RULE STATE"),
      isHeadSort: false,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        return humanize(row?.risk_rule_state)
      },
    },
    {
      key: "rule_version",
      header: formatter("eventsInfo.columns.ruleVersion", "RULE VERSION"),
      isHeadSort: false,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        return (
          row?.details?.rule_version ||
          formatter(
            "eventsInfo.fallbackMessages.detailsNotAvailable",
            "Not Available",
          )
        )
      },
    },
    ...getAuditLogColumns(loading, sort, formatter),
    {
      key: "status",
      header: formatter("eventsInfo.columns.status", "STATUS"),
      isHeadSort: false,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        const status = row?.details?.status?.toLowerCase()

        return (
          <StatusBadge>{status === "failure" ? status : "success"}</StatusBadge>
        )
      },
    },
    {
      key: "user",
      header: formatter("eventsInfo.columns.user", "USER"),
      isHeadSort: false,
      render: (_, row) => {
        if (loading) {
          return <SkeletonLoader />
        }

        const user = row?.details?.user?.toLowerCase()

        return user === "system" ? humanize(user) : user
      },
    },
  ]
}
