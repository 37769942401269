import React, { useMemo, useState } from "react"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { getGlobalConfig } from "@src/globalConfig"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { Link } from "react-router-dom"
import { StatusBadge } from "./StatusBadge"
import { useMimecastActionLogs as _useMimecastActionLogs } from "./useMimecastActionLogs"
import { useDebounceFn } from "ahooks"
import type { ActionLog } from "./useMimecastActionLogs"
import { MimecastActionLogsFilters } from "./MimecastActionLogsFilters"
import { useTranslation } from "react-i18next"
import { useRuleNames as _useRuleNames } from "./useRuleNames"
import { FormattedLocaleDatetime } from "@src/utils/locale/formatLocaleDatetime"

export const MimecastActionLogsScreen = ({
  personId,
  showTitle = true,
  useMimecastActionLogs = _useMimecastActionLogs,
  useRuleNames = _useRuleNames,
}: {
  personId?: string
  showTitle?: boolean
  useMimecastActionLogs?: typeof _useMimecastActionLogs
  useRuleNames?: typeof _useRuleNames
}) => {
  const [pagination, setPagination] = useState({ pageSize: 25, current: 1 })
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const { run: setSearchDebounced } = useDebounceFn(setSearch, { wait: 250 })
  const [orderBy, setOrderBy] = useState("execution_datetime desc")
  const { t } = useTranslation("actionLogs")

  const { ruleIdToRuleName, loading: loadingRules } = useRuleNames()

  const matchingRuleIds = useMemo(() => {
    if (!search) {
      return []
    }
    const ids = Object.entries(ruleIdToRuleName)
      .filter(([, ruleName]) =>
        ruleName.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      )
      .map(([ruleId]) => ruleId)

    return ids
  }, [ruleIdToRuleName, search])

  const {
    data,
    loading: loadingActionLogs,
    setAppliedFilters,
    appliedFilters,
  } = useMimecastActionLogs({
    ruleIds: matchingRuleIds,
    orderBy,
    personId,
    pagination,
    search,
  })

  const columns = [
    {
      key: "risk_rule_name",
      header: t("tableHeaders.ruleName"),
      render: (name: string, { rule_id }: { rule_id: string }) => {
        return (
          <div style={{ paddingRight: "10px" }}>
            {ruleIdToRuleName[rule_id] || name}
          </div>
        )
      },
    },
    ...(!personId
      ? [
          {
            key: "person_name",
            header: t("tableHeaders.personName"),
            render: (
              _: never,
              { first_name, last_name, mc_person_id }: ActionLog,
            ) => {
              return (
                <Link to={`/engagement/vision2/${mc_person_id}/profile`}>
                  {first_name} {last_name}
                </Link>
              )
            },
          },
          {
            key: "email",
            header: t("tableHeaders.email"),
          },
        ]
      : []),
    {
      key: "action",
      header: t("tableHeaders.action"),
      render: (action: ActionLog["action"]) => {
        return t(
          (
            {
              email: "filter.email",
              email_with_video: "filter.emailWithVideo",
            } as const
          )[action],
        )
      },
    },
    {
      key: "status",
      header: t("tableHeaders.status"),
      render: (status: ActionLog["status"]) => {
        return (
          <StatusBadge status={status} displayText={t(`status.${status}`)} />
        )
      },
    },
    {
      key: "execution_datetime",
      asc: orderBy === "execution_datetime asc",
      header: t("tableHeaders.executed"),
      render: (datetime: string) => {
        return <FormattedLocaleDatetime date={Number(datetime)} />
      },
    },
  ]

  return (
    <div>
      {(loadingActionLogs || loadingRules) && <ActivityIndicator active />}
      {!personId && (
        <MimecastCgBreadcrumbs
          breadcrumbs={["risk_response_engine", "action_logs"]}
        />
      )}
      <TableWithFiltersScreen
        title={showTitle ? t("actionLogsTitleText") : ""}
        appliedFiltersCount={
          Object.entries(appliedFilters)
            .flatMap(([, value]) => value)
            .filter(Boolean).length
        }
        entries={data?.data?.results || []}
        totalCount={data?.data?.total_elements || 0}
        columns={columns}
        searchValue={searchText}
        pagination={{
          ...pagination,
          onChange: (current: number, pageSize: number) => {
            setPagination({ current, pageSize })
          },
        }}
        loading={!data}
        onSearch={(val: string) => {
          setSearchDebounced(val)
          setSearchText(val)
        }}
        clearSearch={() => {
          setSearch("")
          setSearchText("")
        }}
        onSortedChange={(key: string, dir: string) => {
          setOrderBy(`${key} ${dir}`)
        }}
        showExportData={getGlobalConfig("ENABLE_ACTION_LOGS_EXPORT")}
        excludeSortColumn={columns
          .filter(({ key }) => key !== "execution_datetime")
          .map(({ key }) => key)}
        placeholder={t("searchPlaceholder")}
        emptyMessage={t("emptyMessage")}
      >
        <MimecastActionLogsFilters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      </TableWithFiltersScreen>
    </div>
  )
}
