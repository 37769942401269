import React from "react"
import PropTypes from "prop-types"

import {
  PolicyExecutionDate,
  ItemRow,
  ContentRow,
  ButtonRow,
  PolicyExecutionRowTitle,
  PolicyExecutionRowTitleLink,
  PolicyActionLogWrapper,
  TableWrapper,
  NoActionItemsWrapper,
  NoActionItemImage,
  NoActionItemsMessageWrapper,
  NoDataTextHeader,
  TableHeader,
} from "./styles"
import { getIndividualsGroupedRulesAuditLogs } from "@src/services/apis/reputation"
import { ActionLogsLoader } from "../PolicyActionLog/ActionLogsLoader"
import { useRequest } from "ahooks"
import { timeMonthDayYear } from "@src/utils/dates"
import { Button } from "@src/components/MimecastReskin"
import { getGlobalConfig } from "@src/globalConfig"
import { getPoliciesActionLogs } from "@src/services/apis/policies"
import { useTranslation } from "react-i18next"

const TitleComponent = (props) => {
  const { t } = useTranslation("individualRiskProfile")
  const { risk_rule_name, policy_id, rule_deleted, showLink = true } = props
  return rule_deleted ? (
    <PolicyExecutionRowTitle>
      {risk_rule_name} ({t("actionLog.ruleDeleted")})
    </PolicyExecutionRowTitle>
  ) : showLink ? (
    <PolicyExecutionRowTitleLink
      to={`/engagement/vision2/risk-response-engine/risk-response-rules?policy-id=${policy_id}`}
    >
      {risk_rule_name}
    </PolicyExecutionRowTitleLink>
  ) : (
    <PolicyExecutionRowTitle>{risk_rule_name}</PolicyExecutionRowTitle>
  )
}

export default function ActionLog({ profileId, onSwitchToActionlogsTab }) {
  const { t } = useTranslation("individualRiskProfile")
  const { loading, data } = useRequest(
    () =>
      getGlobalConfig("ENABLE_IRP_ACTION_LOG_GROUPING")
        ? getIndividualsGroupedRulesAuditLogs(profileId, {
            order_by: "created_datetime desc",
            page_number: 1,
            page_size: 10,
          })
        : getPoliciesActionLogs(
            { page_number: 1, page_size: 10 },
            profileId,
          ).then((res) => {
            return {
              data: {
                results: res.data.results.map((result) => ({
                  ...result,
                  policy_execution_id: `${result.rule_id}-${result.execution_datetime}`,
                  created_datetime: new Date(Number(result.execution_datetime)),
                  showLink: false,
                })),
              },
            }
          }),
    {
      refreshDeps: [],
      paginated: true,
      defaultPageSize: 10,
      formatResult: (response) => ({
        list: response.data.results,
      }),
    },
  )

  return (
    <PolicyActionLogWrapper>
      {loading || (data && !!data.list.length) ? (
        <TableWrapper>
          <ItemRow>
            <TableHeader>{t("actionLog.tableHeader")}</TableHeader>
          </ItemRow>
          {loading && <ActionLogsLoader rows={6} />}
          {!loading &&
            data?.list?.map((item) => (
              <ItemRow key={item.policy_execution_id}>
                <TitleComponent {...item} />
                <ContentRow>
                  <PolicyExecutionDate>
                    {timeMonthDayYear({
                      date: item.created_datetime,
                    })}
                  </PolicyExecutionDate>
                </ContentRow>
              </ItemRow>
            ))}
          <ButtonRow>
            <Button onClick={() => onSwitchToActionlogsTab()} theme="ghost">
              {t("actionLog.viewActionLog")}
            </Button>
          </ButtonRow>
        </TableWrapper>
      ) : (
        <NoActionItemsWrapper>
          {getGlobalConfig("ELLIE") && (
            <NoActionItemImage
              src="https://hm-assets.elevatesecurity.com/master/pulse/Indestructible.png"
              alt=""
              width="20%"
            />
          )}
          <NoActionItemsMessageWrapper>
            <NoDataTextHeader>
              {t("actionLog.noActionsMessage")}
            </NoDataTextHeader>
            <ButtonRow>
              <Button onClick={() => onSwitchToActionlogsTab()}>
                {t("actionLog.viewActionLog")}
              </Button>
            </ButtonRow>
          </NoActionItemsMessageWrapper>
        </NoActionItemsWrapper>
      )}
    </PolicyActionLogWrapper>
  )
}

ActionLog.propTypes = {
  profileId: PropTypes.string.isRequired,
  onSwitchToActionlogsTab: PropTypes.func.isRequired,
}

TitleComponent.propTypes = {
  risk_rule_name: PropTypes.string.isRequired,
  policy_id: PropTypes.string.isRequired,
  rule_deleted: PropTypes.bool.isRequired,
}
