import { useState } from "react"
import { useRequest } from "ahooks"
import { getPoliciesActionLogs } from "@src/services/apis/policies"
import { getDateRangePeriodBefore } from "@src/utils/dates"
import qs from "qs"
import moment from "moment"

export type ActionLog = Readonly<{
  mc_person_id: string
  risk_rule_name: string
  first_name: string
  last_name: string
  email: string
  status: "success" | "failed" | "suppressed"
  execution_datetime: string
  action: "email" | "email_with_video"
}>

type ActionLogsResponse = {
  data: {
    total_elements: number
    results: Readonly<ActionLog[]>
  }
}

export type AppliedFiltersType = {
  executed_datetime_start?: string
  executed_datetime_end?: string
  action_status_filter?: string[]
  action_filter?: string[]
}

type ArgumentType = {
  ruleIds?: string[]
  appliedFilters?: AppliedFiltersType
  orderBy?: string
  personId?: string
  pagination: { pageSize: number; current: number }
  search?: string
}

export function useMimecastActionLogs({
  ruleIds,
  orderBy,
  personId,
  pagination,
  search,
}: ArgumentType) {
  const { startDate, endDate } = getDateRangePeriodBefore(3, "months", {
    endDateOffset: 0,
  })
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersType>({
    executed_datetime_start: startDate,
    executed_datetime_end: endDate,
  })

  const { data, loading } = useRequest<ActionLogsResponse>(
    () => {
      const datetimesWithTz: Pick<
        AppliedFiltersType,
        "executed_datetime_start" | "executed_datetime_end"
      > = {}
      if (appliedFilters.executed_datetime_start) {
        datetimesWithTz.executed_datetime_start = moment(
          appliedFilters.executed_datetime_start,
        ).format()
      }
      if (appliedFilters.executed_datetime_end) {
        datetimesWithTz.executed_datetime_end = moment(
          appliedFilters.executed_datetime_end,
        )
          .add(1, "day")
          .subtract(1, "second")
          .format()
      }
      return getPoliciesActionLogs(
        {
          ...appliedFilters,
          ...datetimesWithTz,
          order_by: orderBy,
          page_size: pagination.pageSize,
          page_number: pagination.current,
          rule_id: ruleIds,
          search_bar: search,
        },
        personId,
        (params) => {
          return qs.stringify(
            Object.fromEntries(
              Object.entries(params).filter(
                ([, value]) => value !== "" && value !== undefined,
              ),
            ),
            { arrayFormat: "comma" },
          )
        },
      )
    },
    {
      refreshDeps: [
        ruleIds,
        pagination.pageSize,
        pagination.current,
        appliedFilters,
        orderBy,
        search,
      ],
    },
  )

  return { data, loading, setAppliedFilters, appliedFilters }
}
