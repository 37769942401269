export const PRODUCT_KEY = {
  PULSE: "pulse",
  HM: "hm",
  REFLEX: "reflex",
}

export const FACTOR_DESCRIPTIONS = {
  "Real World Phishing":
    "Actual Phishing refers to actions in response to phishing attempts generated by attackers outside the organization as opposed to internal simulated phishing campaigns.",
  Training:
    "Training identifies further areas for security improvement for individuals at your organization. Training is done to measure success in understanding of good security practices and to meet compliance requirements.",
  "Device Security":
    "Device Security measures who is and is not in compliance in your organization. This includes risky device configurations, VPN usage, unencrypted Wi-Fi usage, unauthorized software, and untrusted active content.",
  Phishing:
    "Simulated Phishing refers to actions in response to phishing campaigns that are generated internally as opposed to by external threat actors.",
  "Sensitive Data Handling":
    "Sensitive Data Handling refers to actions that involve data leaks by individuals at your organization who either accidentally or intentionally disclose company information to unauthorized parties.",
  "Password Manager":
    "Password Manager refers to actions that involve adoption and usage of a password manager by individuals at your organization.",
  "Unauthorized Software":
    "Unauthorized Software refers to actions that involve the download and usage of software that has not been formally approved by the Security Team at your organization.",
  Malware:
    "Malware refers to actions that involve how individuals at your organization introduce and/or respond to malicious software.",
  "Secure Browsing":
    "Secure Browsing refers to actions associated with Internet browsing activity, specifically as it relates to the safety and security risk posed by websites being accessed.",
}

export const getProducts = ({ showHackersmind, showPulse, showReflex }) => [
  ...(showPulse
    ? [
        {
          key: PRODUCT_KEY.PULSE,
          name: "Pulse",
          icon: "ProductPulseColored",
          campaignName: "Pulse Campaign",
        },
      ]
    : []),
  ...(showHackersmind
    ? [
        {
          key: PRODUCT_KEY.HM,
          name: "Hacker’s Mind",
          icon: "ProductHMColored",
          campaignName: "HM Campaign",
        },
      ]
    : []),
  ...(showReflex
    ? [
        {
          key: PRODUCT_KEY.REFLEX,
          name: "Reflex",
          icon: "ProductReflexColored",
          campaignName: "Reflex Campaign",
        },
      ]
    : []),
]

export const MINUTES_IN_A_DAY = 1440
export const MINUTES_IN_AN_HOUR = 60
export const GroupCharColor = ["#0C7D84", "#668CE5", "#AC3ECF"]
