import React from "react"
import Form from "@src/components/Form"
import { useDispatch, useSelector } from "react-redux"
import {
  setDescriptionRuleInfo,
  setRuleNameRuleInfo,
  setUseMailtrap,
} from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import Checkbox from "@src/components/Checkbox"
import { darklyGetFlag } from "@src/utils/darkly"

const RiskRulesInfo = () => {
  const dispatch = useDispatch()
  const { ruleName, ruleDescription, useMailtrap } = useSelector(
    (getReducers) => getReducers.get("ruleInfoReducer"),
  )

  const showUseMailTrapToggle = darklyGetFlag("see-use-mailtrap-toggle-on-rrr")

  const riskRuleInfo = [
    {
      fieldType: "text",
      fieldName: "ruleName",
      label: "Rule Name",
      showLabel: true,
      helpText: "",
      fieldProps: {
        defaultValue: ruleName,
        required: true,
        width: "full",
        autoFocus: true,
        onChange: (value) => dispatch(setRuleNameRuleInfo(value)),
        placeholder: "Name your rule",
      },
    },
    {
      fieldType: "text",
      fieldName: "ruleDescription",
      label: "Rule Description",
      showLabel: true,
      helpText: "",
      fieldProps: {
        defaultValue: ruleDescription,
        required: false,
        width: "full",
        onChange: (value) => dispatch(setDescriptionRuleInfo(value)),
        placeholder: "Optional",
      },
    },
  ]

  return (
    <div>
      <Form
        formFields={riskRuleInfo}
        handleSubmit={() => {}}
        showExcludeInclude={() => null}
      />
      {showUseMailTrapToggle && (
        <Checkbox
          label="Use mailtrap"
          checked={useMailtrap}
          onChange={(checked) => dispatch(setUseMailtrap(checked))}
        />
      )}
    </div>
  )
}

export default RiskRulesInfo
