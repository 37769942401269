import React, { useState } from "react"

const SelectedIndividualContextState = React.createContext()
const SelectedIndividualContextDispatch = React.createContext()

export function useSelectedIndividualState() {
  const context = React.useContext(SelectedIndividualContextState)
  if (context === undefined) {
    throw new Error(
      "useSelectedIndividualState must be used within a SelectedIndividualContextState",
    )
  }
  return context
}
export function useSelectedIndividualDispatch() {
  const context = React.useContext(SelectedIndividualContextDispatch)
  if (context === undefined) {
    throw new Error(
      "useSelectedIndividualDispatch must be used within a SelectedIndividualContextDispatch",
    )
  }
  return context
}

function SelectedIndividualProviders({ children }) {
  const [checked, setChecked] = useState([])
  const [unChecked, setUnChecked] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)

  const toggleCheckbox = (id) => (value) => {
    if (checkedAll) {
      if (value) setUnChecked(unChecked.filter((item) => item !== id))
      else setUnChecked([...unChecked, id])
    } else {
      if (value) setChecked((checked) => [id, ...checked])
      else setChecked((checked) => checked.filter((item) => item !== id))
    }
  }

  const onCheckAllChange = (value) => {
    setChecked([])
    setUnChecked([])
    setCheckedAll(value)
  }

  return (
    <SelectedIndividualContextState.Provider
      value={{
        checked,
        unChecked,
        checkedAll,
        setChecked,
      }}
    >
      <SelectedIndividualContextDispatch.Provider
        value={{
          toggleCheckbox,
          onCheckAllChange,
        }}
      >
        {children}
      </SelectedIndividualContextDispatch.Provider>
    </SelectedIndividualContextState.Provider>
  )
}

export default SelectedIndividualProviders
