import TextOverflow from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/TextOverflow"
import { humanize } from "@src/utils/string"
import { timeWithTimeZone } from "@src/utils/dates"
import React from "react"
import { NavLink } from "react-router-dom"
import { ColumnWrapper, ScoreDiv } from "./style"
import BarColors from "@src/components/ScoreHorizontalBar/BarColors"
import { SkeletonLoader } from "@src/utils/skeleton"
import { TableCellSpan } from "@src/components/TableCellSpan"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"

export const getStringDataRender = (loading, value) => {
  if (loading) {
    return (
      <div style={{ width: "90%" }}>
        <SkeletonLoader />
      </div>
    )
  }
  return (
    <div style={{ paddingLeft: "5px" }}>
      <TextOverflow name={humanize(value || "")} color="#565d66" />
    </div>
  )
}
export const getSortProperty = (sort, key) => {
  if (!sort.includes(key)) return {}

  return {
    asc: !sort.includes("desc"),
  }
}

const getDataSourceEventColumn = (sort, showDatasourceEventLog, loading) => {
  return showDatasourceEventLog
    ? [
        {
          key: "data_source",
          header: humanize("data_source").toUpperCase(),
          render: (value) => getStringDataRender(loading, value),
          ...getSortProperty(sort, "data_source"),
        },
      ]
    : []
}

export const getColumns = (sort, loading, showDataSourceCol) => {
  return [
    {
      key: "full_name",
      header: "full name".toUpperCase(),
      render: (value, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <NavLink
            title={value}
            style={{
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            target="_blank"
            to={{
              pathname: `/engagement/vision2/${row?.es_person_id}/profile`,
            }}
          >
            {humanize(value || "")}
          </NavLink>
        ),
      ...getSortProperty(sort, "full_name"),
    },
    {
      key: "event",
      header: "event".toUpperCase(),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TextOverflow name={humanize(value)} color="#565d66" />
        ),
      ...getSortProperty(sort, "action"),
    },
    {
      key: "date",
      header: "DATE",
      render: (date) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan style={{ padding: "0 4px" }} title={date}>
            {timeWithTimeZone({
              date: date,
            })}
          </TableCellSpan>
        ),
      ...getSortProperty(sort, "date"),
    },
    ...getDataSourceEventColumn(sort, showDataSourceCol, loading),
    {
      key: "action",
      header: "action".toUpperCase(),
      render: (value) => getStringDataRender(loading, value),
      ...getSortProperty(sort, "action"),
    },
  ]
}
const getColor = (score) => {
  if (score >= 8 && score <= 10) return "#D23A54"
  else if (score >= 6 && score < 8) return "#F08526"
  else if (score >= 4 && score < 6) return "#FACC38"
  else if (score >= 2 && score < 4) return "#3CC1AE"
  else return "#2C9097"
}

export const Score = ({ score }) => {
  return (
    <ColumnWrapper>
      <ScoreDiv>{score === null ? "-" : formatUserFacingScore(score)}</ScoreDiv>
      <BarColors
        score={Math.ceil(score * 10)}
        showCircle={false}
        size="small"
        sameColor={true}
        barColor={getColor(score)}
        padding={true}
        showWideBar={false}
        formatter={formatUserFacingScore}
      />
    </ColumnWrapper>
  )
}

const quantiledOptions = ["attack_factor"]

const simpleColumns = ["department", "location"]

export const getIndividualsInvolvedColumns = (sort, loading) => {
  return [
    {
      key: "full_name",
      header: "full name".toUpperCase(),
      render: (value, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <NavLink
            style={{
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            target="_blank"
            to={{
              pathname: `/engagement/vision2/${row?.es_person_id}/profile`,
            }}
          >
            {`${humanize(value)}`}
          </NavLink>
        ),
      ...getSortProperty(sort, "full_name"),
    },
    {
      key: "human_risk_score",
      header: "human risk score".toUpperCase(),
      render: (_, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <Score score={roundUserFacingScore(row?.human_risk_score)} />
        ),
      ...getSortProperty(sort, "human_risk_score"),
    },
    {
      key: "email",
      header: "Email".toUpperCase(),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <div>{value}</div>
        ),
    },
    ...quantiledOptions.map((column) => ({
      key: column,
      header: column.toUpperCase().replaceAll("_", " "),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          humanize(value)
        ),
      ...getSortProperty(sort, column),
    })),
    ...simpleColumns.map((key) => ({
      key,
      header: key.toUpperCase().replaceAll("_", " "),
      render: (value) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          value
        ),
      ...getSortProperty(sort, key),
    })),
  ]
}
