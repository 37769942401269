import React from "react"
import { humanize, isEmpty } from "@src/utils/string"
import { SkeletonLoader } from "@src/utils/skeleton"
import {
  ActionWrapper,
  CurrentScoreLabel,
  CurrentScoreNumber,
  ShowRiskTrend,
  SparkLineWrapper,
  ActionFactorReviewButton,
} from "./style"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"
import { Icon } from "@elevate_security/elevate-component-library"
import SparkLine from "@src/components/SparkLine"
import { TableCellSpan } from "@src/components/TableCellSpan"
import { SpacingX } from "@src/components/SpacingX"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { roundUserFacingScore } from "@src/utils/numbers/roundUserFacingScore"

const quintileLabel = (score) => {
  if (isEmpty(score)) return "N/A"
  if (0 <= score && score < 2) return "Very Low"
  if (2 <= score && score < 4) return "Low"
  if (4 <= score && score < 6) return "Medium"
  if (6 <= score && score < 8) return "High"
  if (8 <= score && score <= 10) return "Very High"
  return "N/A"
}

const showTrend = (currentScore, previousScore) => {
  const scoreDiff =
    roundUserFacingScore(currentScore) - roundUserFacingScore(previousScore)
  const { color, icon } = getIconAndColorByScoreDiff(
    roundUserFacingScore(scoreDiff),
  )
  return (
    <ShowRiskTrend color={color}>
      <Icon name={icon} fill={color} />
      {formatUserFacingScore(scoreDiff)}
    </ShowRiskTrend>
  )
}

const showReviewLinks = (action, showActions, index, openSideDrawer) => {
  if (!showActions?.includes(action)) return
  return (
    <ActionFactorReviewButton
      data-id={`action-factor-button-${index}`}
      style={{ marginLeft: "5px" }}
      onClick={openSideDrawer}
    >
      Review <Icon name="CaretRight" size="xsm" />
    </ActionFactorReviewButton>
  )
}

const getRiskTrendColumns = (loading, showActions, openSideDrawer) => {
  const isActionClickable = (action, rowData) => {
    if (showActions?.includes(action)) {
      return (
        <ActionFactorReviewButton
          style={{ marginLeft: 0 }}
          onClick={() => {
            openSideDrawer({
              keyFactor: rowData.review.name,
              currentFactorScore: rowData.review.value,
              trendValue: rowData.review.trending,
            })
          }}
        >
          {humanize(action)}
        </ActionFactorReviewButton>
      )
    }
    return <ActionWrapper color="#565a5d">{humanize(action)}</ActionWrapper>
  }

  return [
    {
      key: "action",
      header: "action".toUpperCase(),
      render: (action, rowData) => {
        return loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          isActionClickable(action, rowData)
        )
      },
    },
    {
      key: "trendData",
      header: "Trend (PAST 12 Months)".toUpperCase(),
      render: (trendData) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <SparkLineWrapper>
            <SparkLine
              behaviorScores={{ trendData: trendData }}
              minMaxValue={{
                min: 0.0,
                max: 10.0,
              }}
            />
          </SparkLineWrapper>
        ),
    },
    {
      key: "current_score",
      header: "current score".toUpperCase(),
      render: (current_score) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan>
            <CurrentScoreLabel>
              <SpacingX>
                <CurrentScoreNumber>
                  {formatUserFacingScore(current_score)}
                </CurrentScoreNumber>
                <span>{quintileLabel(current_score)}</span>
              </SpacingX>
            </CurrentScoreLabel>
          </TableCellSpan>
        ),
    },
    {
      key: "past_1_month",
      header: "vs. past 1 month".toUpperCase(),
      render: (pastMonth, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          <TableCellSpan style={{ paddingLeft: "4px" }}>
            {showTrend(row.current_score, pastMonth)}
          </TableCellSpan>
        ),
    },
    {
      key: "past_12_month",
      header: "vs. past 12 month".toUpperCase(),
      render: (yearData, row) =>
        loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          showTrend(row.current_score, yearData)
        ),
    },
    {
      key: "review",
      header: "",
      render: (_, rowData, index) => {
        return loading ? (
          <div style={{ width: "90%" }}>
            <SkeletonLoader />
          </div>
        ) : (
          showReviewLinks(rowData.action, showActions, index, () => {
            openSideDrawer({
              keyFactor: rowData.review.name,
              currentFactorScore: rowData.review.value,
              trendValue: rowData.review.trending,
            })
          })
        )
      },
    },
  ]
}

export default getRiskTrendColumns
