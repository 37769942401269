import fetcher from "@src/services/fetcher"
import { fetchAll } from "@src/utils/fetchAll"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import { buildQueryString } from "@src/utils/buildQueryString"

import type { Watchlist } from "./useWatchlist"

export function useWatchlists({
  pagination,
  search,
}: {
  pagination?: { pageSize: number; current: number }
  search?: string
} = {}): {
  data?: { results: Watchlist[]; total_elements: number }
  loading: boolean
  error?: Error
} {
  const { loading, data, error } = useRequest(
    () => {
      if (!pagination) {
        return fetchAll((pagination) =>
          fetchWatchlistsPage({ ...pagination, search }),
        )
      }
      return fetchWatchlistsPage({
        search,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
      })
    },
    {
      refreshDeps: [pagination?.pageSize, pagination?.current, search],
    },
  )
  return { loading, data, error }
}

function fetchWatchlistsPage({
  search,
  pageSize,
  pageNumber,
}: {
  search?: string
  pageSize: number
  pageNumber: number
}): Promise<{ results: Watchlist[]; total_elements: number }> {
  const params = buildQueryString({
    page_size: pageSize,
    page_number: pageNumber,
    name: search,
  })
  return fetcher
    .get(`${BASES_REPUTATIONS_API_URL}/watchlists?${params}`)
    .then((res) => res.data)
}
