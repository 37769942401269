import React, { useCallback } from "react"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

type Options = { locale?: string; format?: string }

/*
 * Examples
 * en-US 12/24/2024
 * en-GB 24/12/2024
 * de-DE 24.12.2024
 * es-ES 24/12/2024
 * fr-CA 2024-12-24
 * fr-FR 24/12/2024
 * it-IT 24/12/2024
 * nl-NL 24-12-2024
 * pt-PT 24/12/2024
 */
export function formatLocaleDate(
  date?: number | string | Date,
  { locale = "en-US", format = "L" }: Options = {},
): string {
  const localeFormatter = moment(date)
  localeFormatter.locale(locale)
  return localeFormatter.format(format)
}

export function formatLocaleCurrentDate(options?: Options): string {
  return formatLocaleDate(undefined, options)
}

export function useFormattedLocaleDate(
  date?: number | string | Date,
  options?: Omit<Options, "locale">,
) {
  const { i18n } = useTranslation()
  return formatLocaleDate(date, { ...options, locale: i18n.language })
}

export function useLocaleDateFormatter() {
  const { i18n } = useTranslation()
  return useCallback(
    (date: number | string | Date, options: Omit<Options, "locale">) => {
      return formatLocaleDate(date, { ...options, locale: i18n.language })
    },
    [i18n.language],
  )
}

export function FormattedLocaleDate({
  date,
}: {
  date?: number | string | Date
}) {
  return <span>{useFormattedLocaleDate(date)}</span>
}
