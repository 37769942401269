import React, { useMemo, useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import {
  useIndividualRiskAnalysisState,
  useIndividualRiskAnalysisActions,
} from "../../context/IndividualRiskAnalysisContextProvider"
import { TableWrapper } from "./styles"
import getColumns from "./columns"
import useRequest from "@ahooksjs/use-request"
import SelectedIndividualProviders, {
  useSelectedIndividualDispatch,
  useSelectedIndividualState,
} from "./SelectedIndividualProviders"
import useRiskAnalysisURLParam from "../useRiskAnalysisURLParam"
import { Table } from "@src/components/MimecastReskin"
import { getColumnsChooserDefinition } from "./columnsChooser"
import SideDrawer from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer"
import { useSelector, useDispatch } from "react-redux"
import {
  closeSideDrawer,
  setRiskCount,
} from "@src/services/redux/analysisIndividuals/actions"
import { PRODUCT_KEY } from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/utils.js"
import { isServiceEntitled, isServiceVisible } from "@src/utils/session"
import { getCompanyServices } from "@src/services/redux/company/selectors"
import {
  TARGET_TYPE,
  getRiskAnalysisURL,
} from "@src/scenes/IndividualRiskAnalysis/utils.js"
import { useActions } from "@src/utils/redux"
import {
  createGroupWithFilters,
  createCampaignHM,
  createCampaignPulse,
  createCampaignReflex,
} from "@src/services/redux/campaigns/actions"
import { createGroupWithFilters as createGroupWithFiltersReputation } from "@src/services/redux/reputations/actions"
import moment from "moment"
import { darklyGetFlag } from "@src/utils/darkly"
import {
  FILTER_KEY,
  translateParamsToFilters,
  translateFiltersToParams,
  extractFiltersString,
} from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils.js"
import {
  setScoreByDynamicEndpoint,
  setRecommendedIndividualsForHM,
  setRecommendedIndividualsForPulse,
  setRecommendedIndividualsForReflex,
  setTotalIndividualsCount,
} from "@src/services/redux/scores/actions"
import { RISK_DATA_TYPES } from "@src/constants"
import { getLocalStorage, setLocalStorage } from "@src/utils/storage"
import {
  ALL_HR_METADATA_FIELDS,
  dummyData,
  sortNameMap,
} from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import DragosaurWithUmbrella from "@src/components/DragosaurWithUmbrella"
import { DragoWrapper } from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import { FiltersToolbar } from "./components/FiltersToolbar"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { getGlobalConfig } from "@src/globalConfig"
import { useTranslation } from "react-i18next"
import { humanize } from "@src/utils/string"

const getOrderByFromUrl = (order_by, orderBy) => {
  const [property, direction] = order_by?.split(" ") ?? [orderBy, "desc"]
  return {
    property,
    direction,
  }
}

function IndividualRiskAnalysisTable({
  history,
  behaviorTypes,
  riskType,
  selected,
  allowCellWrap = false,
  formatter,
}) {
  const { risksNames } = useIndividualRiskAnalysisState()
  const actions = useIndividualRiskAnalysisActions()
  const { checked, unChecked, checkedAll } = useSelectedIndividualState()
  const { toggleCheckbox, onCheckAllChange } = useSelectedIndividualDispatch()
  const reflex2Enabled = darklyGetFlag("show-reflex-mvp")
  const vcDemoFlag = darklyGetFlag("show-vc-demo-features")
  const whoAmI = useSelector(getWhoAMI)

  const {
    location: { search },
  } = history
  const drawerActions = useActions({
    setScoreByDynamicEndpoint,
    setRecommendedIndividualsForHM,
    setRecommendedIndividualsForPulse,
    setRecommendedIndividualsForReflex,
    setTotalIndividualsCount,
    createGroupWithFilters,
    createCampaignHM,
    createCampaignPulse,
    createCampaignReflex,
    createGroupWithFiltersReputation,
  })

  const { filters, order_by, updateUrlWithNewOrderBy } =
    useRiskAnalysisURLParam()
  const { hackersmind, pulse, reflex } = useSelector(getCompanyServices)
  const showReflex = isServiceEntitled(reflex) && isServiceVisible(reflex)
  const showPulse = isServiceEntitled(pulse) && isServiceVisible(pulse)
  const showHackersmind =
    isServiceEntitled(hackersmind) && isServiceVisible(hackersmind)
  const analysisIndividual = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const dispatch = useDispatch()
  const closeDrawer = () => dispatch(closeSideDrawer())
  const riskData = RISK_DATA_TYPES[riskType || "individual"] || {}

  const advanced_filters = analysisIndividual.advanced_filters

  const { loading, data, pagination } = useRequest(
    ({ current, pageSize, ...params }) => {
      return actions.getRisksAnalysisData({
        ...(advanced_filters
          ? { advanced_filters: JSON.stringify(advanced_filters) }
          : { filters: filters || null }),
        order_by: order_by || `${riskData.orderBy}`,
        page_number: current,
        page_size: pageSize,
        url: riskData.ApiUrl,
        ...params,
      })
    },
    {
      paginated: true,
      defaultPageSize: 25,
      refreshDeps: [
        filters,
        order_by,
        riskType,
        JSON.stringify(advanced_filters),
      ],
      formatResult: (response) => {
        dispatch(setRiskCount(response.data?.total_elements ?? 0))
        return {
          total: response.data?.total_elements ?? 0,
          list: response.data?.results ?? [],
        }
      },
    },
  )

  useEffect(() => {
    onCheckAllChange(false)
  }, [riskType])

  const indeterminate = checkedAll ? unChecked.length > 0 : checked.length > 0
  const columns = useMemo(
    () =>
      getColumns(
        {
          riskType,
          risksNames,
          sort: getOrderByFromUrl(order_by, riskData.orderBy),
          checked,
          toggleCheckbox,
          list: data?.list || [],
          onCheckAllChange,
          indeterminate,
          checkedAll,
          unChecked,
          showNewDesign: riskData.showNewDesign,
          firstColumn: riskData.firstColumn,
          loading,
          behaviorTypes,
          isAdmin: whoAmI?.is_admin,
          allowCellWrap,
          formatter,
        },
        analysisIndividual.availableHRMetadataKeys,
      ),
    [pagination, risksNames, checked, order_by, behaviorTypes, riskType],
  )
  const chosenColumns = useMemo(() => {
    return [...columns.filter((c) => selected.some((s) => s === c.key))]
  }, [columns, selected])
  const handleChangeTable = async (info) => {
    const page = info.pagination.offset / info.pagination.limit + 1
    if (
      pagination.current !== page ||
      pagination.pageSize !== info.pagination.limit
    ) {
      pagination.onChange(page, info.pagination.limit)
    }
  }
  const handleSortedChange = (property, direction) =>
    updateUrlWithNewOrderBy(`${sortNameMap[property] || property} ${direction}`)
  const createCampaign = async (product, params, payload, cb) => {
    const createCampaignAction = {
      [PRODUCT_KEY.PULSE]: drawerActions.createCampaignPulse,
      [PRODUCT_KEY.HM]: drawerActions.createCampaignHM,
      [PRODUCT_KEY.REFLEX]: drawerActions.createCampaignReflex,
    }
    const redirectURL = {
      [PRODUCT_KEY.PULSE]: (id) => `/engagement/pulse/campaigns/${id}`,
      [PRODUCT_KEY.HM]: (id) =>
        `/engagement/hackersmind/campaigns/campaign/${id}`,
      [PRODUCT_KEY.REFLEX]: (id) =>
        reflex2Enabled
          ? `/engagement/reflex2/campaigns/${id}`
          : `/engagement/reflex/campaign/edit/${id}`,
    }
    const createGroup = !checkedAll
      ? drawerActions.createGroupWithFilters
      : drawerActions.createGroupWithFiltersReputation
    params = !checkedAll ? params : extractFiltersString(searchParam)
    const { error, data: groupData } = await createGroup(params, payload)
    if (!error) {
      const { id: group_id, name: group_name } = groupData.group
      const name = `${product.campaignName} ${moment().format(
        "MM/DD/YYYY HH:mm:ss",
      )}`

      const { error: error2, data: data2 } = await createCampaignAction[
        product.key
      ](name, group_id, group_name)
      if (!error2) {
        history.push(redirectURL[product.key](data2.id))
      } else if (cb) {
        cb()
      }
    } else if (cb) {
      cb()
    }
  }

  const searchParam = decodeURI(search.slice(1))
  const [queryParams, setQueryParams] = useState({
    limit: 25,
    offset: 0,
    ordering: "first_name",
    score_ordering: "",
    filters: "",
  })

  useEffect(() => {
    const filters = translateParamsToFilters(searchParam)

    const sortFilter = filters.find(
      (f) => f.key === FILTER_KEY.SORT || f.key === FILTER_KEY.SORT_SCORE,
    )
    if (sortFilter) {
      setTimeout(() => {
        setQueryParams({
          ...queryParams,
          [FILTER_KEY.SORT]:
            sortFilter.key === FILTER_KEY.SORT ? sortFilter.value : "",
          [FILTER_KEY.SORT_SCORE]:
            sortFilter.key === FILTER_KEY.SORT_SCORE ? sortFilter.value : "",
        })
        handleApplyFilters(
          filters.filter(
            (f) =>
              !(f.key === FILTER_KEY.SORT || f.key === FILTER_KEY.SORT_SCORE),
          ),
        )
      }, 0)
    }
  }, [])

  const handleApplyFilters = (newFilters) => {
    const urlParams = translateFiltersToParams(newFilters)
    history.push(getRiskAnalysisURL("individual", "risk-analysis", urlParams))
  }

  const handleOnEnroll = async (product, cb) => {
    const params =
      checkedAll && queryParams.filters
        ? `?${queryParams.filters.slice(1)}`
        : ""
    const payload = {
      targeting_type: !checkedAll
        ? TARGET_TYPE.IDS
        : params
          ? TARGET_TYPE.QUERY
          : TARGET_TYPE.EVERYONE,
      ...(checked.length ? { target_ids: checked } : {}),
      ...(unChecked.length ? { do_not_target_ids: unChecked } : {}),
    }
    createCampaign(product, params, payload, cb)
  }
  const handleRecommendedEnroll = (product, unCheckedIds, cb) => {
    const params = `?${
      product.key === PRODUCT_KEY.REFLEX
        ? FILTER_KEY.SCORE_PHISHING
        : FILTER_KEY.SCORE_OVERALL
    }=0-59`
    const payload = {
      targeting_type: TARGET_TYPE.QUERY,
      ...(unCheckedIds.length ? { do_not_target_ids: unCheckedIds } : {}),
    }
    createCampaign(product, params, payload, cb)
  }
  const getRecommendedIndividuals = (key, limit = 20, offset = 0) => {
    if (!key || key === PRODUCT_KEY.HM) {
      drawerActions.setRecommendedIndividualsForHM({
        limit,
        offset,
        filters: `&${FILTER_KEY.SCORE_OVERALL}=0-59&ordering=first_name`,
      })
    }
    if (!key || key === PRODUCT_KEY.PULSE) {
      drawerActions.setRecommendedIndividualsForPulse({
        limit,
        offset,
        filters: `&${FILTER_KEY.SCORE_OVERALL}=0-59&ordering=first_name`,
      })
    }
    if (!key || key === PRODUCT_KEY.REFLEX) {
      drawerActions.setRecommendedIndividualsForReflex({
        limit,
        offset,
        filters: `&${FILTER_KEY.SCORE_PHISHING}=0-59&ordering=first_name`,
      })
    }
  }
  return (
    <TableWrapper
      numberOfColumns={chosenColumns.length || 1}
      haveExtraColumns={riskData?.showNewDesign}
      hasEmail={riskType === "individual"}
      hideFirstColumn={!getGlobalConfig("ENABLE_RAP_BULK_ACTIONS")}
    >
      {loading || (data && !!data.list.length) ? (
        <Table
          resizable
          data={loading ? dummyData : data?.list || []}
          columns={chosenColumns}
          onChangeTable={handleChangeTable}
          manual
          totalCount={pagination.total}
          defaultPage={pagination.current}
          defaultPageSize={pagination.pageSize}
          onSortedChange={handleSortedChange}
          pageSizeOptions={[10, 25, 50, 100]}
          excludeSortColumn={getGlobalConfig("RAP_SORT_EXCLUSIONS")}
          formatter={formatter}
        />
      ) : (
        <DragoWrapper>
          <DragosaurWithUmbrella
            message={formatter("noTableData", "No data available")}
            showTooltip={false}
          />
        </DragoWrapper>
      )}
      <SideDrawer
        count={checkedAll ? data?.total : checked.length}
        onEnroll={handleOnEnroll}
        onRecommendedEnroll={handleRecommendedEnroll}
        onClose={closeDrawer}
        onPageChange={getRecommendedIndividuals}
        showHackersmind={showHackersmind}
        showPulse={showPulse}
        showReflex={showReflex}
        showInsightConditions={vcDemoFlag}
        showDrawer={analysisIndividual.showSideBar}
      />
    </TableWrapper>
  )
}

function filterAvailableHRFields(riskType, columns, availableHRFields) {
  if (riskType === "individual") {
    return columns?.filter((column) => {
      if (ALL_HR_METADATA_FIELDS?.includes(column?.key)) {
        return availableHRFields?.includes(column?.key)
      }
      return true
    })
  }
  return columns
}

const useColumnChooserState = ({
  riskType,
  actionsNames,
  activeActions,
  formatter,
}) => {
  const { availableHRMetadataKeys } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )

  const columns = useMemo(() => {
    const extraRiskFields = RISK_DATA_TYPES[riskType]?.extraRiskNames || []
    const firstColumn = RISK_DATA_TYPES[riskType]?.firstColumn
    const columnsList = filterAvailableHRFields(
      riskType,
      RISK_DATA_TYPES[riskType]?.columns || [],
      availableHRMetadataKeys,
    )
    return getColumnsChooserDefinition({
      risksNames: ["overall"],
      extraRiskFields,
      firstColumn,
      columnsList: columnsList,
      riskType,
      behaviorTypes: actionsNames.filter(
        (behaviour) =>
          activeActions?.includes(behaviour?.replaceAll("_", "-")) &&
          behaviour !== "overall",
      ),
      formatter,
    })
  }, [riskType, actionsNames, availableHRMetadataKeys])

  const defaultSelectedColumns = useMemo(() => {
    const localSelected = getLocalStorage(`${riskType}_columns`)
    const existColumns = columns.some((column) =>
      localSelected?.includes(column.key),
    )
    if (localSelected && existColumns) {
      return columns
        .filter(
          (column) =>
            localSelected.includes(column.key) ||
            column.key === "full_name" ||
            column.key === "es_person_id" ||
            column.key === "human_risk_score",
        )
        .map((c) => c.key)
    } else {
      return columns.filter((c) => c.selected).map((c) => c.key)
    }
  }, [columns, riskType])

  const [selected, setSelected] = useState(defaultSelectedColumns)

  useEffect(() => {
    setSelected(defaultSelectedColumns)
  }, [riskType, defaultSelectedColumns])

  const [shouldPersist, setShouldPersist] = useState({})

  const isSelected = (key) => selected.includes(key)

  const toggle = (key) => {
    setShouldPersist((prev) => ({ ...prev, [riskType]: true }))
    setSelected((prev) => {
      if (prev.includes(key)) {
        return prev.filter((c) => c != key)
      } else {
        return prev.concat(key)
      }
    })
  }

  useEffect(() => {
    if (shouldPersist[riskType]) {
      setLocalStorage(`${riskType}_columns`, JSON.stringify(selected))
    }
  }, [selected, riskType, shouldPersist])

  useEffect(() => {
    // When the user changes risk type, don't save choices until they actually
    // toggle something
    setShouldPersist((prev) => ({ ...prev, [riskType]: false }))
  }, [riskType])

  return {
    toggle,
    isSelected,
    selected,
    columns,
  }
}

const IndividualRiskAnalysisTableContainer = ({
  history,
  showFiltersToolbar = true,
  riskType,
  onSimpleFilterChange = () => {},
}) => {
  const { actionsNames } = useIndividualRiskAnalysisState()
  const activeActions = useSelector(selectActiveActions)

  const { t } = useTranslation("riskAnalysis", {
    keyPrefix: "columnChooserTexts",
  })
  const formatter = (key, name = key, interpolatedVal = null) => {
    return t(`columnLabelOption.${key}`, {
      defaultValue: humanize(name),
      value: interpolatedVal,
    })
  }

  const columnState = useColumnChooserState({
    riskType,
    actionsNames,
    activeActions,
    formatter,
  })

  const [allowCellWrap, setAllowCellWrap] = useState(
    Boolean(localStorage.getItem("RAP_allowCellWrap")),
  )

  return (
    <SelectedIndividualProviders>
      {showFiltersToolbar && (
        <FiltersToolbar
          onToggleTruncate={(checked) => {
            setAllowCellWrap(!checked)
          }}
          columnState={columnState}
          onSimpleFilterChange={onSimpleFilterChange}
          showBulkActions={getGlobalConfig("ENABLE_RAP_BULK_ACTIONS")}
          showAdvancedFilters={getGlobalConfig("ENABLE_RAP_ADVANCED_FILTERS")}
          showTableMode={getGlobalConfig("ENABLE_RAP_TABLE_MODE")}
        />
      )}
      <IndividualRiskAnalysisTable
        allowCellWrap={allowCellWrap}
        selected={columnState.selected}
        history={history}
        behaviorTypes={actionsNames}
        riskType={riskType}
        formatter={formatter}
      />
    </SelectedIndividualProviders>
  )
}
export default withRouter(IndividualRiskAnalysisTableContainer)
