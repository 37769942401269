import React from "react"
import { SpacingY } from "@src/components/SpacingY"
import {
  ScoreBox,
  ScoreBoxWrapper,
  ScoreBoxDescription,
  Strong,
} from "./styles"
import IconMalware from "./icon-malware.svg"
import IconPhishing from "./icon-phishing.svg"
import { getAttackExplanation } from "@src/services/apis/reputation"
import { darklyGetFlag } from "@src/utils/darkly"
import { IdentityExplanation } from "./IdentityExplanation"
import { useRequest } from "ahooks"
import { SkeletonLoader } from "@src/utils/skeleton"
import { useTranslation } from "react-i18next"

export function AttackExplanationCards({
  profileId,
  startDate,
  timePeriodValue,
}) {
  const { t } = useTranslation("individualRiskProfile")
  const { loading, data } = useRequest(
    () =>
      getAttackExplanation(profileId, {
        start_date: startDate,
      }),
    {
      refreshDeps: [timePeriodValue],
      formatResult: (res) => {
        return res?.data || {}
      },
    },
  )

  const isItdrEnabled = darklyGetFlag("itdr-behaviors")

  return (
    <SpacingY>
      <div>
        {t("attackScoreExplaination.timePeriod", {
          timePeriod: timePeriodValue?.toLocaleLowerCase() || "",
        })}
      </div>
      <ScoreBoxWrapper>
        <ScoreBox>
          {loading ? (
            <SkeletonLoader height={80} />
          ) : (
            <>
              <img src={IconPhishing} alt="logo" />
              <ScoreBoxDescription>
                <span>
                  {t("attackScoreExplaination.scoreBoxPhising.received")}
                </span>
                <Strong>
                  {" "}
                  {data?.phishing_received !== null
                    ? data?.phishing_received
                    : "-"}{" "}
                </Strong>
                {t("attackScoreExplaination.scoreBoxPhising.emails")}
              </ScoreBoxDescription>
            </>
          )}
        </ScoreBox>
        <ScoreBox>
          {loading ? (
            <SkeletonLoader height={80} />
          ) : (
            <>
              <img src={IconMalware} alt="logo" />
              <ScoreBoxDescription>
                <span>
                  {t("attackScoreExplaination.scoreboxMalware.wasInvolvedIn")}
                </span>
                <Strong>
                  {" "}
                  {data?.malware_received !== null
                    ? data?.malware_received
                    : "-"}{" "}
                </Strong>
                {t("attackScoreExplaination.scoreboxMalware.malwareIncidents")}
              </ScoreBoxDescription>
            </>
          )}
        </ScoreBox>
        {isItdrEnabled && (
          <>
            <IdentityExplanation
              attemptText={t("attackScoreExplaination.impossibleLogin")}
              successCount={data?.impossible_login_success_count}
              failureCount={data?.impossible_login_failure_count}
              loading={loading}
            />
            <IdentityExplanation
              attemptText={t("attackScoreExplaination.passwordBruteForce")}
              successCount={data?.password_brute_force_success_count}
              failureCount={data?.password_brute_force_failure_count}
              loading={loading}
            />
            <IdentityExplanation
              attemptText={t("attackScoreExplaination.credentialStuffing")}
              successCount={data?.credential_stuffing_success_count}
              failureCount={data?.credential_stuffing_failure_count}
              loading={loading}
            />
            <ScoreBox>
              {loading ? (
                <SkeletonLoader height={80} />
              ) : (
                <>
                  <img src={IconMalware} alt="logo" />
                  <ScoreBoxDescription>
                    <span>{t("attackScoreExplaination.wasDetectedIn")}</span>
                    <Strong>{data?.session_count_anomaly_count ?? "-"} </Strong>
                    {t("attackScoreExplaination.sessionCount")}{" "}
                    {t("attackScoreExplaination.anomaly", {
                      count: data?.login_anomaly_count || 0,
                    })}
                  </ScoreBoxDescription>
                </>
              )}
            </ScoreBox>
            <ScoreBox>
              {loading ? (
                <SkeletonLoader height={80} />
              ) : (
                <>
                  <img src={IconMalware} alt="logo" />
                  <ScoreBoxDescription>
                    <span> {t("attackScoreExplaination.wasDetectedIn")} </span>
                    <Strong>{data?.login_anomaly_count ?? "-"} </Strong>
                    {t("attackScoreExplaination.login")}{" "}
                    {t("attackScoreExplaination.anomaly", {
                      count: data?.login_anomaly_count || 0,
                    })}
                  </ScoreBoxDescription>
                </>
              )}
            </ScoreBox>
          </>
        )}
      </ScoreBoxWrapper>
    </SpacingY>
  )
}
