import {
  factorValues,
  FILTER_KEY,
  HR_DATA_FILTER_KEY,
} from "@src/scenes/IndividualRiskAnalysis/components/Filters/utils"
import { BOOLEAN_VALUES_MAP } from "@src/scenes/SecEngIndividualRiskAnalysis/constants"
import { getGlobalConfig } from "@src/globalConfig"
import { humanize } from "@src/utils/string"

export const FILTER_TYPE = {
  MULTI_SELECT: "multi-select",
  SLIDER: "slider",
}

const humanizeName = (_, name) => humanize(name)

export const getGenericFilterForField = (
  name,
  values,
  formatter = humanizeName,
) => {
  const size = values?.length
  const humanized_name = formatter(
    `columnChooserTexts.columnLabelOption.${name}`,
    name,
  )
  return {
    type: FILTER_TYPE.MULTI_SELECT,
    title: humanized_name,
    key: name,
    items: values.map((el) => ({
      label: BOOLEAN_VALUES_MAP[el] ?? el ?? "-",
      value: el ?? "not_set",
    })),
    ...(size > 4 && {
      showMore: formatter("riskFilter.showAll", "showAll", humanized_name),
      showLess: formatter("riskFilter.showLess", "showLess", humanized_name),
    }),
  }
}

export const individualsFilters = (
  fields,
  availableHRMetadataKeys,
  formatter,
) => {
  // All HR Fields
  const hrFields = [
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.JOB_TITLE,
      fields.jobTitle,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.BUSINESS_TITLE,
      fields.businessTitle,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.BUSINESS_UNIT,
      fields.businessUnit,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.IS_ONLEAVE,
      fields.isOnleave,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.JOB_FAMILY_FUNCTION_GROUP,
      fields.jobFamilyFunctionGroup,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.JOB_FAMILY_FUNCTION_NAME,
      fields.jobFamilyFunctionName,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_LANGUAGE,
      fields.preferredLanguage,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_NAME,
      fields.preferredName,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_MIDDLE_NAME,
      fields.preferredMiddleName,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.PREFERRED_NAME_LOCAL_LANG,
      fields.preferredNameLocalLang,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.TIME_TYPE,
      fields.timeType,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_COUNTRY,
      fields.workLocationCountry,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_GEO,
      fields.workLocationGeo,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_TIMEZONE,
      fields.workLocationTimezone,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORKER_TYPE,
      fields.workerType,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_BUILDING,
      fields.workLocationBuilding,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_CITY,
      fields.workLocationCity,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_LOCATION_NAME,
      fields.workLocationName,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.WORK_SPACE,
      fields.workSpace,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.LENGTH_OF_SERVICE,
      fields.lengthOfService,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.HIRE_DATE,
      fields.hireDate,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.IS_ACTIVE,
      fields.isActive,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.LEAVE_DATE,
      fields.leaveDate,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.ALTERNATIVE_USER_ID_1,
      fields.alternativeUserId1,
      formatter,
    ),
    getGenericFilterForField(
      HR_DATA_FILTER_KEY.ALTERNATIVE_USER_ID_2,
      fields.alternativeUserId2,
      formatter,
    ),
  ]
  const filteredFields = hrFields?.filter((field) =>
    availableHRMetadataKeys?.includes(field.key),
  )
  return [
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: formatter(
        `riskFilter.filterLabel.people.item.${FILTER_KEY.ORGANIZATIONAL_ROLE}`,
        FILTER_KEY.ORGANIZATIONAL_ROLE,
      ),
      key: FILTER_KEY.ORGANIZATIONAL_ROLE,
      items: [
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.manager",
            "manager",
          ),
          value: "manager",
        },
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.contributor",
            "contributor",
          ),
          value: "contributor",
        },
      ],
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: formatter(
        `riskFilter.filterLabel.people.item.${FILTER_KEY.EMPLOYMENT_TYPE}`,
        FILTER_KEY.EMPLOYMENT_TYPE,
      ),
      key: FILTER_KEY.EMPLOYMENT_TYPE,
      items: [
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.contractor",
            "contractor",
          ),
          value: "contractor",
        },
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.employee",
            "employee",
          ),
          value: "employee",
        },
        { label: "-", value: "not_set" },
      ],
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      title: formatter(
        `riskFilter.filterLabel.people.item.${FILTER_KEY.TENURE_STATUS}`,
        FILTER_KEY.TENURE_STATUS,
      ),
      key: FILTER_KEY.TENURE_STATUS,
      items: [
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.new_hire",
            "new_hire",
          ),
          value: "new_hire",
        },
        {
          label: formatter(
            "riskFilter.filterLabel.people.item.subItem.veteran",
            "veteran",
          ),
          value: "veteran",
        },
      ],
    },
    {
      type: FILTER_TYPE.SLIDER,
      title: formatter(
        `riskFilter.filterLabel.people.item.${FILTER_KEY.TENURE_DURATION}`,
        FILTER_KEY.TENURE_DURATION,
      ),
      key: FILTER_KEY.TENURE_DURATION,
      range: [0, 1000],
      stepIncrement: "1",
      infoMessage: formatter(
        "riskFilter.filterLabel.people.infoMessage",
        "infoMessage",
      ),
    },
    ...(fields.departments?.length
      ? [
          {
            type: FILTER_TYPE.MULTI_SELECT,
            title: formatter(
              `riskFilter.filterLabel.people.item.${FILTER_KEY.DEPARTMENT}`,
              FILTER_KEY.DEPARTMENT,
            ),
            key: FILTER_KEY.DEPARTMENT,
            items: fields.departments.map((name) => ({
              label: name,
              value: name,
            })),
            ...(fields.departments.length > 4 && {
              showMore: formatter(
                "riskFilter.filterLabel.people.showMoreDepartments",
                "showMoreDepartments",
              ),
              showLess: formatter(
                "riskFilter.filterLabel.people.showLessDepartments",
                "showLessDepartments",
              ),
            }),
          },
        ]
      : []),
    ...(fields.locations?.length
      ? [
          {
            type: FILTER_TYPE.MULTI_SELECT,
            title: formatter(
              `riskFilter.filterLabel.people.item.${FILTER_KEY.LOCATION}`,
              FILTER_KEY.LOCATION,
            ),
            key: FILTER_KEY.LOCATION,
            items: fields.locations.map((name) => ({
              label: name,
              value: name,
            })),
            ...(fields.locations &&
              fields.locations.length > 4 && {
                showMore: formatter(
                  "riskFilter.filterLabel.people.showMoreLocation",
                  "showMoreLocation",
                ),
                showLess: formatter(
                  "riskFilter.filterLabel.people.showLessLocation",
                  "showLessLocation",
                ),
              }),
          },
        ]
      : []),
    {
      type: FILTER_TYPE.MULTI_SELECT,
      showAllFilter: true,
      title: formatter(
        "riskFilter.filterLabel.people.item.attack_factor",
        "attack_factor",
      ),
      key: "attack_factor",
      items: factorValues?.map((decision) => ({
        label: formatter(
          `riskFilter.filterLabel.people.item.subItem.${decision.value}`,
          decision.value,
        ),
        value: decision.value,
      })),
    },
    {
      type: FILTER_TYPE.MULTI_SELECT,
      showAllFilter: true,
      title: formatter(
        "riskFilter.filterLabel.people.item.action_factor",
        "action_factor",
      ),
      key: "action_factor",
      items: factorValues?.map((decision) => ({
        label: formatter(
          `riskFilter.filterLabel.people.item.subItem.${decision.value}`,
          decision.value,
        ),
        value: decision.value,
      })),
    },
    ...filteredFields,
  ].filter((field) => {
    return (
      !getGlobalConfig("RAP_FIELD_EXCLUSIONS").includes(field.key) &&
      !getGlobalConfig("RAP_FILTER_EXCLUSIONS").includes(field.key)
    )
  })
}

function isCategoryPresent(behaviorTypes, category) {
  return behaviorTypes.some(({ actionCategory }) => actionCategory === category)
}

export const getDropdownOptions = (
  dropdownParams,
  availableHRMetadataKeys,
  formatter,
) => {
  const list = [
    ...(dropdownParams.riskType === "individual"
      ? individualsFilters(dropdownParams, availableHRMetadataKeys, formatter)
      : []),
    ...(dropdownParams.behaviorTypes
      ? dropdownParams.behaviorTypes.flatMap((key) => [
          {
            type: FILTER_TYPE.SLIDER,
            key,
            title: formatter(`riskFilter.filterLabel.score.item.${key}`, key),
            category: "score",
          },
          ...(isCategoryPresent(dropdownParams.enabledBehaviorTypes, key)
            ? [
                {
                  type: FILTER_TYPE.MULTI_SELECT,
                  // This is the filter key, used to send the params to the
                  // back-end. It must be "behavior_type" for all behavior type
                  // filters, so shouldn't be used as a React key for list
                  // rendering. Use the title instead.
                  key: FILTER_KEY.BEHAVIOR_TYPE,
                  title: formatter(
                    `riskFilter.filterLabel.behaviorType.item.${key}`,
                    key,
                  ),
                  showAllFilter: true,
                  isCollapsible: true,
                  initialCollapseState: true,
                  category: "behavior_type",
                  infoMessage: formatter(
                    "riskFilter.infoMessage",
                    "Behavior type search is limited to events from the past year",
                  ),
                  items: dropdownParams.enabledBehaviorTypes
                    .filter(({ actionCategory }) => actionCategory === key)
                    .map(({ behaviorType, behaviorTypeShortName }) => ({
                      label: formatter(
                        `riskFilter.filterLabel.behaviorType.item.subItem.${behaviorTypeShortName}`,
                        behaviorTypeShortName,
                      ),
                      value: behaviorType,
                    })),
                },
              ]
            : []),
        ])
      : []),
  ]

  return list
}
