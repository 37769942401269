import styled from "styled-components"
import { SpacingY } from "@src/components/SpacingY"

export const ScoreTooltipWrapper = styled(SpacingY).attrs({ size: "md" })`
  padding: 10px;
`

export const ScoreNumber = styled.strong`
  font-weight: 800;
  font-size: 32px;
  color: #34383c;
`

export const ScoreLabel = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: #34383c;
  text-transform: capitalize;
`

export const ScoreMonth = styled.span`
  font-weight: 400;
  font-size: 13px;
  color: #34383c;
`

export const ScoreTrend = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: ${({ color }) => color};
  svg {
    margin-right: 5px;
  }
`

export const Span = styled.span`
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #000000;
  white-space: nowrap;
`

export const PerformanceTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-right: 12px;
  padding-left: 10px;
  width: 100%;
`

export const PerformanceText = styled.div`
  font-style: normal;
  font-size: 13px;
  color: #565d66;
  padding-bottom: 10px;
`

export const BarColorsWrapper = styled.div`
  // No way to hide the circle label without hiding the circle, needs an update
  // in component library
  [class^="BarColorsstyles__ScoreDisplay"] {
    display: none;
  }
`
