import PropTypes from "prop-types"
import React from "react"

import {
  ScoreTooltipWrapper,
  ScoreNumber,
  ScoreLabel,
  ScoreMonth,
  ScoreTrend,
  Span,
  PerformanceTooltipWrapper,
  PerformanceText,
  BarColorsWrapper,
} from "./styles"

// components
import { Icon, BarColors } from "@elevate_security/elevate-component-library"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"
import { dashToTitleCase } from "@src/utils/string"
import { SpacingX } from "@src/components/SpacingX"
import { SpacingY } from "@src/components/SpacingY"
import { WordScoreBadge } from "@src/components/ScoreBadges"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { Trans, useTranslation } from "react-i18next"
import { useFormattedLocaleDate } from "@src/utils/locale/formatLocaleDate"

function treatTrend(trend) {
  if (trend === null) {
    return null
  }

  if (Number.isInteger(trend)) {
    return trend
  }

  return formatUserFacingScore(trend)
}

export default function ScoreTooltip(props) {
  const { t } = useTranslation("dashboard")

  const {
    score,
    trend,
    behaviorName,
    month,
    maxValue,
    performanceFlag,
    performanceType,
    useWordLabel = false,
  } = props

  const treatedTrend = treatTrend(trend)

  const { color, icon, diff } = getIconAndColorByScoreDiff(treatedTrend)
  const barColorValue = maxValue === 100 ? score / 10 : score

  const shortMonthYear = useFormattedLocaleDate(month, { format: "MMM Y" })

  if (performanceFlag) {
    return (
      <PerformanceTooltipWrapper>
        <PerformanceText>
          <Trans
            ns="dashboard"
            i18nKey="performanceTooltip"
            count={Math.floor(score)}
            components={{
              bold: <b />,
            }}
            values={{
              performanceEvent: performanceType,
              monthOfYear: shortMonthYear,
              count: Math.floor(score),
            }}
          />
        </PerformanceText>
      </PerformanceTooltipWrapper>
    )
  }

  return (
    <ScoreTooltipWrapper>
      <SpacingX alignItems="center" justify="space-between" wrap={false}>
        {score === null ? (
          <ScoreNumber>{t("scoreTooltip.notApplicable")}</ScoreNumber>
        ) : useWordLabel ? (
          <WordScoreBadge score={score} />
        ) : (
          <ScoreNumber>{formatUserFacingScore(score)}</ScoreNumber>
        )}
        <SpacingY gap="1px">
          <ScoreLabel>{dashToTitleCase(behaviorName)}</ScoreLabel>
          <ScoreMonth>{shortMonthYear}</ScoreMonth>
        </SpacingY>
        <SpacingY gap="1px" alignItems="center">
          <ScoreTrend color={color}>
            {!diff ? (
              t("scoreTooltip.notApplicable")
            ) : (
              <>
                <Icon name={icon} fill={color} />
                {diff}
              </>
            )}
          </ScoreTrend>
          <Span>{t("scoreTooltip.scoreTrend")}</Span>
        </SpacingY>
      </SpacingX>
      <BarColorsWrapper>
        <BarColors score={barColorValue} size="small" />
      </BarColorsWrapper>
    </ScoreTooltipWrapper>
  )
}

ScoreTooltip.defaultProps = {
  score: null,
  trend: null,
  behaviorName: "N/A",
  month: "N/A",
  maxValue: 100,
}
ScoreTooltip.propTypes = {
  score: PropTypes.number,
  trend: PropTypes.number,
  month: PropTypes.string,
  behaviorName: PropTypes.string,
  maxValue: PropTypes.number,
}
