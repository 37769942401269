import * as d3 from "d3"
import { formatDate } from "@src/utils/dates"
import * as moment from "moment"

function parseXAxis({ date, quarter }, typeGraph) {
  if (typeGraph === "monthly") return date
  return quarter
}

const graphLineColor = (data) => {
  const monthDiff = data[0] - data[data.length - 1]
  if (monthDiff === 0) {
    return "#1A518E"
  } else if (monthDiff > 0) {
    return "#178574"
  } else {
    return "#D54242"
  }
}

export function createSparkLineChartBar({
  element,
  behaviorScores,
  campaignScores,
  typeGraph = "monthly",
  hideActionsTaken,
  minMaxValue,
}) {
  const sortData = behaviorScores.trendData
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .map((item) => item.score)
  const graphColor = graphLineColor(sortData)
  const parentWidth = 100
  const parentHeight = 30

  const paddingLeft = 0
  const paddingTop = 0

  const svgWidth = parentWidth - paddingLeft
  const svgHeight = parentHeight - paddingTop

  // create container
  const svgContainer = d3
    .select(element)
    .attr("width", "100%")
    .attr("height", parentHeight)

  // create the graph area
  const graphArea = svgContainer
    .append("g")
    .attr("width", "100%")
    .attr("height", "100%")

  const { min, max } = minMaxValue

  const yScale = d3
    .scaleLinear()
    .domain([min, max])
    .rangeRound([svgHeight - paddingTop, 0])

  // create xAxis
  const dataGraph = behaviorScores
  const firstItemOfObjects = dataGraph[Object.keys(dataGraph)[0]]
  const xScale = d3
    .scaleBand()
    .domain(firstItemOfObjects.map((item) => parseXAxis(item, typeGraph)))
    .rangeRound([0, svgWidth])

  const createLines = (key) => {
    const lineData = dataGraph[key]
    const valueLine = d3
      .line()
      .x((item) => {
        return xScale(parseXAxis(item, typeGraph))
      })
      .y((item) => {
        return yScale(item.score)
      })
      .curve(d3.curveBundle)

    // Add the value line paths.
    const line = graphArea.append("path")
    line
      .data([lineData])
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", graphColor)
      .attr("stroke-width", "2")
      .attr("d", valueLine)
  }

  if (!campaignScores || !campaignScores.length || hideActionsTaken) {
    // create lines and dots
    Object.keys(dataGraph).forEach(createLines)
  } else {
    // CONFIG THE BARS
    const campaignScoresData = campaignScores.map((item, index) => ({
      ...item,
      date: formatDate({
        date: item.end_date || item.start_date,
        format: "MMM",
      }),
      quarter: moment(item.end_date || item.start_date, "YYYY-MM-DD").format(
        "[Q]Q Y",
      ),
      score: 100,
      index,
    }))

    const bucketCount = {}
    const barXPosition = {}

    const extraSpace = svgWidth * (typeGraph === "monthly" ? 0.06 : 0.16)
    const xOffset = paddingLeft + extraSpace * 0.5 // padding + extra space on left

    // set bars position
    campaignScoresData.forEach(({ index, date, quarter }) => {
      const bucketKey = typeGraph === "monthly" ? date : quarter
      bucketCount[bucketKey] = bucketCount[bucketKey]
        ? bucketCount[bucketKey] + 1
        : 1

      barXPosition[index] =
        //initial x-offset
        xOffset + bucketCount[bucketKey] * 10
    })

    const xScaleBars = xScale.padding(0)
    const barWidth = 34
    graphArea
      .selectAll(".bar")
      .data(campaignScoresData)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("fill", "#384F84")
      .style("opacity", "0.1")
      .attr(
        "transform",
        ({ index }) => `translate(${barXPosition[index]},${paddingTop / 2})`,
      )
      .attr("id", (d) => d.campaign_type + d.id)
      .attr("x", function (d) {
        return xScaleBars(d.date)
      })
      .attr("y", function () {
        return yScale(100)
      })
      .attr("width", barWidth)
      .attr("height", svgHeight - paddingTop)

    // create lines and dots after creating bars so that score circles appear over bars
    Object.keys(dataGraph).forEach(createLines)
  }
}
