import React from "react"

import { ScoreBox, ScoreBoxDescription, Strong } from "./styles"
import { SkeletonLoader } from "@src/utils/skeleton"
import IconMalware from "./icon-malware.svg"
import { useTranslation } from "react-i18next"

export const IdentityExplanation = ({
  successCount,
  failureCount,
  loading,
  attemptText,
}) => {
  const { t } = useTranslation("individualRiskProfile")
  return (
    <ScoreBox>
      {loading ? (
        <SkeletonLoader height={80} />
      ) : (
        <>
          <img src={IconMalware} alt="logo" />
          <ScoreBoxDescription>
            <span>
              {t(
                "attackScoreExplaination.identityExplanationContainer.wasDetectedIn",
              )}
            </span>
            <Strong>{failureCount ?? "-"}</Strong>{" "}
            {t(
              "attackScoreExplaination.identityExplanationContainer.successful",
            )}{" "}
            {attemptText}{" "}
            {t("attackScoreExplaination.identityExplanationContainer.attempt", {
              count: failureCount || 0,
            })}
            <Strong>{successCount ?? "-"}</Strong>{" "}
            {t(
              "attackScoreExplaination.identityExplanationContainer.successful",
            )}{" "}
            {attemptText}{" "}
            {t("attackScoreExplaination.identityExplanationContainer.attempt", {
              count: successCount || 0,
            })}
          </ScoreBoxDescription>
        </>
      )}
    </ScoreBox>
  )
}
