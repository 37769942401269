import React from "react"
import { humanize } from "@src/utils/string"
import { useIndividualProfileState } from "../../../IndividualProfileContextProvider"
import {
  NameEmailContainer,
  FullNameContainer,
  PolicyDetailsContainer,
  PolicyRecord,
  PolicyDetailsWrapper,
  EmailContainer,
  PolicyDetailsLabel,
} from "@src/scenes/IndividualProfilePage/ProfileInfo/components/IndividualHumanResourceInformation/style"
import { names } from "./utils"
import { Link } from "react-router-dom"
import { getGlobalConfig } from "@src/globalConfig"
import { useTranslation } from "react-i18next"

function Dot({ hasLabel }) {
  return (
    <span
      style={{
        position: "relative",
        top: "4px",
        lineHeight: "24px",
        color: "#000 87%",
        fontWeight: "400",
        fontSize: "24px",
        paddingRight: !hasLabel ? "0px" : "10px",
      }}
    >
      {hasLabel && "•"}
    </span>
  )
}

const IndividualInformation = ({ label, value, children, label2 }) => {
  const groupLabel = names[label]
  if (!value && !children) return null
  return (
    <div style={{ paddingRight: "10px" }}>
      <Dot hasLabel={Boolean(label)} />
      <PolicyDetailsLabel>
        {`${humanize(groupLabel ? groupLabel : label)} `}
      </PolicyDetailsLabel>
      {value && <span>{value}</span>}
      {label2 && (
        <span style={{ lineHeight: "26px", color: "#7B828A" }}>
          {" "}
          {humanize(label2)}
        </span>
      )}
      {children}
    </div>
  )
}

export default function IndividualHumanResourceInformation() {
  const { individualData } = useIndividualProfileState()
  const fullName = `${individualData?.first_name || ""} ${individualData?.last_name || ""}`
  const { t } = useTranslation("individualRiskProfile")

  if (!individualData) return null

  return (
    <PolicyDetailsWrapper>
      <PolicyRecord>
        <NameEmailContainer>
          <FullNameContainer>
            {individualData?.first_name ? fullName : null}:{" "}
            {t("profileTitleText")}
          </FullNameContainer>
        </NameEmailContainer>
        <PolicyDetailsContainer>
          {individualData?.email && (
            <>
              <PolicyDetailsLabel>
                {t("profileEmailTextLabel")}
              </PolicyDetailsLabel>
              <EmailContainer href={`mailto:${individualData.email}`}>
                {individualData.email}
              </EmailContainer>
            </>
          )}
          {getGlobalConfig("ENABLE_ADDITIONAL_HUMAN_RESOURCE_FIELDS") && (
            <>
              <IndividualInformation
                label={t("individualInformation.position")}
                value={individualData?.job_title}
              />

              <IndividualInformation
                label={t("individualInformation.department")}
                value={individualData?.department}
              />
              <IndividualInformation
                label="Location:"
                value={individualData?.location}
              />
              {individualData?.manager_name && (
                <IndividualInformation
                  label={t("individualInformation.managedBy")}
                >
                  <Link
                    style={{ color: "#1565c0" }}
                    to={`/engagement/vision2/${individualData?.es_manager_id}/profile`}
                  >
                    <span>{individualData?.manager_name}</span>
                  </Link>
                </IndividualInformation>
              )}
              <IndividualInformation
                label={t("individualInformation.timezone")}
                value={individualData?.timezone}
              />
            </>
          )}
        </PolicyDetailsContainer>
      </PolicyRecord>
    </PolicyDetailsWrapper>
  )
}
