import React from "react"
import moment from "moment-timezone"
import { useTranslation } from "react-i18next"

type Options = { locale?: string }

/*
 * Examples:
 * en-US 12/24/2024 12:23 PM
 * en-GB 24/12/2024 12:23
 * de-DE 24.12.2024 12:23
 * es-ES 24/12/2024 12:25
 * fr-CA 2024-12-24 12:23
 * fr-FR 24/12/2024 12:23
 * it-IT 24/12/2024 12:23
 * nl-NL 24-12-2024 12:23
 * pt-PT 24/12/2024 12:23
 */
export function formatLocaleDatetime(
  date?: number | string | Date,
  { locale = "en-US" }: { locale?: string } = {},
) {
  const localeFormatter = moment(date)
  localeFormatter.locale(locale)
  return `${localeFormatter.format("L")} ${localeFormatter.format("LT")}`
}

export function formatLocaleCurrentDatetime(options?: Options): string {
  return formatLocaleDatetime(undefined, options)
}

export function useFormattedLocaleDatetime(date?: number | string | Date) {
  const { i18n } = useTranslation()
  return formatLocaleDatetime(date, { locale: i18n.language })
}

export function FormattedLocaleDatetime({
  date,
}: {
  date?: number | string | Date
}) {
  return <span>{useFormattedLocaleDatetime(date)}</span>
}
