import { LATEST_EVENTS_MODAL_Z_INDEX } from "@src/zIndex"
import styled from "styled-components"

export const Wrapper = styled.div`
  width: ${(props) => props.widthInPercentage};
`

export const LatestEventHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
`

export const TableWrapper = styled.div`
  border-bottom: 1px solid #dde1e7;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0 3px 0;
`

export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dde1e7;
  padding: 7px 0 7px 0;
`

export const TableHeaderWrapper = styled.div`
  color: #7b828a;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  width: ${(props) => props.width};
`

export const TableBodyWrapper = styled.div`
  width: 100%;
`

export const TableColWrapper = styled.div`
  padding: 0 5px 0 0;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  width: ${(props) => props.width};
`

export const LatestEventsLink = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #7b828a;
  margin: 16px 0;
  cursor: pointer;
`

export const TableLinkColWrapper = styled.div`
  padding: 0 5px 0 0;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  width: 25%;
  cursor: pointer;
`

export const ModalWrapper = styled.div`
  z-index: ${LATEST_EVENTS_MODAL_Z_INDEX};
  position: relative;

  [class^="Modalstyles__ModalFooter"] {
    display: none;
  }
  [class^="Modalstyles__ModalContent"] {
    padding: 0px;
  }
  [class^="Modalstyles__ModalContainer"] {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
  }
  [class^="EventsLogstyles__EventLogsWrapper"] {
    margin: 0px;
  }
`

export const ModalFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  [class^="rbt-input-main"] {
    min-width: 320px;
  }
  [class^="inputstyles__InputContainer"] {
    .icons-left {
      left: 16px;
      svg {
        stroke: #7e8691;
      }
    }
  }

  [class^="rbt"][tabindex="-1"] {
    width: 125%;
  }
`

export const LatestEventsTableSkeletonWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
