import React from "react"
import { CustomSelect } from "@src/components/MimecastReskin"
import {
  TIME_PERIOD_LIST,
  PAST_THREE_MONTHS,
  PAST_SIX_MONTHS,
  PAST_TWELVE_MONTHS,
} from "@src/scenes/HumanRiskDashboard/constants"
import { Label, TimePeriodSelectWrapper } from "./styles"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

function TimePeriod({ initialValue, timePeriodHandler }) {
  const { t } = useTranslation("dashboard")
  return (
    <TimePeriodSelectWrapper>
      <Label>{t("humanRiskDashboardTimeframeLabel")}</Label>
      <CustomSelect
        options={TIME_PERIOD_LIST.map((period) => {
          return {
            value: period,
            label: {
              [PAST_THREE_MONTHS]: t("timePeriod.past3Months"),
              [PAST_SIX_MONTHS]: t("timePeriod.past6Months"),
              [PAST_TWELVE_MONTHS]: t("timePeriod.past12Months"),
            }[period],
          }
        })}
        defaultValue={initialValue}
        onChange={timePeriodHandler}
      />
    </TimePeriodSelectWrapper>
  )
}

export default TimePeriod

TimePeriod.propTypes = {
  initialValue: PropTypes.string,
  padding: PropTypes.string,
  timePeriodHandler: PropTypes.func,
}
