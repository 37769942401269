const { useCallback } = require("react")
import { useTranslation } from "react-i18next"
import { createChartBar } from "./graph"

export const useDrawChart = (
  chartRef,
  printChartRef,
  disabledLines,
  typeGraph,
  behaviorScores,
  barData,
  hideActionsTaken,
  onMouseEnter,
  setIsReady,
  showExport,
  keyToBeDashed,
  minMaxValue,
  sortType = "",
  isInsight,
  yAxisDecimal = false,
  changeLineFocus,
  lineFOcus,
  performanceFlag = false,
  showGradeLabel = false,
) => {
  const { i18n } = useTranslation()

  const drawChart = useCallback(
    (chartReady) => {
      if (!chartRef || !chartRef.current) return
      chartRef.current.innerHTML = ""

      if (showExport) {
        printChartRef.current.innerHTML = ""
        createChartBar({
          element: printChartRef.current,
          behaviorScores,
          barData,
          chartReady,
          disabledLines,
          typeGraph,
          onMouseEnter,
          keyToBeDashed,
          minMaxValue,
          hideActionsTaken: true,
          printing: true,
          sortType,
          isInsight,
          yAxisDecimal,
          changeLineFocus,
          lineFOcus,
          performanceFlag,
          showGradeLabel,
          locale: i18n.language,
        })
      }

      createChartBar({
        element: chartRef.current,
        behaviorScores,
        barData,
        chartReady,
        disabledLines,
        typeGraph,
        onMouseEnter,
        hideActionsTaken,
        keyToBeDashed,
        minMaxValue,
        sortType,
        isInsight,
        yAxisDecimal,
        changeLineFocus,
        lineFOcus,
        performanceFlag,
        showGradeLabel,
        locale: i18n.language,
      })

      setIsReady(true)
    },
    [
      printChartRef,
      chartRef,
      disabledLines,
      typeGraph,
      behaviorScores,
      barData,
      hideActionsTaken,
      i18n,
    ],
  )

  return drawChart
}
