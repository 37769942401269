import React, { useState, useEffect } from "react"
import {
  ActionInsightsWrapper,
  StyledStats,
  PercentageGraphContainer,
  LegendContainer,
  SolidCircle,
  LegendSolidCircleTitle,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/style"
import { IconAction } from "@elevate_security/elevate-component-library"
import HistoricalTrend from "@src/components/HistoricalTrend"
import ReactDOM from "react-dom"
import tippy, { followCursor } from "tippy.js"
import moment from "moment"
import {
  getSpecificActionAbleInsightHistoricalData,
  getAdditionalStats,
  getSpecificActionAbleInsightPlaybooks,
} from "@src/services/apis/reputation"
import {
  insightsDescription,
  mocked_interventions,
  mocked_stats_blocks,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/utils"
import HistoricalTrendGraphSkeleton from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/skeleton/HistoricalTrendGraphSkeleton"
import HumanRisksInsights from "../HumanRisksInsights/HumanRisksInsights"
import {
  VcDashBorder,
  VcStatBox,
  VcStatsContainer,
} from "@src/scenes/Dashboard/components/FirstQuestion/components/ActionableInsights/VcDemo/style"
import { darklyGetFlag } from "@src/utils/darkly"
import { humanize } from "@src/utils/string"
import { limitNumber } from "@src/utils/numbers/utils"
import { SkeletonLoader } from "@src/utils/skeleton"
import FlagRibbon from "@src/components/FlagRibbon"
import { getOfferingPlan } from "@src/services/redux/company/selectors"

function IndividualHistoricalTrend({
  loading,
  dataToRender,
  handleOnMouseEnter,
  maxIndividualValue,
}) {
  if (loading) {
    return (
      <PercentageGraphContainer>
        <HistoricalTrendGraphSkeleton />
      </PercentageGraphContainer>
    )
  }

  return (
    <PercentageGraphContainer>
      <LegendContainer>
        <FlagRibbon flagName="show-vc-demo-features" alignItems="center">
          <h5>
            <b># of Individuals Captured by This Insight</b>
          </h5>
        </FlagRibbon>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          {dataToRender?.intervention_data ? (
            <LegendSolidCircleTitle style={{ alignItems: "top" }}>
              <VcDashBorder />
              <p>Intervention Taken</p>
            </LegendSolidCircleTitle>
          ) : (
            <div />
          )}
          <LegendSolidCircleTitle>
            <SolidCircle />
            <p>Goal Range</p>
          </LegendSolidCircleTitle>
        </div>
      </LegendContainer>
      <HistoricalTrend
        behaviorScores={dataToRender}
        onMouseEnter={handleOnMouseEnter}
        yAxisLabel="# of Individuals"
        keyToBeDashed="historical_data"
        showHeading={false}
        showTabs={false}
        isInsight={true}
        changeGraphTabsPosition={true}
        minMaxValue={{
          min: 0.0,
          max: maxIndividualValue > 250.0 ? maxIndividualValue : 250.0,
        }}
      />
    </PercentageGraphContainer>
  )
}

function IndividualInsightPorcentage({ item, setIsVisible }) {
  const vcDemoFlag = darklyGetFlag("show-vc-demo-features")
  const [playBooks, setPlayBooks] = useState([])
  const [additionalStats, setAdditionalStats] = useState([])
  const [dataToRender, setSpecificData] = useState({
    historical_data: [{ date: "2021-01-01", individuals: null, events: null }],
  })
  const [loading, setLoading] = useState(true)

  const handleActionableInsightsPlaybooks = (data) => {
    const playBooksData = data?.map((playbook) => ({
      id: playbook.id,
      recommendations: playbook.recommendations,
    }))

    setPlayBooks(playBooksData || [])
  }

  const handleHistoricalDatAndAdditionalStatsResponse = (
    historicalData,
    additionalStatsData,
  ) => {
    const dataForGraph = {}
    if (historicalData?.length) {
      dataForGraph.historical_data = historicalData
    }

    let additional_stats_data = additionalStatsData || []
    if (vcDemoFlag) {
      additional_stats_data =
        additional_stats_data.length > 0
          ? additional_stats_data
          : mocked_stats_blocks[item.insight] || mocked_stats_blocks.default

      if (
        Object.prototype.hasOwnProperty.call(mocked_interventions, item.insight)
      ) {
        dataForGraph.intervention_data = mocked_interventions[item.insight]
      }
    }

    setSpecificData(dataForGraph)
    setAdditionalStats(
      additional_stats_data.sort((a, b) => (a.position > b.position ? 1 : -1)),
    )
  }

  useEffect(() => {
    const dataFetching = async () => {
      const fetchData = () =>
        Promise.all([
          getSpecificActionAbleInsightHistoricalData(item.insight),
          getAdditionalStats(item.insight),
          getSpecificActionAbleInsightPlaybooks(item.insight),
        ])

      try {
        const [
          actionableInsightsHistoricalDataResponse,
          actionableInsightsAdditionalStats,
          actionableInsightsPlaybooks,
        ] = await fetchData()

        handleHistoricalDatAndAdditionalStatsResponse(
          actionableInsightsHistoricalDataResponse?.data,
          actionableInsightsAdditionalStats?.data,
        )

        handleActionableInsightsPlaybooks(actionableInsightsPlaybooks?.data)
        setLoading(false)
        setIsVisible(true)
      } catch {
        setIsVisible(true)
        setLoading(false)
      }
    }

    dataFetching()
  }, [])

  const handleOnMouseEnter = (info) => {
    const { elementId, date, individuals, intervention, rdr_name } = info
    const lastDate = intervention
      ? moment.utc(date, "YYYY-MM-DD").format("LL")
      : moment.utc(date, "YYYY-MM-DD").format("MMM YYYY")
    const snatizedId = elementId.replace(/[.]/g, "-")
    const tooltipId = `${snatizedId}-tooltip-content`
    const tooltipDiv = document.createElement("div")

    tooltipDiv.setAttribute("id", tooltipId)
    tooltipDiv.style.display = "block"
    tooltipDiv.style.minWidth = "900px"

    const toolTipData = () => {
      if (intervention) {
        return (
          <div>
            <b>{rdr_name}</b> activated on {lastDate}
          </div>
        )
      } else {
        return individuals > 1 ? (
          <div>
            <b>{individuals}</b> individuals in {lastDate}
          </div>
        ) : (
          <div>
            <b>{individuals || 0}</b> individual in {lastDate}
          </div>
        )
      }
    }

    ReactDOM.render(toolTipData(), tooltipDiv)

    const tooltipProps = {
      plugins: [followCursor],
      followCursor: intervention ? "vertical" : "horizontal",
      theme: "light-border",
      allowHTML: true,
      content: tooltipDiv.innerHTML,
      onHide() {
        tooltipDiv.remove()
      },
    }

    const element = document.querySelector(`#${snatizedId}`)
    element.setAttribute("fill", "#039BA1")
    tippy(element, tooltipProps)
    element._tippy.show()
  }

  const maxIndividualValue =
    Math.max(
      ...dataToRender?.historical_data?.map((d) => d?.individuals || 0),
    ) + 100

  const showStatsBlocks = () => {
    if (loading) {
      const loadingTile = [1, 2, 3]
      return (
        <VcStatsContainer>
          {loadingTile.map((item) => {
            return (
              <VcStatBox key={item}>
                <SkeletonLoader height={100} />
              </VcStatBox>
            )
          })}
        </VcStatsContainer>
      )
    }

    const composeStatsValue = (stats_block) => {
      if (stats_block.type !== "percentage") return stats_block.value

      if (stats_block.total_individuals == 0) {
        return stats_block.total_individuals
      }

      const value = stats_block.total_individuals

      if (parseInt(stats_block.value) > 0) {
        return `${value} (${limitNumber(stats_block.value, 0, 100)}%)`
      }

      if (parseInt(stats_block.value) === 0) return `${value} (<1%)`

      return stats_block.value
    }
    return (
      <VcStatsContainer>
        {additionalStats.map((stats_block, index) => {
          return (
            <VcStatBox key={`${index}-${stats_block.value}`}>
              <h3>{composeStatsValue(stats_block)}</h3>
              <p>{humanize(stats_block.title).toUpperCase()}</p>
            </VcStatBox>
          )
        })}
      </VcStatsContainer>
    )
  }

  const hidePlaybooks = getOfferingPlan() === "assess"

  return (
    <ActionInsightsWrapper>
      <h5>
        <p>
          <div>
            {insightsDescription(item?.description)}
            <span style={{ marginLeft: "5px" }}>
              <IconAction
                name="InfoCheckOutline"
                tooltipText={
                  <div>
                    <b>Why this matters:</b> {item?.reason}
                  </div>
                }
                renderTooltipOnHover
                size="sm"
              />
            </span>
          </div>
        </p>
      </h5>
      <p>
        {" "}
        This insight is driven by{" "}
        <StyledStats>
          {item.total_events.toLocaleString()} events
        </StyledStats>{" "}
        and applies to{" "}
        <StyledStats>
          {item.total_individuals.toLocaleString()} individuals
        </StyledStats>
        . Here are some additional stats:
      </p>
      {showStatsBlocks()}
      {IndividualHistoricalTrend({
        loading,
        dataToRender,
        handleOnMouseEnter,
        maxIndividualValue,
      })}
      {!!playBooks.length && !hidePlaybooks && (
        <div style={{ marginBottom: "40px" }}>
          <hr />
          <HumanRisksInsights
            actionableDetailsFlag={true}
            recommendations={playBooks}
            loading={loading}
          />
        </div>
      )}
    </ActionInsightsWrapper>
  )
}

export default IndividualInsightPorcentage
