import React, { useState } from "react"
import { useSubscribeToLaunchDarklyFlag as _useSubscribeToLaunchDarklyFlag } from "@src/utils/darkly/useSubscribeToLaunchDarklyFlag"

import { humanize } from "@src/utils/string"
import { useApplications as _useApplications } from "./useApplications"
import { Cell } from "../TableWithFiltersScreen/styles"
import { ApplicationsFilters } from "./ApplicationsFilters"
import { ManageCriticality } from "./ManageCriticality"
import { useApplicationsFilters as _useApplicationsFilters } from "./useApplicationsFilters"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { useTranslation } from "react-i18next"

export const ApplicationsScreen = ({
  useSubscribeToLaunchDarklyFlag = _useSubscribeToLaunchDarklyFlag,
  useApplications = _useApplications,
  useApplicationsFilters = _useApplicationsFilters,
  hidePageTitle = false,
  es_person_id = undefined,
}) => {
  const { t } = useTranslation("individualRiskProfile")
  // Using ITDR flag for now since it's closely related to applications work
  const isApplicationsScreenEnabled =
    useSubscribeToLaunchDarklyFlag("itdr-behaviors")

  const [pagination, setPagination] = useState({ limit: 25, offset: 0 })

  const { filters, updateFilter, updateFilterDebounced } =
    useApplicationsFilters()

  const [searchText, setSearchText] = useState(filters?.name || "")

  const { applications, loading, error, updateCriticality } = useApplications({
    ready: isApplicationsScreenEnabled,
    pagination,
    filters: {
      ...filters,
      es_person_id,
    },
  })

  if (!isApplicationsScreenEnabled) {
    return <div>{t("applicationScreen.notFound")}</div>
  }

  if (error) {
    return <div>{t("applicationScreen.loadError")}</div>
  }

  const columns = [
    {
      key: "display_name",
      header: t("applicationScreen.columns.appName"),
    },
    {
      key: "source",
      header: t("applicationScreen.columns.source"),
      render: () => {
        return <Cell>{t("applicationScreen.source.azureAd")}</Cell>
      },
    },
    {
      key: "application_type",
      header: t("applicationScreen.columns.type"),
      render: (val) => {
        return <Cell>{humanize(val)}</Cell>
      },
    },
    {
      key: "criticality",
      header: t("applicationScreen.columns.appRanking"),
      render: (_, row) => {
        if (es_person_id) {
          return <Cell>{humanize(row.criticality)}</Cell>
        }
        return (
          <ManageCriticality
            criticality={row.criticality}
            applicationId={row.app_id}
            onUpdate={updateCriticality}
          />
        )
      },
    },
  ]

  const appliedFilters = Object.entries(filters)
    // Name is filtered using the search bar, so exclude it from applied simple filters
    .filter(([key, list]) => key !== "name" && Boolean(list))
    .flatMap(([key, list]) =>
      (list || "").split(",").map((value) => ({ key, value })),
    )

  const onSearch = (value) => {
    setSearchText(value)
    updateFilterDebounced("name", value)
  }

  const clearSearch = () => {
    setSearchText("")
    updateFilter("name", "")
  }

  const onChange = ({ key, value, checked }) => {
    const filterList = (filters[key] || "")
      .split(",")
      .filter(Boolean)
      .filter((item) => item != value)
    if (checked) {
      filterList.push(value)
    }
    updateFilter(key, filterList.join(","))
  }

  return (
    <TableWithFiltersScreen
      title={hidePageTitle ? "" : t("applicationScreen.title")}
      appliedFiltersCount={appliedFilters.length}
      entries={applications?.results}
      totalCount={applications?.count}
      columns={columns}
      searchValue={searchText}
      pagination={pagination}
      loading={loading}
      onSearch={onSearch}
      clearSearch={clearSearch}
      onChangeTable={({ pagination }) => {
        setPagination(pagination)
      }}
      excludeFromClickRow={["criticality"]}
      excludeSortColumn={columns.map((c) => c.key)}
    >
      <ApplicationsFilters
        appliedFilters={appliedFilters}
        onChange={onChange}
      />
    </TableWithFiltersScreen>
  )
}
