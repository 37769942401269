import React from "react"
import { Icon } from "@elevate_security/elevate-component-library"
import RecommendationItems from "../../../RecommendationItems"
import {
  LinkWrapper,
  SubSectionWrapper,
  SubSectionHeader,
  SectionHeading,
  SubSectionCard,
  SubSectionCardSkeleton,
  NoRecommendationItemsWrapper,
  NoRecommendationItemsMessageWrapper,
  RecommendationsBlurb,
} from "./styles"
import pluralize from "pluralize"
import {
  geNewtRiskAnalysisURL,
  getRiskDetectionRuleUrl,
} from "@src/scenes/IndividualRiskAnalysis/utils"
import { SkeletonLoader } from "@src/utils/skeleton"
import { darklyGetFlag } from "@src/utils/darkly"
import FlagRibbon from "@src/components/FlagRibbon"

pluralize.addPluralRule("to be", "are")
pluralize.addSingularRule("to be", "is")

const DEFAULT_RECOMMENDATION_LINK_TEXT = "Create"
const DEFAULT_RECOMMENDATION_PERFORMED_TEXT = "Activated"
const DEFAULT_RECOMMENDATION_RUL = getRiskDetectionRuleUrl

const DEFAULT_RECOMMENDATION_ACTION = {
  linkText: DEFAULT_RECOMMENDATION_LINK_TEXT,
  actionPerformedText: DEFAULT_RECOMMENDATION_PERFORMED_TEXT,
  url: DEFAULT_RECOMMENDATION_RUL,
}

const RecommendationAction = {
  listplus: DEFAULT_RECOMMENDATION_ACTION,
  "user-shield": DEFAULT_RECOMMENDATION_ACTION,
  "arrows-down-to-people": DEFAULT_RECOMMENDATION_ACTION,
  medal: DEFAULT_RECOMMENDATION_ACTION,
  "users-viewfinder": DEFAULT_RECOMMENDATION_ACTION,
  eyeglass: {
    // Review continues as Review as you can always review it, there's no reason to say reviewed as it can be multiple persons looking at it
    linkText: "Review",
    actionPerformedText: "Review",
    url: geNewtRiskAnalysisURL,
  },
  pulse: {
    linkText: "Enroll",
    actionPerformedText: "Enrolled",
    url: getRiskDetectionRuleUrl,
  },
}

const insightRow = (insight) => {
  const actionConfig =
    RecommendationAction[insight.icon_type] || DEFAULT_RECOMMENDATION_ACTION
  return {
    id: insight?.id,
    description: insight?.recommendation_blurb,
    security_fmw: insight?.security_fmw,
    security_fmw_tooltip: insight?.security_fmw_tooltip,
    security_fmw_text: insight?.security_fmw_text,
    impact: insight?.impact,
    iconType: insight?.icon_type,
    actionUrl: actionConfig.url(),
    action: (
      <LinkWrapper>
        {insight?.is_active ? (
          <a>{actionConfig.actionPerformedText}</a>
        ) : (
          <a>
            {actionConfig.linkText}
            <Icon name="CaretRight" size="xxsm" />
          </a>
        )}
      </LinkWrapper>
    ),
    policy: insight?.policy,
    rules: insight?.rules,
    recommendation_blurb: insight?.recommendation_blurb,
    recommendation_category: insight?.recommendation_category,
    type: insight?.type,
    policy_id: insight?.policy_id,
    is_active: insight?.is_active,
    is_disabled: !insight?.is_active,
  }
}

const ShowRecommendations = ({ actionsList, actionableDetailsFlag }) => {
  return (
    <SubSectionWrapper
      actionableDetailsFlag={actionableDetailsFlag}
      color={actionsList?.hex_color}
    >
      <SubSectionHeader actionableDetailsFlag={actionableDetailsFlag}>
        {actionsList?.playbook}
      </SubSectionHeader>
      <RecommendationItems
        color={actionsList?.hex_color}
        icon={false}
        actionsList={actionsList.data}
        recommendationsList={actionsList.recommendations}
        isRiskInsights={true}
        subSectionColor={actionsList?.hex_color}
      />
    </SubSectionWrapper>
  )
}
function renderNoRecommendationItemsMessage() {
  return (
    <NoRecommendationItemsWrapper>
      <img
        src="https://hm-assets.elevatesecurity.com/master/pulse/Fortified.png"
        alt=""
        width="30%"
      />
      <NoRecommendationItemsMessageWrapper>
        <p>All insights reviewed!</p>
        <p>We&#8217;ll have a fresh batch for you soon. Stay tuned!</p>
      </NoRecommendationItemsMessageWrapper>
    </NoRecommendationItemsWrapper>
  )
}

export default function HumanRisksInsights({
  actionableDetailsFlag,
  recommendations,
  loading,
}) {
  const vcDemoFlag = darklyGetFlag("show-vc-demo-features")
  const recommendationsToRender = recommendations
  recommendationsToRender.forEach(
    (recommendation) =>
      (recommendation.data = recommendation.recommendations.map(insightRow)),
  )

  if (loading) {
    const dummyList = [1, 2, 3]
    return (
      <SubSectionCard>
        <SectionHeading>Recommendations to Reduce Risk</SectionHeading>
        {dummyList.map((index) => (
          <SubSectionCardSkeleton key={index}>
            <SkeletonLoader height={25} width={28} circle={true} />
            <SkeletonLoader marginLeft={15} marginTop={6} />
            <SkeletonLoader
              marginLeft={10}
              width={100}
              height={20}
              marginTop={3}
            />
            <SkeletonLoader
              marginLeft={10}
              width={80}
              height={20}
              marginTop={3}
            />
          </SubSectionCardSkeleton>
        ))}
      </SubSectionCard>
    )
  }
  return (
    <SubSectionCard>
      <FlagRibbon flagName="show-vc-demo-features">
        <SectionHeading>Recommendations to Reduce Risk</SectionHeading>
      </FlagRibbon>
      {vcDemoFlag && actionableDetailsFlag && (
        <RecommendationsBlurb>
          Based on this insight, we recommend taking the following interventions
          to reduce your workforce risk:
        </RecommendationsBlurb>
      )}
      {recommendationsToRender.length
        ? recommendationsToRender.map((recommendation) => (
            <ShowRecommendations
              key={recommendation?.playbook}
              actionsList={recommendation}
              actionableDetailsFlag={actionableDetailsFlag}
            />
          ))
        : renderNoRecommendationItemsMessage()}
    </SubSectionCard>
  )
}
