import React from "react"
import { EventLogWrapper } from "./styles"
import { EventLogs } from "../../../components/EventLogs"
import { Typography, theme } from "@elevate_security/elevate-component-library"
import { SpacingY } from "@src/components/SpacingY"
import { useTranslation } from "react-i18next"

const { H2 } = Typography

export default function EventInfo({ eventTypeFilter }) {
  const { t } = useTranslation("individualRiskProfile")
  return (
    <SpacingY>
      <H2
        fontWeight="bold"
        fonSize="md"
        style={{ color: theme.text.color[700], margin: 0 }}
      >
        {t("eventsInfo.eventsHeader")}
      </H2>
      <EventLogWrapper>
        <EventLogs
          type="individualEventsLog"
          showIndividualColumn={false}
          exportConfig={{
            disable: false,
          }}
          enableAdditionalDetails={true}
          defaultFilters={
            eventTypeFilter
              ? [
                  {
                    key: "action",
                    value: eventTypeFilter,
                    original: eventTypeFilter,
                    humanizedName: "Action",
                  },
                ]
              : []
          }
        />
      </EventLogWrapper>
    </SpacingY>
  )
}
