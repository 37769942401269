import { useMemo } from "react"
import { PredefinedWatchlistId } from "../WatchlistsScreen/useWatchlist"
import { useRules } from "./useRules"
import { useTranslation } from "react-i18next"

export function useRuleNames(): {
  ruleIdToRuleName: { [key: string]: string }
  loading: boolean
} {
  const { i18n } = useTranslation("watchlists")

  const watchlistIdToWatchlistName = useMemo(
    () =>
      (
        i18n.getResourceBundle(i18n.language, "watchlists") ??
        // Fail-safe fallback to en-US if for some reason the current language
        // doesn't have translations.  Should never be hit as long as we are
        // specifying supported languages explicitly in i18next config.
        i18n.getResourceBundle("en-US", "watchlists")
      ).predefined as Record<PredefinedWatchlistId, { name: string }>,
    [i18n],
  )

  const { data: watchlistIdToRuleId, loading } = useRules()

  const ruleIdToRuleName = useMemo(
    () =>
      Object.entries(watchlistIdToWatchlistName).reduce(
        (acc, [watchlistId, { name }]) => {
          // Rule name is the same as the corresponding watchlist name
          if (watchlistIdToRuleId[watchlistId as PredefinedWatchlistId]) {
            acc[watchlistIdToRuleId[watchlistId as PredefinedWatchlistId]] =
              name
          }
          return acc
        },
        {} as { [key: string]: string },
      ),
    [watchlistIdToRuleId, watchlistIdToWatchlistName],
  )
  return { ruleIdToRuleName, loading }
}
