import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import pluralize from "pluralize"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Button from "@elevate_security/elevate-component-library/dist/Button"
import {
  GroupsGraph,
  IconAction,
} from "@elevate_security/elevate-component-library/"
import { Tab, Tabs } from "@src/components/MimecastReskin"

import {
  ActionsOverlay,
  Products,
  ProductCard,
  FlexRowSpaceBetween,
  Heading,
  TextSpan,
  FlexRow,
  OrSection,
  Divider,
  IndividualsListContainer,
  IndividualsList,
  ButtonWrapper,
  ExpandLink,
  EnrollContainer,
  SkeletonWrapper,
  SideDrawerStatBox,
  SideDrawerStatBoxContainer,
  IconContainer,
  StatsNote,
  TabsWrapper,
} from "./styles"
import Paginator from "../Paginator"
import ActionIcon from "@src/components/ActionIcon"
import Checkbox from "@src/components/Checkbox"
import {
  getRecommendedIndividualsForHM,
  getRecommendedIndividualsForPulse,
  getRecommendedIndividualsForReflex,
  getTotalIndividualsCount,
} from "@src/services/redux/scores/selectors"
import { PRODUCT_KEY, getProducts, FACTOR_DESCRIPTIONS } from "./utils"
import { SkeletonLoader } from "@src/utils/skeleton"
import { getIconAndColorByScoreDiff } from "@src/utils/colors"
import { humanize, isEmpty } from "@src/utils/string"
import LatestEvents from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/LatestEvents/LatestEvents"
import PerformanceChart from "@src/components/PerformanceChart/PerformanceChart"
import { getActionName } from "@src/components/ActionChart/utils"
import { setSelectedKeyFactor } from "@src/services/redux/IndividualProfile/actions"
import useRequest from "@ahooksjs/use-request"
import {
  getActionPerformance,
  getActionsData,
} from "@src/services/apis/reputation"
import AccessAndAttackFactorGraph from "@src/scenes/HumanRiskDashboard/components/AccessAndAttackFactorGraph/AccessAndAttackFactorGraph"
import { limitNumber } from "@src/utils/numbers/utils"
import FlagRibbon from "@src/components/FlagRibbon"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import { OFFERING_PLANS } from "@src/constants"
import {
  GRAPH_GAP,
  TIME_PERIOD_MONTHS,
} from "@src/scenes/HumanRiskDashboard/constants"
import TimePeriod from "@src/scenes/HumanRiskDashboard/components/TimePeriod/TimePeriod"
import {
  generatePerformanceData,
  performanceDictionaryMockData,
  getPerformanceMonths,
} from "@src/components/PerformanceChart/utils"
import {
  setLatestEvents,
  setScoreBreakdown,
} from "@src/services/redux/reputations/actions"
import { Trans, useTranslation } from "react-i18next"
import { formatUserFacingScore } from "@src/utils/numbers/formatUserFacingScore"
import { getInsightTranslationKeyPrefix } from "@src/scenes/HumanRiskDashboard/components/HumanRiskBehaviors/HumanRiskBehaviorsItemDetailsGridItem"
import { useLocaleDateFormatter } from "@src/utils/locale/formatLocaleDate"

const page_size = 20

const SideDrawer = ({
  showDrawer,
  count,
  onClose,
  onEnroll,
  onRecommendedEnroll,
  onPageChange,
  showHackersmind,
  showPulse,
  showReflex,
  actionFactorScoreFlag,
  keyFactor,
  currentFactorScore,
  sideDrawerTrendValue,
  showInsightConditions,
}) => {
  const { t } = useTranslation("dashboard")
  //Modifing the keyFactor to make attack_malware and attack_rwp to function correctly
  const filteredKeyFactor = keyFactor
    ?.replaceAll("-", "_")
    .replace("attack_", "")
    .replace("rwp", "real_world_phishing")
  const analysisIndividuals = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const [selectedTab, setSelectedTab] = useState(0)
  const reputations = useSelector((state) => state.get("reputationsReducer"))
  const [timePeriod, setTimePeriod] = useState(reputations.time_period_value)
  const [eventOverTimeGraph, setEventOverTimeGraph] = useState({
    loading: true,
    data: [],
  })
  const [recommendView, setRecommedView] = useState(false)
  const { icon, color } = getIconAndColorByScoreDiff(sideDrawerTrendValue)
  const [quantity, setQuantity] = useState()
  const [currentPage, setCurrentPage] = useState({
    [PRODUCT_KEY.PULSE]: 0,
    [PRODUCT_KEY.HM]: 0,
    [PRODUCT_KEY.REFLEX]: 0,
  })
  const [unChecked, setUnChecked] = useState({
    [PRODUCT_KEY.PULSE]: [],
    [PRODUCT_KEY.HM]: [],
    [PRODUCT_KEY.REFLEX]: [],
  })
  const [expanded, setExpanded] = useState({
    [PRODUCT_KEY.PULSE]: false,
    [PRODUCT_KEY.HM]: false,
    [PRODUCT_KEY.REFLEX]: false,
  })
  const [enrollLoading, setEnrollLoading] = useState({
    [PRODUCT_KEY.PULSE]: false,
    [PRODUCT_KEY.HM]: false,
    [PRODUCT_KEY.REFLEX]: false,
  })
  const recommendedList = {
    [PRODUCT_KEY.PULSE]: useSelector(getRecommendedIndividualsForPulse),
    [PRODUCT_KEY.HM]: useSelector(getRecommendedIndividualsForHM),
    [PRODUCT_KEY.REFLEX]: useSelector(getRecommendedIndividualsForReflex),
  }
  const totalIndividualsCount = useSelector(getTotalIndividualsCount) || 0

  const individualText = "individual"
  const isRecommended = !count || recommendView

  const { loading: insightLoader, data: insightConditions } = useRequest(
    () => getActionsData(filteredKeyFactor, TIME_PERIOD_MONTHS[timePeriod]),
    {
      ready: !isEmpty(filteredKeyFactor),
      refreshDeps: [filteredKeyFactor, timePeriod],
      formatResult: (res) => {
        return (
          res?.data?.details?.map((detail) => ({
            ...detail,
            action: res.data.action,
          })) || [
            {
              position: 0,
              title: "No details found",
              type: "percentage",
              value: "0",
              description: "No details found",
              action: filteredKeyFactor,
            },
          ]
        )
      },
    },
  )

  const { loading: chartLoader, data: chartData } = useRequest(
    () =>
      getActionPerformance(
        filteredKeyFactor,
        getPerformanceMonths(TIME_PERIOD_MONTHS[timePeriod]),
      ),
    {
      ready:
        (!isEmpty(filteredKeyFactor) && analysisIndividuals.showSideBar) ||
        false,
      refreshDeps: [filteredKeyFactor, timePeriod],
      formatResult: (res) => {
        const actionPerformanceData =
          res?.data || performanceDictionaryMockData[filteredKeyFactor]
        setEventOverTimeGraph({ loading: false, data: actionPerformanceData })
        return generatePerformanceData(actionPerformanceData)
      },
    },
  )

  useEffect(() => {
    setQuantity(insightConditions?.length || 0)
  }, [])

  useEffect(() => {
    setSelectedTab(0)
    dispatch(setLatestEvents([], true))
    dispatch(setScoreBreakdown([], true))
  }, [filteredKeyFactor])

  const handleCheckChange = (key, id, value) => {
    if (!value) {
      setUnChecked({
        ...unChecked,
        [key]: [...unChecked[key], id],
      })
    } else {
      setUnChecked({
        ...unChecked,
        [key]: unChecked[key].filter((_id) => _id !== id),
      })
    }
  }

  const handlePageChange = (key, page) => {
    setCurrentPage({
      ...currentPage,
      [key]: page,
    })
    const limit = page_size
    const offset = page_size * page
    onPageChange(key, limit, offset)
  }

  const onEnrollLoading = (key, loading) => {
    setEnrollLoading({
      ...enrollLoading,
      [key]: loading,
    })
  }

  const handleEnroll = (product) => {
    onEnrollLoading(product.key, true)
    onEnroll(product, () => onEnrollLoading(product.key, false))
  }

  const handleRecommendedEnroll = (product, unCheckedIds) => {
    onEnrollLoading(product.key, true)
    onRecommendedEnroll(product, unCheckedIds, () => {
      onEnrollLoading(product.key, false)
    })
  }

  const dispatch = useDispatch()
  const hidePerformanceAndGroupGraphs =
    getOfferingPlan() === OFFERING_PLANS.ASSESS

  const showStateBoxes = () => {
    if (!insightLoader) {
      return insightConditions?.map((data, index) => {
        const translationKeyPrefix = getInsightTranslationKeyPrefix(data)
        return (
          <SideDrawerStatBox
            quantity={quantity}
            key={`${index}-${data.value}-${data.position}`}
          >
            <h3>
              {data.type === "percentage"
                ? `${limitNumber(data.value, 0, 100)}%`
                : data.value.toLocaleString()}
            </h3>
            <p>
              {analysisIndividuals.showSideBar &&
                t(`${translationKeyPrefix}.title`, {
                  defaultValue: data.title,
                }).toLocaleUpperCase()}
              <IconContainer>
                <IconAction
                  name="InfoCheckOutline"
                  tooltipText={
                    <div>
                      {t(`${translationKeyPrefix}.description`, {
                        defaultValue: data.description,
                        monthCount: TIME_PERIOD_MONTHS[timePeriod],
                      })}
                    </div>
                  }
                  renderTooltipOnHover
                  size="xsm"
                />
              </IconContainer>
            </p>
          </SideDrawerStatBox>
        )
      })
    }
    return (
      <div style={{ width: "100%" }}>
        <SkeletonLoader height={90} marginTop={10} />
      </div>
    )
  }

  const formatLocaleDate = useLocaleDateFormatter()

  const TabsComponent = useMemo(() => {
    return [
      ...(!hidePerformanceAndGroupGraphs
        ? [
            <GroupsGraph
              loading={chartLoader}
              key={filteredKeyFactor}
              keyFactor={filteredKeyFactor}
              actionName={t(`actionNames.${keyFactor}`, {
                defaultValue: getActionName(keyFactor),
              })}
              legendFormatter={(item) => {
                return t(`performanceEventNames.${item}`, {
                  defaultValue: humanize(item),
                })
              }}
              tooltipFormatter={({ label, value, type }) => {
                return t("eventsOverTimeTooltip", {
                  count: value,
                  formattedCount: value.toLocaleString(),
                  performanceEventName: t(`performanceEventNames.${type}`, {
                    defaultValue: humanize(type),
                  }),
                  monthOfYear: formatLocaleDate(label, { format: "MMM YYYY" }),
                  components: {
                    strong: <strong />,
                  },
                })
              }}
              data={eventOverTimeGraph.data}
              setEventOverTimeGraph={setEventOverTimeGraph}
              graphHeight={300}
              xAxisFormat={(date) =>
                formatLocaleDate(date, { format: "MMM YY" })
              }
              groupGap={GRAPH_GAP[timePeriod] || 75}
              headerText={t(
                "humanRiskBehaviourBreakdown.gridItem.tabs.eventsOverTime",
              )}
              yAxisLabel={t(
                "humanRiskBehaviourBreakdown.gridItem.charts.eventsOverTimeChart.numberOfEventsLabel",
              )}
              xAxisLabel={t(
                "humanRiskBehaviourBreakdown.gridItem.charts.eventsOverTimeChart.timeLabel",
              )}
            />,
            <PerformanceChart
              keyFactor={filteredKeyFactor}
              key={filteredKeyFactor}
              setEventOverTimeGraph={setEventOverTimeGraph}
              data={chartData || {}}
              loading={chartLoader}
            />,
          ]
        : []),
      <AccessAndAttackFactorGraph
        keyFactor={filteredKeyFactor}
        key={filteredKeyFactor}
        graphType="score_breakdown"
      />,
      <LatestEvents
        setData={(data) => dispatch(setLatestEvents(data, false))}
        key={filteredKeyFactor}
        keyFactor={filteredKeyFactor}
        widthInPercentage={100}
        openDetail={() => dispatch(setSelectedKeyFactor(filteredKeyFactor))}
      />,
    ]
  }, [chartLoader])

  const TABS = [
    ...(!hidePerformanceAndGroupGraphs
      ? [
          t("humanRiskBehaviourBreakdown.gridItem.tabs.eventsOverTime"),
          t("humanRiskBehaviourBreakdown.gridItem.tabs.individualPerform"),
        ]
      : []),
    t("humanRiskBehaviourBreakdown.gridItem.tabs.scoreBreakdown"),
    t("humanRiskBehaviourBreakdown.gridItem.tabs.latestEvents"),
  ]

  if (actionFactorScoreFlag && filteredKeyFactor) {
    return (
      <ActionsOverlay
        show={analysisIndividuals.showSideBar}
        data-analytics={`side-drawer-${keyFactor}`}
      >
        <FlexRowSpaceBetween>
          <FlexRow>
            <FlagRibbon flagName="show-vc-demo-features">
              <Heading>
                {t(`actionNames.${filteredKeyFactor}`, {
                  defaultValue: getActionName(filteredKeyFactor),
                })}
                : {formatUserFacingScore(currentFactorScore)}{" "}
                <Icon name={icon} fill={color} />
              </Heading>
            </FlagRibbon>
          </FlexRow>
          <ActionIcon name="Close" onClick={() => onClose()} />
        </FlexRowSpaceBetween>
        <TextSpan>
          {t(`factorDescriptions.${filteredKeyFactor}`, {
            defaultValue: FACTOR_DESCRIPTIONS[humanize(filteredKeyFactor)],
          })}
        </TextSpan>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px",
          }}
        >
          <TimePeriod
            initialValue={timePeriod}
            timePeriodHandler={(selected) => setTimePeriod(selected.value)}
          />
        </div>
        <SideDrawerStatBoxContainer
          quantity={quantity}
          loading={insightLoader ? "flex" : "grid"}
        >
          {showInsightConditions && showStateBoxes()}
        </SideDrawerStatBoxContainer>
        {!insightLoader && (
          <StatsNote>
            {t("individualRiskAnalysisSideDrawer.statsNote", {
              timePeriod: TIME_PERIOD_MONTHS[timePeriod],
            })}
          </StatsNote>
        )}
        {showInsightConditions && (
          <>
            <Tabs
              onChange={(i) => setSelectedTab(i)}
              currentTabIndex={selectedTab}
            >
              {TABS.map((tab, index) => (
                <Tab key={`tab-${index}`} title={tab} />
              ))}
            </Tabs>
            <TabsWrapper>{TabsComponent[selectedTab]}</TabsWrapper>
          </>
        )}
      </ActionsOverlay>
    )
  }

  return (
    <ActionsOverlay show={showDrawer}>
      <FlexRowSpaceBetween>
        {isRecommended ? (
          <FlexRow>
            {recommendView && (
              <ActionIcon
                name="ArrowLeft"
                onClick={() => setRecommedView(false)}
                size="sm"
              />
            )}
            <Heading>
              {t("individualRiskAnalysisSideDrawer.recommendedActions")}
            </Heading>
          </FlexRow>
        ) : (
          <Heading>
            {t("individualRiskAnalysisSideDrawer.enrollInACampaign")}
          </Heading>
        )}
        <ActionIcon name="Close" onClick={() => onClose()} />
      </FlexRowSpaceBetween>
      {isRecommended ? (
        <TextSpan>
          <Trans
            ns="dashboard"
            i18nKey="individualRiskAnalysisSideDrawer.recommendationMessage"
            values={{
              value: pluralize(individualText, totalIndividualsCount, true),
            }}
            components={{ bold: <b /> }}
          />
        </TextSpan>
      ) : count === 0 ? (
        <TextSpan danger={true}>
          {t("individualRiskAnalysisSideDrawer.enrollCampaignError")}
        </TextSpan>
      ) : (
        <TextSpan>
          {"Enroll all "}
          <b>
            {count} {t("individualRiskAnalysisSideDrawer.selectionText")}{" "}
            {pluralize(individualText, count)}
          </b>
          {" campaign:"}
        </TextSpan>
      )}
      <Products isRecommended={isRecommended}>
        {getProducts({ showHackersmind, showPulse, showReflex }).map(
          (product) => {
            const { key, name, icon } = product
            const recommendedCount = recommendedList[key].data
              ? recommendedList[key].data.count
              : 0
            const selectedRecommendedCount =
              recommendedCount - unChecked[key].length
            const isLoaded = recommendedList[key].isLoaded
            const list = recommendedList[key].data
              ? recommendedList[key].data.results
              : []
            const isExpanded = expanded[key]
            return (
              <ProductCard key={key}>
                {isRecommended && (
                  <Icon name={icon} size="lg" className="product-icon" />
                )}
                {isRecommended ? (
                  <div>
                    <TextSpan>
                      {"Enroll "}
                      <b>
                        {pluralize(
                          individualText,
                          selectedRecommendedCount,
                          true,
                        )}
                      </b>
                      {key === PRODUCT_KEY.REFLEX ? " with a " : " with an "}
                      <b>
                        {key === PRODUCT_KEY.REFLEX
                          ? "phishing simulation score < 60"
                          : "overall score < 60"}
                      </b>
                      {" in a "}
                      <b>{name}</b>
                      {" campaign"}
                    </TextSpan>
                    <ButtonWrapper>
                      <Button
                        disabled={
                          !selectedRecommendedCount || enrollLoading[key]
                        }
                        onClick={() =>
                          handleRecommendedEnroll(product, unChecked[key])
                        }
                      >
                        {`Enroll in ${name} Campaign`}
                      </Button>
                    </ButtonWrapper>
                    <IndividualsListContainer>
                      <ExpandLink
                        onClick={() =>
                          setExpanded({
                            ...expanded,
                            [key]: !isExpanded,
                          })
                        }
                      >
                        {isExpanded ? "Hide" : "Show"} {t("individuals")}
                        <Icon
                          name={isExpanded ? "CaretUp" : "CaretDown"}
                          size="xxsm"
                          fill="#0C7D84"
                          className="expand-icon"
                        />
                      </ExpandLink>
                      {isExpanded && (
                        <>
                          {!isLoaded && (
                            <SkeletonWrapper>
                              <SkeletonLoader marginTop={15} />
                              <SkeletonLoader />
                              <SkeletonLoader />
                            </SkeletonWrapper>
                          )}
                          <IndividualsList>
                            {list.map(({ id, first_name, last_name }) => {
                              const full_name = `${first_name} ${last_name}`
                              return (
                                <Checkbox
                                  label={full_name}
                                  id={id}
                                  key={id}
                                  checked={!unChecked[key].includes(id)}
                                  onChange={(checked) =>
                                    handleCheckChange(key, id, checked)
                                  }
                                />
                              )
                            })}
                          </IndividualsList>
                        </>
                      )}
                      {isExpanded && recommendedCount > 0 && (
                        <Paginator
                          currentPage={currentPage[key]}
                          pages={Math.ceil(recommendedCount / page_size)}
                          onNavigate={(page) => handlePageChange(key, page)}
                        />
                      )}
                    </IndividualsListContainer>
                  </div>
                ) : (
                  <EnrollContainer>
                    <Icon name={icon} size="lg" className="enroll-only" />
                    <TextSpan align="center">{name}</TextSpan>
                    <TextSpan align="center">
                      {t("individualRiskAnalysisSideDrawer.campaign")}
                    </TextSpan>
                    <ButtonWrapper>
                      <Button
                        disabled={count === 0 || enrollLoading[key]}
                        onClick={() => handleEnroll(product)}
                      >
                        {t("individualRiskAnalysisSideDrawer.enroll")}
                      </Button>
                    </ButtonWrapper>
                  </EnrollContainer>
                )}
              </ProductCard>
            )
          },
        )}
      </Products>
      {!isRecommended && (
        <OrSection>
          <Divider>
            <span className="line" />
            <span className="or-text">or</span>
            <span className="line" />
          </Divider>
          <Button theme="secondary" onClick={() => setRecommedView(true)}>
            {t("individualRiskAnalysisSideDrawer.seeRecommendedActions")}
          </Button>
          <div className="text">
            {t("individualRiskAnalysisSideDrawer.basedOnbehaviourText")}
          </div>
        </OrSection>
      )}
    </ActionsOverlay>
  )
}

SideDrawer.propTypes = {
  showDrawer: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onRecommendedEnroll: PropTypes.func.isRequired,
  count: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  showHackersmind: PropTypes.bool.isRequired,
  showPulse: PropTypes.bool.isRequired,
  showReflex: PropTypes.bool.isRequired,
  actionFactorScoreFlag: PropTypes.bool,
  keyFactor: PropTypes.string,
  currentFactorScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  sideDrawerTrendValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  showInsightConditions: PropTypes.bool,
}

SideDrawer.defaultProps = {
  count: null,
  isRecommended: false,
  actionFactorScoreFlag: true,
  keyFactor: "",
  currentFactorScore: 0,
  sideDrawerTrendValue: false,
  showInsightConditions: true,
}

export default SideDrawer
