/*
 * Rounds the value to the tenths place. Most scores are returned from the
 * back-end as two decimal places, but we display them as a single decimal place
 * to the user.
 */
export function roundUserFacingScore(value: number): number {
  // Even though we are using TypeScript, accommodate nullish values since this
  // will also be used from untyped JS code
  return Math.floor(Math.round((value ?? 0) * 10)) / 10
}
