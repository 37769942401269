declare const GLOBAL_CONFIG: GlobalConfigType

import elevate from "./elevate"
import _mimecast from "./mimecast"
import type { GlobalConfig } from "./GlobalConfig"

const { hostname } = window.location

// Temporary overrides as we rollout some more features, restricting to DEV-only to avoid breaking staging
const devOverrides: Partial<GlobalConfig> =
  hostname === "localhost" ||
  hostname.endsWith("mimecast-dev.com") ||
  hostname.endsWith("dev.mimecastcsp.com")
    ? ({
        ENABLE_RAP_TABLE_MODE: true,
        ENABLE_ADDITIONAL_HUMAN_RESOURCE_FIELDS: true,
        RAP_FIELD_EXCLUSIONS: [
          "job_title",
          "manager_name",
          "action_factor",
          "organizational_role",
          "employment_type",
          "work_location_geo",
          "is_active",
          "tenure_status",
        ],
      } as const)
    : ({} as const)

const mimecast = {
  ..._mimecast,
  ...devOverrides,
}

type GlobalConfigType = "elevate" | "mimecast_cg" | "mimecast_ci"

const configs: Record<GlobalConfigType, GlobalConfig> = {
  elevate,
  // CI is identical to CG for now. If they diverge, make separate configs.
  mimecast_cg: mimecast,
  mimecast_ci: mimecast,
} as const

export function getGlobalConfig<K extends keyof GlobalConfig>(
  key: K,
): GlobalConfig[K] {
  const config = configs[GLOBAL_CONFIG]
  if (!Object.prototype.hasOwnProperty.call(config, key)) {
    throw new Error(`Config property missing ${key}`)
  }
  return config[key]
}

export function getGlobalConfigKey(): GlobalConfigType {
  return GLOBAL_CONFIG
}
