import PropTypes from "prop-types"
import React from "react"
import {
  Header,
  Modal,
  Typography,
} from "@elevate_security/elevate-component-library"
import { Button, CustomSelect } from "@src/components/MimecastReskin"
import { Form as FinalForm } from "react-final-form"
import { useSelector } from "react-redux"
import {
  CreateForm,
  CreateModalContainer,
  FieldContainer,
  InputContainer,
} from "./styles"
import { toPng } from "html-to-image"
import { getGlobalConfig } from "@src/globalConfig"
import { useTranslation } from "react-i18next"

function ExportModal({
  onClose,
  onChange,
  state,
  loading,
  setLoading,
  isOpen,
}) {
  const { H2 } = Typography
  const { t } = useTranslation(["dashboard"])

  const options = [
    {
      value: "HumanRiskScoreGaugePNG",
      label: t("exportPngModal.humanRiskScore"),
    },
    {
      value: "HumanRiskScoreOverTimePNG",
      label: t("scoreTrendsOverTimeTitle"),
    },
    {
      value: "ActionFactorPNG",
      label: t(getGlobalConfig("BEHAVIOR_BREAKDOWN_TITLE")),
    },
  ]

  if (getGlobalConfig("ENABLE_BEHAVIOR_TRENDS_OVER_TIME")) {
    options.push({
      value: "ActionFactorTrendsPNG",
      label: t("actionFactorTrendTableTitle", {
        descriptor: t(getGlobalConfig("BEHAVIOR_DESCRIPTOR")),
      }),
    })
  }

  options.push({
    value: "AttacksOverTimePNG",
    label: t("exportPngModal.attacksOverTime"),
  })

  if (getGlobalConfig("ENABLE_ATTACK_FACTOR_DISTRIBUTION")) {
    options.push({
      value: "AccessAndAttackFactorPNG",
      label: t("attackFactors.distributionTitle"),
    })
  }

  const pngState = useSelector((state) => state.get("pngReducer"))

  const handleSubmit = async () => {
    setLoading(true)
    const selectedPNG = pngState[state]
    //this is to wait for animations of graphs to finish before downloading image
    await new Promise((resolve) => setTimeout(resolve, 1500))
    const element = document.getElementById(selectedPNG)
    if (element) {
      toPng(element, { backgroundColor: "#ffffff" })
        .then((dataUrl) => {
          const link = document.createElement("a")
          link.href = dataUrl
          link.download = `${selectedPNG}.png`
          link.click()
        })
        .catch((err) => {
          console.error("Failed to export image:", err)
        })
      onClose()
    }
  }

  return (
    <CreateModalContainer>
      <FinalForm
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <Modal
            key="exportModal"
            onClose={() => {
              onClose()
            }}
            style={{ maxWidth: "684px", height: "300px" }}
            header={
              <Header>
                <H2>{t("exportPngModal.chooseImage")}</H2>
              </Header>
            }
            isOpen={isOpen}
            rightButtons={[
              <Button
                disabled={loading}
                key="exportButton"
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
              >
                {t("exportPngModal.exportFile")}
              </Button>,
            ]}
          >
            <CreateForm
              id="vision-dashboard-export-form"
              onSubmit={handleSubmit}
            >
              <FieldContainer>
                <InputContainer>
                  <CustomSelect
                    name="selectedGraph"
                    style={{ width: "100%" }}
                    onChange={onChange}
                    isRequired
                    placeholder={t("exportPngModal.graphToExport")}
                    options={options}
                  />
                </InputContainer>
              </FieldContainer>
            </CreateForm>
          </Modal>
        )}
      />
    </CreateModalContainer>
  )
}

ExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  isOpen: PropTypes.func,
}

export default ExportModal
