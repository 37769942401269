import React, { useMemo } from "react"
import { useEventLogs } from "@src/components/EventLogs/useEventLogs"
import { getColumns, getFiltersParam } from "@src/components/EventLogs/utils"
import { AUDIT_LOGS, AUDIT_LOGS_ALL_POLICIES } from "@src/constants"
import { getDateRangePeriodBefore } from "@src/utils/dates"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import useQuery from "@src/services/hooks/useQuery"
import { AuditLogsScreenWrapper } from "./AuditLogsScreen.styles"
import { AuditLogFilters } from "./AuditLogFilters"
import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { useTranslation } from "react-i18next"

export const AuditLogsScreen = () => {
  const searchValue = useQuery().get("search")
  return <AuditLogsScreenWithSearch searchValue={searchValue} />
}

export const AuditLogsScreenWithSearch = ({
  searchValue = null,
}: {
  searchValue: string | null
}) => {
  const { t } = useTranslation("individualRiskProfile")
  const defaultFilters = useMemo(() => {
    const { startDate, endDate } = getDateRangePeriodBefore(3, "months")
    return [
      {
        type: "date",
        key: "date",
        value: [startDate, endDate],
      },
    ]
  }, [])
  const {
    pagination,
    data,
    loading,
    orderBy,
    appliedFilters,
    searchFilter,
    filters,
    handleSearch,
    clearSearch,
    handleSortedChange,
    onFilterSelection,
  } = useEventLogs({
    type: AUDIT_LOGS_ALL_POLICIES,
    order_by: "created_datetime desc",
    search: searchValue,
    hideFilters: false,
    policy_id: null,
    defaultFilters,
  })

  const formatter = (key: string, defaultValue: string) => {
    return t(key, { defaultValue })
  }

  const columns = useMemo(
    () =>
      getColumns(
        orderBy,
        loading,
        false,
        true,
        AUDIT_LOGS_ALL_POLICIES,
        formatter,
      ),
    [orderBy, loading],
  )

  return (
    <>
      <MimecastCgBreadcrumbs
        breadcrumbs={["risk_response_engine", "audit_logs"]}
      />
      <AuditLogsScreenWrapper>
        <TableWithFiltersScreen
          title={AUDIT_LOGS}
          appliedFiltersCount={appliedFilters.length}
          entries={data?.list}
          totalCount={pagination.total || data?.total}
          columns={columns}
          searchValue={searchFilter || searchValue}
          pagination={pagination}
          loading={!data}
          onSearch={handleSearch}
          clearSearch={clearSearch}
          onSortedChange={handleSortedChange}
          showExportData={true}
          filters={
            Object.keys(filters).length
              ? getFiltersParam(filters, "extra.")
              : ""
          }
        >
          <AuditLogFilters
            onChange={onFilterSelection}
            appliedFilters={appliedFilters}
          />
        </TableWithFiltersScreen>
      </AuditLogsScreenWrapper>
    </>
  )
}
