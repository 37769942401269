import React, { useEffect, useState } from "react"
import { Modal } from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"
import { QueryBuilder } from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder"
import { useDispatch, useSelector } from "react-redux"
import { ModalWrapper } from "./styles"
import useRiskAnalysisURLParam from "@src/scenes/SecEngIndividualRiskAnalysis/components/useRiskAnalysisURLParam"
import { setAdvancedFilters } from "@src/services/redux/analysisIndividuals/actions"
import { v4 as uuidv4 } from "uuid"
import { initializeEmptyRule } from "@src/services/redux/RiskDetectionRules/RuleInfo/RuleInfoReducer"
import { validateCriteria } from "@src/scenes/RiskResponseRuleCreateScreen/components/ConfigureInputCriteriaPage/utils"
import { setQueryBuilderSchema } from "@src/services/redux/RiskDetectionRules/RuleInfo/actions"
import { getQueryBuilderSchema } from "@src/services/apis/policies"
import { convertToAnnotatedQueryString } from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/convertToQueryString"
import Criteria from "@src/scenes/RiskResponseRuleCreateScreen/components/Criteria/Criteria.js"
import useGetCriteriaFieldsNames from "@src/scenes/RiskResponseRuleCreateScreen/components/QueryBuilder/utils/useGetCriteriaFieldsNames"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"

const initialValue = {
  uuid: uuidv4(),
  condition: "and",
  rules: [initializeEmptyRule("people")],
}

function AdvancedFilters({ isOpen, setIsOpen, onClick }) {
  const { t } = useTranslation("riskAnalysis")
  const dispatch = useDispatch()
  const [queryRules, setQueryRules] = useState(structuredClone(initialValue))

  useEffect(() => {
    const getSchema = async () => {
      const response = await getQueryBuilderSchema()
      if (response.data) {
        dispatch(setQueryBuilderSchema(response.data))
      }
    }

    // If the org isn't configured with R3s enabled, the builder schema isn't
    // guaranteed to be loaded, so make sure we load it not matter what since we
    // need it for advanced filters
    getSchema()
  }, [dispatch])

  const { queryBuilderSchema, loadingSchema } = useSelector((getReducers) =>
    getReducers.get("ruleInfoReducer"),
  )
  const { advanced_filters } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )
  const { clearUrl } = useRiskAnalysisURLParam()

  const handlerClose = () => {
    setIsOpen(false)
    setQueryRules(structuredClone(initialValue))
  }

  const handlerSearch = () => {
    clearUrl()

    dispatch(setAdvancedFilters(queryRules))
    setQueryRules(structuredClone(initialValue))
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen && advanced_filters) {
      setQueryRules(structuredClone(advanced_filters))
    }
  }, [isOpen, advanced_filters])

  const { isLoadingFieldsNames, fieldsNamesMap } = useGetCriteriaFieldsNames([
    queryRules,
  ])

  return (
    <>
      <Button theme="ghost" onClick={onClick}>
        {t("advancedFilters.label")}
      </Button>
      {createPortal(
        <ModalWrapper>
          <Modal
            closeButton={<div />}
            onClose={() => handlerClose()}
            isOpen={isOpen}
            rightButtons={[
              <Button
                key="advanced-filter-cancel"
                theme="ghost"
                onClick={() => handlerClose()}
              >
                {t("advancedFilters.actions.cancel")}
              </Button>,
              <Button
                key="advanced-filter-Search"
                disabled={!validateCriteria(queryRules)}
                onClick={handlerSearch}
              >
                {t("advancedFilters.actions.search")}
              </Button>,
            ]}
            header={
              <Criteria
                criteria={convertToAnnotatedQueryString(
                  queryRules,
                  fieldsNamesMap,
                )}
                isLoading={isLoadingFieldsNames}
              />
            }
          >
            <QueryBuilder
              showInlineQuery={false}
              dataSource={queryBuilderSchema}
              queryRules={queryRules}
              loading={loadingSchema}
              onQueryChange={(jsonQuery) =>
                setQueryRules(structuredClone(jsonQuery))
              }
            />
          </Modal>
        </ModalWrapper>,
        document.body,
      )}
    </>
  )
}

export default React.memo(AdvancedFilters)
