import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { getGlobalConfigKey } from "./globalConfig"

// Order matters here for ambiguous language strings -- e.g. if the language is
// "fr" the first "fr-XX" in this list will be picked.
export const supportedLngs = [
  "de-DE",
  "en-US",
  "en-GB",
  "es-ES",
  "fr-FR",
  "fr-CA",
  "it-IT",
  "nl-NL",
  "pt-PT",
]

function getEnabledLngs() {
  switch (getGlobalConfigKey()) {
    case "elevate":
      return ["en-US"]
    case "mimecast_cg":
      return ["en-US"]
    case "mimecast_ci":
      return supportedLngs
  }
}

const isInitialized = i18next
  .use(initReactI18next)
  .use(
    new LanguageDetector(null, {
      caches: [],
    }),
  )
  .use({
    // Much handier than http back-end since we don't have to worry about CORS
    // here -- webpack can load JS modules by inlining script tags.
    // See https://github.com/i18next/i18next/discussions/1611
    type: "backend",
    read(
      language: string,
      namespace: string,
      callback: (err: Error | null, resources?: any) => void,
    ) {
      import(
        /* webpackChunkName: "[request]" */
        `./locales/${language}/${namespace}.json`
      )
        .then((resources) => {
          callback(null, resources)
        })
        .catch((err) => {
          callback(err)
        })
    },
  })
  .init({
    // debug: true,
    fallbackLng: "en-US",
    ns: [
      "translation",
      "dashboard",
      "riskAnalysis",
      "individualRiskProfile",
      "watchlists",
      "actionLogs",
    ],
    defaultNS: "translation",
    interpolation: {
      // Let React handle escaping
      escapeValue: false,
    },
    // Specifying supported languages allows language detector to pick the best
    // one even if there isn't an exact match, e.g. fr -> fr-FR, de -> de-DE
    supportedLngs: getEnabledLngs(),
  })

// Useful for the few cases where we are loading outside the React context
export function waitForI18n(): Promise<{ t: typeof i18next.t }> {
  return isInitialized.then(() => ({ t: i18next.t.bind(i18next.t) }))
}
