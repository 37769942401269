import React from "react"
import { getGlobalConfig } from "@src/globalConfig"
import { useSelector } from "react-redux"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { getActionName, ACTION_NAMES } from "../ActionChart/utils"
import { sortStringsSafe } from "@src/utils/string"
import { useTranslation } from "react-i18next"

export const HumanRiskDashboardHumanRiskScoreInfoTooltip = () => {
  const { t } = useTranslation(["dashboard"])

  const behaviorDescriptor = t(getGlobalConfig("BEHAVIOR_DESCRIPTOR"))

  return (
    <div>
      {t("humanRiskScoreCard.tooltips.riskScoreTooltip.description", {
        behaviorDescriptor,
      })}
      <br />
      <br />
      {t("humanRiskScoreCard.tooltips.riskScoreTooltip.considerations")}
      <EnabledBehaviorList />.{" "}
      {t("humanRiskScoreCard.tooltips.riskScoreTooltip.examples", {
        behaviorDescriptor,
      })}
    </div>
  )
}

export const IndividualRiskProfileHumanRiskScoreInfoTooltip = () => {
  // IRP description of Human Risk Score is the same as main dashboard, for
  // the time being
  return <HumanRiskDashboardHumanRiskScoreInfoTooltip />
}

export const HumanRiskDashboardAttackFactorInfoTooltip = () => {
  const { t } = useTranslation("dashboard")
  return (
    <div>
      {t("humanRiskScoreCard.tooltips.attackFactorTooltip.description")}
      <br />
      <br />
      {t("humanRiskScoreCard.tooltips.attackFactorTooltip.details")}
      <br />
      <br />
      {t("humanRiskScoreCard.tooltips.attackFactorTooltip.lowScoreNote")}{" "}
      <EnabledAttackFactorBehaviorList />.
    </div>
  )
}

export const IndividualRiskProfileAttackFactorInfoTooltip = () => {
  const { t } = useTranslation("dashboard")
  return (
    <div>
      {t("humanRiskScoreCard.tooltips.individualRiskAttackTooltip.description")}
      <br />
      <br />
      {t("humanRiskScoreCard.tooltips.individualRiskAttackTooltip.details")}
      <br />
      <br />
      {t(
        "humanRiskScoreCard.tooltips.individualRiskAttackTooltip.lowScoreNote",
      )}{" "}
      <EnabledAttackFactorBehaviorList />.
    </div>
  )
}

type Behavior = keyof typeof ACTION_NAMES

type BehaviorFilter = (behavior: Behavior) => boolean

const useEnabledBehaviorsList = (filter: BehaviorFilter = () => true) => {
  const { t } = useTranslation("dashboard")

  const activeActions = (useSelector(selectActiveActions) || []).filter(
    (action: string) => action !== "overall",
  ) as Behavior[]

  return sortStringsSafe(
    activeActions
      .filter(filter)
      .map((behavior) =>
        t(`actionNames.${behavior}`, { defaultValue: getActionName(behavior) }),
      ),
  ).join(", ")
}

const EnabledBehaviorList = () => {
  const enabledBehaviorsList = useEnabledBehaviorsList()
  return <span>{enabledBehaviorsList}</span>
}

const EnabledAttackFactorBehaviorList = () => {
  const enabledAttackFactorBehaviorsList = useEnabledBehaviorsList((behavior) =>
    ["malware", "real-world-phishing"].includes(behavior),
  )
  return <span>{enabledAttackFactorBehaviorsList}</span>
}
