import { useMemo } from "react"
import { useRequest } from "ahooks"

import fetcher from "@src/services/fetcher"
import { fetchAll } from "@src/utils/fetchAll"
import { buildQueryString } from "@src/utils/buildQueryString"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"

import { PredefinedWatchlistId } from "../WatchlistsScreen/useWatchlist"

export type RulesMap = Record<PredefinedWatchlistId, string>

export type Rule = {
  watchlist_nid: PredefinedWatchlistId
  id: string
}

export function useRules(): {
  data: RulesMap
  loading: boolean
  error?: Error
} {
  const { data, loading, error } = useRequest(() => {
    return fetchAll(fetchRulePage)
  })

  return {
    loading,
    error,
    data: useMemo(
      () =>
        (data?.results ?? []).reduce(
          (acc, val) => ({
            ...acc,
            [val.watchlist_nid]: val.id,
          }),
          {} as RulesMap,
        ),
      [data?.results],
    ),
  }
}

function fetchRulePage({
  pageSize,
  pageNumber,
}: {
  pageSize: number
  pageNumber: number
}): Promise<{
  results: Rule[]
  total_elements: number
}> {
  const params = buildQueryString({
    page_size: pageSize,
    page_number: pageNumber,
  })
  return fetcher
    .get(`${BASES_REPUTATIONS_API_URL}/rules?${params}`)
    .then((res) => res.data)
}
