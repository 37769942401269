import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Icon } from "@elevate_security/elevate-component-library"
import IconPhishing from "@src/scenes/IndividualProfilePage/ProfileInfo/components/AttackScoreExplanation/icon-phishing.svg"
import {
  Row,
  Column,
  CampaignTooltipWrapper,
  Strong,
  Span,
  Small,
  Status,
  Targets,
} from "./styles"

// components

import { formatDate } from "@src/utils/dates"
import { dashToTitleCase } from "@src/utils/string"
import { formatWithCommas } from "@src/utils/numbers/utils"
import ActivityIndicator from "@src/components/ActivityIndicator"
import {
  getHackersmindCompletionStatistic,
  getPulseOpenStatistic,
  getReflexClickStatistic,
} from "../../services/stats"

export default function CampaignTooltip(props) {
  const {
    status,
    type,
    name,
    startDate,
    endDate,
    totalTargets,
    campaignId,
    totalEventsClicked,
  } = props
  const targetId = `target-${campaignId}`
  const participantsTargetId = `participants-${campaignId}`

  useEffect(() => {
    function updateStatsDom(value) {
      const statistic = Array.isArray(value) ? value[0] : value
      const sentNumberPulseCampaign = Array.isArray(value) ? value[1] : null
      // update for pulse campaigns
      if (type == "pulse_campaign") {
        if (sentNumberPulseCampaign) {
          const target = document.getElementById(participantsTargetId)
          target.innerHTML = `&nbsp;${formatWithCommas(
            sentNumberPulseCampaign,
          )}`
        }
      }
      if (type !== "vision_historical_campaing") {
        const target = document.getElementById(targetId)
        if (target) {
          if (statistic) {
            target.innerText = `${statistic.toFixed(1)}%`
          } else {
            target.innerText = "-"
          }
        }
      }
    }

    async function getStatistics(type) {
      try {
        const fetchStatisticsApi = {
          pulse_campaign: getPulseOpenStatistic,
          reflex_campaign: getReflexClickStatistic,
          hm_campaign: getHackersmindCompletionStatistic,
        }[type]

        const statistic = (await fetchStatisticsApi(campaignId)) || 0
        updateStatsDom(statistic)
      } catch {
        updateStatsDom("-")
      }
    }
    getStatistics(type)
  }, [])

  const renderIcon = () =>
    ({
      pulse_campaign: <Icon name="ProductPulseColored" size="lg" />,
      reflex_campaign: <Icon name="ProductReflexColored" size="lg" />,
      hm_campaign: <Icon name="ProductHMColored" size="lg" />,
      vision_historical_campaing: (
        <img
          src={IconPhishing}
          alt="logo"
          style={{ marginLeft: "10px", width: "42px", height: "36px" }}
        />
      ),
    })[type]

  return (
    <CampaignTooltipWrapper>
      <Row alignItems="flex-start">
        <Column>{renderIcon()}</Column>
        <Column padding="0px 0px 0px 16px" alignItems="flex-start">
          <Strong>
            {" "}
            {formatDate({ date: startDate, format: "MMM YYYY" })} {name}{" "}
            {type === "vision_historical_campaing" && "Clicked Stats"}
          </Strong>
          <Span textTransform="capitalize">
            {type !== "vision_historical_campaing" &&
              type.toString().split("_")[0]}
            {` •  ${formatDate({ date: startDate, format: "MMM D[,] YYYY" })}`}
            {type !== "pulse_campaign" &&
              ` - ${
                endDate
                  ? formatDate({ date: endDate, format: "MMM D[,] YYYY" })
                  : "N/A"
              }`}
          </Span>
          {status === "in_progress" && (
            <Status>{dashToTitleCase(status)}</Status>
          )}
        </Column>
      </Row>
      <Row alignItems="center">
        <Column
          width="calc(50% - 1px)"
          justifyContent="center"
          alignItems="center"
          padding="30px 0px 20px 0px"
        >
          <Row>
            <Icon name="Users" size="sm" />
            <Targets id={participantsTargetId}>
              &nbsp;
              {totalTargets === null ? " -" : formatWithCommas(totalTargets)}
            </Targets>
          </Row>
          <Small>
            {type !== "vision_historical_campaing"
              ? type === "reflex_campaign" || type === "pulse_campaign"
                ? totalTargets === 1
                  ? "recipient"
                  : "recipients"
                : totalTargets === 1
                  ? "participant"
                  : "participants"
              : "individuals clicked"}
          </Small>
        </Column>
        <Column height="74px" width="1px" backgroundColor="#DDE1E7" />
        <Column
          width="calc(50% - 1px)"
          justifyContent="center"
          alignItems="center"
          padding="30px 0px 20px 0px"
        >
          {type === "vision_historical_campaing" && (
            <Targets>
              {totalEventsClicked === null ? "-" : totalEventsClicked}
            </Targets>
          )}
          <Targets id={targetId}>
            {type !== "vision_historical_campaing" && (
              <ActivityIndicator active={true} />
            )}
          </Targets>
          {type === "hm_campaign" && <Small>completion rate</Small>}
          {type === "reflex_campaign" && <Small>click rate</Small>}
          {type === "pulse_campaign" && <Small>open rate</Small>}
          {type === "vision_historical_campaing" && <Small>click events</Small>}
        </Column>
      </Row>
    </CampaignTooltipWrapper>
  )
}

CampaignTooltip.propTypes = {
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  totalTargets: PropTypes.number.isRequired,
  totalEventsClicked: PropTypes.number.isRequired,
}
