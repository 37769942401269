import React from "react"
import {
  HumanRiskBehaviorsItemDetailsGridItem,
  HumanRiskBehaviorsItemDetailsGridItemIcon,
  HumanRiskBehaviorsItemDetailsGridItemScore,
  HumanRiskBehaviorsItemDetailsGridItemText,
} from "./styles"

import PropTypes from "prop-types"

import { Icon, Tooltip } from "@elevate_security/elevate-component-library"
import { limitNumber, truncateNumber } from "@src/utils/numbers/utils"
import { useTranslation } from "react-i18next"

export function getInsightTranslationKeyPrefix({ action, position }) {
  // Here we map "position" to translation key, which looks absurd but works for
  // now. We'll be adding an ID to the API response to make it more sane:
  // https://mimecast.jira.com/browse/EP-1598
  const displayTextTranslationKeys = {
    actual_phishing: {
      1: "workforceAttacked",
      4: "emailsDelivered",
      2: "workforceClicked",
      5: "emailsClicked",
      6: "emailsBlocked",
      3: "workforceReported",
    },
    simulated_phishing: {
      1: "emailsSent",
      2: "workforceClicked",
      5: "emailsClicked",
      3: "workforceReported",
      4: "individualsCompromised",
      6: "attachmentOpened",
      7: "openRate",
    },
    training: {
      2: "completedLate",
      3: "completed",
      4: "assigned",
      1: "overdue",
    },
    malware: {
      2: "downloaded",
      3: "blocked",
      1: "executed",
    },
  }

  const insightKey = displayTextTranslationKeys[action]?.[position]

  return insightKey ? `behaviorStatistics.${action}.${insightKey}` : null
}

export const HumanRiskBehaviorsItemDetailsGridItemMap = ({
  passOnClick,
  data,
  monthCount,
}) => {
  const { t } = useTranslation("dashboard")

  const translationKeyPrefix = getInsightTranslationKeyPrefix(data)

  return (
    <HumanRiskBehaviorsItemDetailsGridItem>
      <HumanRiskBehaviorsItemDetailsGridItemScore>
        {data.type === "percentage"
          ? `${limitNumber(data.value, 0, 100)}%`
          : truncateNumber(data.value)}
      </HumanRiskBehaviorsItemDetailsGridItemScore>
      <HumanRiskBehaviorsItemDetailsGridItemText onClick={passOnClick}>
        {translationKeyPrefix
          ? t(`${translationKeyPrefix}.title`, {
              defaultValue: data.title,
            })
          : data.title}
      </HumanRiskBehaviorsItemDetailsGridItemText>
      <HumanRiskBehaviorsItemDetailsGridItemIcon>
        <Tooltip
          body={
            translationKeyPrefix
              ? t(`${translationKeyPrefix}.description`, {
                  defaultValue: data.description,
                  monthCount,
                })
              : data.description
          }
          placement="top"
          readOnly
          size="xsm"
        >
          <Icon name="InfoCheckOutline" size="xsm" />
        </Tooltip>
      </HumanRiskBehaviorsItemDetailsGridItemIcon>
    </HumanRiskBehaviorsItemDetailsGridItem>
  )
}
HumanRiskBehaviorsItemDetailsGridItemMap.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  passOnClick: PropTypes.func.isRequired,
}
