// libraries
import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

// styled
import { CharGroup } from "./styles"
import { createChartBar } from "./graph"

export default function ChartBar(props) {
  const {
    data,
    onBarMouseOut,
    onBarMouseIn,
    onBarMouseClick,
    xAxisLabel,
    yAxisLabel,
    graphHeight,
    showSecondaryXAxisLabel,
  } = props
  const chartRef = useRef(null)
  useEffect(drawChart, [chartRef])
  useEffect(() => {
    window.addEventListener("resize", drawChart)
    return () => window.removeEventListener("resize", drawChart)
  }, [])

  function drawChart() {
    if (!chartRef || !chartRef.current) return
    chartRef.current.innerHTML = ""
    createChartBar({
      element: chartRef.current,
      data,
      onBarMouseOut,
      onBarMouseIn,
      onBarMouseClick,
      xAxisLabel,
      yAxisLabel,
      graphHeight,
      showSecondaryXAxisLabel,
    })
  }
  return <CharGroup ref={chartRef} />
}

ChartBar.defaulProps = {
  data: [],
  onBarMouseIn: () => null,
  onBarMouseOut: () => null,
  onBarMouseClick: () => null,
  xAxisLabel: "Score Distributions",
  showSecondaryXAxisLabel: false,
}

ChartBar.propTypes = {
  data: PropTypes.array,
  onBarMouseIn: PropTypes.func,
  onBarMouseOut: PropTypes.func,
  onBarMouseClick: PropTypes.func,
  xAxisLabel: PropTypes.string,
  graphHeight: PropTypes.number,
  showSecondaryXAxisLabel: PropTypes.bool,
}
